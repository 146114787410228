import { HiFolderOpen, HiOutlineTrash } from 'react-icons/hi2';
import { Tag, useDeleteTagMutation } from '../../../api/project/projectApi';
import { Link } from '../../../components/Elements';
import React from 'react';
import ButModal from '../../../components/Modal/ButModal';

type TagListProps = {
  tags?: Tag[];
};

function ListTag(props: TagListProps) {
  const { tags } = props;
  const [deleteTag] = useDeleteTagMutation();

  return (
    <div className="overflow-hidden bg-white shadow rounded">
      <ul className="divide-y divide-gray-200">
        {!!tags?.length ? (
          Object.values(tags).map((tag: Tag | undefined) => {
            if (!tag) {
              return null;
            }
            return (
              <li key={tag.id}>
                <div className="grid grid-cols-12">
                  <Link to={`edit/${tag.id}`} className="block col-span-10 hover:bg-blue-50 p-4">
                    <div className="flex items-center justify-between">
                      <p className="truncate text-sm font-medium text-blue-600">{tag?.name}</p>
                    </div>
                  </Link>
                  <ButModal
                    label={<HiOutlineTrash className="h-6 w-6" />}
                    submitLabel={'Yes'}
                    confirm={() => deleteTag({ tagId: tag.id as string })}
                    className={'flex col-span-2 items-center hover:bg-red-100 border-0 text-red-500 rounded-none'}
                  >
                    <h2 className="text-xl text-gray-700 mb-4 mt-1 px-4 text-center">
                      Are you sure you want to delete the "{tag?.name}" tag?{' '}
                    </h2>
                  </ButModal>
                </div>
              </li>
            );
          })
        ) : (
          <div className="text-center">
            <HiFolderOpen className={'mx-auto mt-4 h-12 w-12 text-gray-400'} aria-hidden="true" />
            <h3 className="my-2 text-sm font-semibold text-gray-900">No Tags</h3>
          </div>
        )}
      </ul>
    </div>
  );
}

export default ListTag;
