import React from 'react';
import { InputText, Select } from '../../../../components/Input';
import { HiLink, HiQuestionMarkCircle, HiXCircle } from 'react-icons/hi2';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { FieldValue } from 'react-hook-form/dist/types/fields';
import { Parameter } from '../../../../api/customizationSpace/customizationSpaceApi';

type VariableConfigurationsManagerProps = {
  prefix: string;
  parameters: Parameter[];
};

function VariableConfigurationsManager(props: VariableConfigurationsManagerProps) {
  const { prefix, parameters } = props;

  const { control, watch, register } = useFormContext();

  const {
    fields: itemVariableConfigurationFields,
    append: appendParameter,
    remove,
    move,
  } = useFieldArray({
    control,
    name: `${prefix}.variableConfigurations`,
  });

  const variableConfigurations = watch(`${prefix}.variableConfigurations`);
  const template = watch();

  console.log({ prefix }, { template });

  return (
    <>
      <div className="rounded-b mt-4">
        {itemVariableConfigurationFields?.map((variableConfigurationField: FieldValue<any>, index: number) => {
          const link = variableConfigurations[index].parameterName;
          return (
            <div
              key={variableConfigurationField.id}
              className={'grid grid-cols-12 gap-2 justify-around items-center m-2'}
            >
              <div className="col-span-1">
                {!link ? (
                  <HiXCircle className="h-6 w-6 text-red-600" />
                ) : link === 'UNLINKED' ? (
                  <HiQuestionMarkCircle className="h-6 w-6 text-orange-300" />
                ) : (
                  <HiLink className="h-6 w-6 text-green-600" />
                )}
              </div>
              <InputText
                disabled={true}
                className="col-span-5"
                label={'Variable name'}
                {...register(`${prefix}.variableConfigurations.${index}.name`)}
                addonAfter={<div className="flex-shrink">{variableConfigurationField.value}</div>}
              />
              <Select
                control={control}
                className="col-span-6"
                label={'Linked parameter'}
                name={`${prefix}.variableConfigurations.${index}.parameterName`}
                options={[
                  {
                    value: 'UNLINKED',
                    label: 'Unlinked',
                  },
                  ...parameters
                    ?.filter((p) => p.type === 'AMOUNT_CHANGE')
                    .map((p) => ({
                      value: p.parameterName,
                      label: p.parameterName,
                    })),
                ]}
              />
            </div>
          );
        })}
      </div>
    </>
  );
}

export default VariableConfigurationsManager;
