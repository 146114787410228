import { useEffect } from 'react';
import { DisplayConfiguration, Parameter } from '../../../api/scenarioTemplate/scenarioTemplateApi';
import classNames from '../../../utils/classNames';
import '../style/style.css';
import { useFormContext } from 'react-hook-form';
import { useCustomizationTemplateContext } from '../hooks/CustomizationTemplateProvider';
import { DefaultProps } from '../../../components/Carousel/Carousel';
import useRenderCounter from '../../../hooks/useRenderCounter';
import CustomizationPhaseForm from './CustomizationPhaseForm';
import { findParameter } from '../utils/findParameter';

export type IndexedParameter = Parameter & {
  index: number;
  hidden: string | boolean | undefined;
};

type CustomizationTemplateFormProps = {
  displayConfigurations: DisplayConfiguration[];
  parameters: IndexedParameter[];
  currentPhaseId: string | undefined;
  changePhase: (newPhaseId: string) => void;
  onFormChange: () => void;
} & DefaultProps;

export default function CustomizationTemplateForm(props: CustomizationTemplateFormProps) {
  const { displayConfigurations, parameters, currentPhaseId, className, changePhase, onFormChange } = props;

  const renderCounter = useRenderCounter();

  const {
    formState: { errors },
    setFocus,
  } = useFormContext();
  const { trigger } = useFormContext();

  const { phases: phaseCount, setDisplayErrors } = useCustomizationTemplateContext();

  useEffect(() => {
    if (typeof currentPhaseId === 'undefined') {
      changePhase(displayConfigurations?.[0].phaseId as string);
    }
  }, []);

  const validate = async (currentTab: string, tabCallBack: (currentTab: string) => void) => {
    await trigger();
    console.log('Setting validation');
    setDisplayErrors(true);
    if (Object.keys(errors).length > 0) {
      //Removing the empty one (no error)
      const parameterIndexes = errors.parameters?.map((ep, index) => (ep ? index : undefined)).filter(Boolean);
      //Search a parameter in error in the current displayed phase
      // otherwise we move to a different tab
      const errorParameters = parameters.filter((p) => parameterIndexes.includes(p.index));
      let parameter = findParameter(errorParameters, currentPhaseId, currentTab);
      if (parameter) {
        const { display } = parameter;

        let targetParameter = display?.formulaParameter
          ? parameters.find((ep) => ep.parameterName === display?.formulaParameter)
          : parameter;

        if (targetParameter?.display?.phaseId === currentPhaseId) {
          if (targetParameter?.display?.tab) {
            tabCallBack(targetParameter?.display.tab as string);
          }
        } else if (targetParameter?.display?.phaseId) {
          changePhase(targetParameter?.display.phaseId as string);
        }

        setFocus(`parameters.${parameter.index}.value`);
      }
    }
  };

  return (
    <div className={classNames(className ? className : '', 'bg-white rounded pb-2')}>
      {renderCounter}
      <ol className="flex flex-nowrap bg-white overflow-x-auto rounded-t">
        {displayConfigurations?.map((pc, stepIdx) => {
          const phaseParameters: IndexedParameter[] = parameters?.filter((p) => p.display?.phaseId === pc.phaseId);
          if (phaseParameters.length) {
            const phaseInvalid =
              Object.keys(errors).length > 0
                ? errors.parameters?.filter((ep, index) => phaseParameters.map((p) => p.index).includes(index) && ep)
                : [];
            const phaseCurrent = pc.phaseId === currentPhaseId;
            return (
              <li
                key={pc.phaseId as string}
                className="flex-1 cursor-pointer relative border-t border-gray-200"
                onClick={() => changePhase(pc.phaseId as string)}
              >
                <div
                  className={classNames(phaseCurrent ? 'border-blue-600' : 'border-gray-200', 'border-b-2 pl-4 py-2')}
                >
                  <span className="flex p-3">
                    <span
                      className={classNames(
                        !phaseInvalid?.length ? 'bg-green-500' : 'pulse red',
                        'flex-shrink-0 h-8 w-8 rounded-full',
                      )}
                    />
                    <span className="whitespace-nowrap flex-1 ml-4 mt-1 text-md font-medium text-blue-600">
                      {pc.phaseName}
                    </span>
                  </span>
                </div>
                {stepIdx !== 0 ? (
                  <>
                    {/* Separator */}
                    <div className="absolute inset-0 left-0 top-0 hidden w-3 lg:block" aria-hidden="true">
                      <svg
                        className="h-full w-full text-gray-300"
                        viewBox="0 0 12 82"
                        fill="none"
                        preserveAspectRatio="none"
                      >
                        <path d="M0.5 0V31L10.5 41L0.5 51V82" stroke="currentcolor" vectorEffect="non-scaling-stroke" />
                      </svg>
                    </div>
                  </>
                ) : null}
              </li>
            );
          }
        })}
      </ol>
      {displayConfigurations &&
        displayConfigurations?.map((pc: DisplayConfiguration) => {
          return (
            <div key={pc.phaseId} className={classNames(pc.phaseId !== currentPhaseId ? `hidden` : '')}>
              <CustomizationPhaseForm
                onFormChange={onFormChange}
                phaseConfiguration={pc}
                onValidate={validate}
                parameters={parameters?.filter((p) => p.display?.phaseId === pc.phaseId) ?? []}
              />
            </div>
          );
        })}
    </div>
  );
}
