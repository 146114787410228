import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Tag as TagDto, useGetTagQuery, usePostTagMutation } from '../../../api/project/projectApi';
import { Spinner } from '../../../components/Elements';
import { useBreadcrumbs } from '../../../lib/breadcrumbs';
import { TagForm } from './TagForm';
import { toast } from 'react-hot-toast/headless';

function Tag() {
  const { tagId } = useParams();
  const { data, error, isLoading } = useGetTagQuery({ tagId: tagId as string });
  const [postTag] = usePostTagMutation();

  const { setBreadcrumbs } = useBreadcrumbs();

  useEffect(() => {
    if (data) {
      setBreadcrumbs([{ label: 'tags', path: '/app/tags' }, { label: `${data.name}` }]);
    }
  }, [data]);

  const onSubmit = async (tag: TagDto) => {
    const updatedTag: any = await postTag({ tag: tag as TagDto }).unwrap();
    if (updatedTag.error) {
      // console.log(updatedTag.error);
      toast.error('Failed to update tag');
    } else {
      toast.success('Tag updated successfully');
    }
  };

  if (isLoading) {
    return <Spinner size={'h-14 w-14'} className="w-12 h-12 self-center m-5" />;
  }

  return <TagForm onSubmit={onSubmit} initialValues={data} submitLabel={'Update'} />;
}

export default Tag;
