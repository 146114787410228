import * as Yup from 'yup';
import {
  CategoryGroup,
  ImpactMethod,
  usePostCategoryGroupMutation,
} from '../../../api/lightweightOlcaCore/lightweightOlcaCoreApi';
import { FormProvider, useForm } from 'react-hook-form';
import { InputText, Select } from '../../../components/Input';
import { Button } from '../../../components/Elements';
import { Checkbox } from '../../../components/Input/Checkbox';
import { yupResolver } from '@hookform/resolvers/yup';
import { InputList } from '../../../components/Input/InputList';
import { HiOutlineTrash } from 'react-icons/hi2';
import React from 'react';

type ImpactMethodForm = {
  impactMethod: ImpactMethod;
  submitLabel: string;
  onSubmit: (values: any) => void;
  onError: (values: any) => void;
  formMode?: 'EDIT' | 'CREATE';
};

export const ImpactMethodForm = (props: ImpactMethodForm) => {
  const { impactMethod, submitLabel, formMode, onSubmit, onError } = props;

  const [postCategoryGroup] = usePostCategoryGroupMutation();

  const impactMethodValidation = Yup.object().shape({
    name: Yup.string().required('Impact method name is required').nullable(),
  });

  const methods = useForm({
    defaultValues: {
      ...impactMethod,
      name: `${impactMethod.name}${formMode === 'EDIT' ? '' : '- copy'}`,
    },
    resolver: yupResolver(impactMethodValidation),
  });

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    watch,
  } = methods;

  const categoryGroups: CategoryGroup[] = watch('categoryGroups');

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit, onError)} className={'mt-3'}>
        <InputText {...register('name')} label={'Impact methodology name'} />
        <InputList
          className="mt-3"
          item={{ name: '', impactMethodId: impactMethod.id }}
          onAppend={async (item) => {
            const categoryGroup = await postCategoryGroup({
              categoryGroup: item,
            });
            // console.log(categoryGroup)
            if ('error' in categoryGroup) {
              throw new Error('Failed to execute assessment: ' + categoryGroup.error);
            }
            return categoryGroup.data; //Saving categoryGroup to database
          }}
          label={'Category groupings'}
          control={control}
          name={'categoryGroups'}
          renderItem={(field, index, remove) => (
            <div className={'col-span-6 p-2 flex'}>
              <InputText {...register(`categoryGroups.${index}.name`)} className="flex-1" />
              <Button type="button" variant="none" onClick={() => remove(index)}>
                <HiOutlineTrash className="h-6 w-6 text-red-500" />
              </Button>
            </div>
          )}
        />
        <div className={'py-4'}>
          <InputList
            control={control}
            label={'Impact categories'}
            name={'impactCategories'}
            renderItem={(field, index, remove, append) => (
              <div className="col-span-12 grid grid-cols-12 my-2">
                <span className={'col-span-3 text-center self-center italic'}>{field.name}</span>
                <Select
                  control={control}
                  isMulti={true}
                  className={'col-span-2 mx-4'}
                  label={'Category groups'}
                  name={`impactCategories.${index}.categoryGroups`}
                  accessorFn={(categoryGroup: CategoryGroup) => categoryGroup.id as string}
                  options={categoryGroups.map((cg) => ({ label: cg.name, value: cg }))}
                />
                <Checkbox
                  label={'Expand category (by phase)'}
                  className={'col-span-2 mx-4'}
                  {...register(`impactCategories.${index}.expand`)}
                />
                <Checkbox
                  label={'Exponential value'}
                  className={'col-span-1 mx-4'}
                  {...register(`impactCategories.${index}.exponential`)}
                />
                <Checkbox
                  label={'Default visibility'}
                  className={'col-span-1 mx-4'}
                  {...register(`impactCategories.${index}.visible`)}
                />
                <InputText
                  label={'Alias'}
                  className={'col-span-3 mx-4'}
                  key={field.id}
                  {...register(`impactCategories.${index}.alias`)}
                />
              </div>
            )}
          />
        </div>

        <Button type={'submit'}>{submitLabel}</Button>
      </form>
    </FormProvider>
  );
};
