import { useFieldArray, useFormContext } from 'react-hook-form';
import { InputText, Select } from '../../../../components/Input';
import React from 'react';
import { FieldValue } from 'react-hook-form/dist/types/fields';
import { Button } from '../../../../components/Elements';
import { HiOutlineTrash, HiPlusCircle } from 'react-icons/hi2';
import { InputNumber } from '../../../../components/Input/InputNumber';

type FormulaFormProps = {
  prefix: string;
};

function FormulaForm(props: FormulaFormProps) {
  const { prefix } = props;

  const { control, register, watch } = useFormContext();

  const {
    fields: dependsOnFields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: `${prefix}.dependsOn`,
  });

  const parameters = watch('parameters');
  const parameter = watch(prefix);

  console.log(parameters);

  return (
    <div className="w-full">
      <InputText className="my-3" {...register(`${prefix}.formula`)} label="Formula" />
      <InputNumber {...register(`${prefix}.accuracy`)} label="Accuracy" min={0} step={1} />
      <div className="my-4 text-gray-600">
        {' '}
        Depends On
        <div className="grid grid-cols-2 gap-5 mt-3">
          {dependsOnFields?.map((dependsOnField: FieldValue<any>, index: number) => {
            return (
              <div key={dependsOnField.id} className="col-span-1 flex">
                <Select
                  control={control}
                  name={`${prefix}.dependsOn.${index}.parameterName`}
                  label="Parameter"
                  disabled={false}
                  className="flex-1"
                  options={parameters
                    ?.filter((p) => p.type !== 'FLOW_SWITCH' && p.parameterName !== parameter.parameterName)
                    .map((p) => ({
                      value: p.parameterName,
                      label: p.parameterName,
                    }))}
                />
                <Button type="button" variant={'none'} onClick={() => remove(index)}>
                  <HiOutlineTrash className="h-6 w-6 text-red-500" />
                </Button>
              </div>
            );
          })}
        </div>
        <Button
          type="button"
          variant="none"
          className="w-full mt-4 text-blue-500 border border-blue-500"
          onClick={() => append({ parameterName: '' })}
        >
          <HiPlusCircle className="h-6 w-6 text-blue-500 mr-4" />
          Add dependency
        </Button>
      </div>
    </div>
  );
}

export default FormulaForm;
