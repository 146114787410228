import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  ProjectDto,
  useGetAllScenarioQuery,
  useGetProjectQuery,
  usePostProjectMutation,
} from '../../../api/project/projectApi';
import { Button, Link, Spinner } from '../../../components/Elements';
import { useBreadcrumbs } from '../../../lib/breadcrumbs';
import ListScenario from '../../scenario/components/ListScenario';
import { ProjectForm } from './ProjectForm';
import { toast } from 'react-hot-toast/headless';
import { InputText } from '../../../components/Input';
import { HiMagnifyingGlass, HiPlusCircle } from 'react-icons/hi2';

function Project() {
  const { projectId } = useParams();
  const [search, setSearch] = useState();
  const { data: project, isLoading } = useGetProjectQuery(projectId ? { projectId: projectId } : skipToken);
  const [postProject] = usePostProjectMutation();
  const { setBreadcrumbs } = useBreadcrumbs();

  useEffect(() => {
    if (project) {
      setBreadcrumbs([
        { label: 'projects', path: '/app/projects' },
        { label: project.name as string, path: `/app/projects/${projectId}` },
      ]);
    }
  }, [project]);

  const onSubmit = async (project: ProjectDto) => {
    const updatedProject: any = await postProject({
      projectDto: project,
    }).unwrap();
    if (updatedProject.error) {
      // console.log(updatedProject.error);
      toast.error('Failed to update project');
    } else {
      toast.success('Project updated successfully');
    }
  };

  if (isLoading) {
    return <Spinner size={'h-14 w-14'} className="w-12 h-12 self-center m-5" />;
  }

  return (
    <>
      {project && <ProjectForm className="mt-3" initialValues={project} onSubmit={onSubmit} submitLabel={'Update'} />}

      <div className="flex flex-col">
        <div className="flex flex-row ml-auto my-3">
          <InputText
            addonBefore={<HiMagnifyingGlass className="w-6 h-6 text-black" />}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <Link to={`/app/scenarios/new/${projectId}`}>
            <Button
              className="float-right ml-3 text-blue-500 border-2 bg-white border-blue-500 hover:bg-blue-600 hover:text-white"
              style={{ height: '46px' }}
              variant="none"
              size={'md'}
            >
              <HiPlusCircle className="mr-2 h-6 w-6" /> Create New
            </Button>
          </Link>
        </div>
        <ListScenario
          filter={projectId ? { projectId: projectId, scenarioName: search, scenarioDescription: search } : skipToken}
          fetchData={useGetAllScenarioQuery}
        />
      </div>
    </>
  );
}

export default Project;
