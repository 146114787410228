import * as Yup from 'yup';
import { Tag } from '../../../api/project/projectApi';
import { TagRestriction } from '../../../api/project/types/TagRestriction';
import { Form } from '../../../components/Form';
import { FormProps } from '../../../components/types';
import React from 'react';

type TagFormProps = FormProps;

export const TagForm = (props: TagFormProps) => {
  const { onSubmit, onError, initialValues, submitLabel } = props;

  const tagValidation = Yup.object().shape({
    name: Yup.string().required('Tag name is required'),
    color: Yup.string().required('Tag color is required'),
  });

  const handleOnSubmit = (data: Tag) => {
    onSubmit(data);
  };

  const tagRestrictions =
    Object.keys(TagRestriction)
      .filter((v) => isNaN(Number(v)))
      .map((t: string) => ({ value: t, label: t })) || [];

  return (
    <Form
      onSubmit={handleOnSubmit}
      onError={onError}
      validationSchema={tagValidation}
      submitLabel={submitLabel || 'Create'}
      initialValues={initialValues}
      editing={true}
    >
      <Form.Item name="name" type="InputText" label="Tag Name" />
      <Form.Item name="description" type="InputText" label="Description" />
      <Form.Item name="tagRestriction" type="Select" label="Tag restriction" options={tagRestrictions} />
      <Form.Item name="color" type="ColorPicker" label="Tag Color" />
      <Form.Item name="id" type="InputText" hidden={true} />
    </Form>
  );
};
