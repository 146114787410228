import React from 'react';
import { Controller } from 'react-hook-form';
import { SimpleSelect } from './SimpleSelect';

type SelectProps = {
  control: any;
  name: string;
  defaultValue?: string;
  isMulti?: boolean;
  disabled?: boolean;
  label?: string;
  placeholder?: string;
  onChange?: (e: any) => void;
  className?: string;
  accessorFn?: (value: any) => string;
  error?: string | null | undefined;
  options: { label: string | undefined; value: any; disabled?: boolean | undefined }[] | undefined;
};
export const Select = React.forwardRef<any, SelectProps>((props, ref) => {
  const {
    control,
    name,
    isMulti,
    defaultValue,
    options,
    label,
    disabled,
    placeholder,
    onChange: externalOnchange,
  } = props;

  // console.log({ defaultValue })

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field: { onChange, ...field } }) => {
        return (
          <SimpleSelect
            {...field}
            {...props}
            isMulti={isMulti}
            placeholder={placeholder}
            options={options ?? []}
            label={label}
            disabled={disabled}
            onChange={(e) => {
              onChange(e);
              // console.log({ e });
              externalOnchange?.(e);
            }}
          />
        );
      }}
    />
  );
});
