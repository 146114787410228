import { CustomizationSpace, Option, Parameter } from '../../../api/customizationSpace/customizationSpaceApi';
import { PhaseConfiguration } from '../../../api/scenarioTemplate/scenarioTemplateApi';

export const generateParameters = (
  customizationSpaces: CustomizationSpace[],
  customizationSpaceRefIds: string[],
  phaseConfiguration: PhaseConfiguration,
  rootModeledProcessRefId?: string,
) => {
  console.log({ customizationSpaces }, { customizationSpaceRefIds });
  const selectedCustomizationSpaces = customizationSpaces.filter((cs) => {
    return customizationSpaceRefIds.includes(cs.customizationSpaceRefId);
  });
  let params: any[] = selectedCustomizationSpaces.reduce((acc, current) => {
    const parameters = current.parameters.map((p: Parameter) => {
      let param: any = { ...p };
      if (p.type === 'FLOW_SWITCH') {
        param = {
          ...p,
          rootModeledProcessRefId,
          parentRefId: current.customizationSpaceRefId,
        };
      }

      if (p.parameterType === 'OPTION') {
        param = {
          ...param,
          value: p.options?.find((o: Option) => o.isDefault)?.value,
        };
      }

      param.display = {
        phaseId: phaseConfiguration.phaseId,
        tab: current.name,
      };

      return param;
    });
    return [...acc, ...parameters];
  }, []);

  //doing a distinct on parameter name
  params = params.filter((p, index, array) => array.findIndex((o) => o.parameterName === p.parameterName) === index);

  // console.log({ selectedCustomizationSpaces });

  return {
    parameters: params,
    tabs: selectedCustomizationSpaces.map((cs) => ({
      name: cs.alias,
      description: cs.description,
    })),
  };
};
