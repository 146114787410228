import { HiLink, HiOutlineTrash } from 'react-icons/hi2';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Button } from '../../../../components/Elements/Button';
import { InputText, Select } from '../../../../components/Input';
import React from 'react';
import { Parameter } from '../../../../api/customizationSpace/customizationSpaceApi';
import classNames from '../../../../utils/classNames';

type OptionFormProps = {
  prefix: String;
  className?: string;
};

function OptionForm(props: OptionFormProps) {
  const { prefix, className } = props;

  const { control, register, watch } = useFormContext();

  const {
    fields: optionsLinkFields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: `${prefix}.linkedParameters`,
  });

  const parameters: Parameter[] = watch('parameters');

  return (
    <div className={classNames(className ? className : '')}>
      {optionsLinkFields.map((o, index) => {
        return (
          <div className="flex my-3 items-center gap-x-5">
            <span className="font-bold">Link {index}</span>
            <Select
              control={control}
              className="flex-1"
              name={`${prefix}.linkedParameters.${index}.parameterName`}
              label={'Parameter'}
              options={parameters.map((p) => ({
                label: p.parameterName,
                value: p.parameterName,
              }))}
            />

            <InputText className="flex-1" {...register(`${prefix}.linkedParameters.${index}.value`)} label={'Value'} />

            <Button type="button" variant="none" onClick={() => remove(index)}>
              <HiOutlineTrash className="h-6 w-6 text-red-500" />
            </Button>
          </div>
        );
      })}
      <Button
        type="button"
        variant="none"
        className="w-full text-blue-500"
        onClick={() => append({ parameterName: '', value: '' })}
      >
        <HiLink className="h-6 w-6" /> Add Link
      </Button>
    </div>
  );
}

export default OptionForm;
