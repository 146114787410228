import { HiMagnifyingGlass, HiPlusCircle } from 'react-icons/hi2';
import { useEffect, useState } from 'react';
import { useGetAllScenarioQuery } from '../../../api/project/projectApi';
import { Button, Link } from '../../../components/Elements';
import { InputText } from '../../../components/Input';
import { useBreadcrumbs } from '../../../lib/breadcrumbs';
import ListScenario from '../components/ListScenario';

export const Scenarios = () => {
  const [search, setSearch] = useState<string>();
  const { setBreadcrumbs } = useBreadcrumbs();

  useEffect(() => {
    setBreadcrumbs([{ label: 'scenarios' }]);
  }, []);

  return (
    <div className="flex flex-col">
      <div className="flex flex-row ml-auto my-3">
        <InputText
          addonBefore={<HiMagnifyingGlass className="w-6 h-6 text-black" />}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <Link to="./new/">
          <Button
            className="float-right ml-3 text-blue-500 border-2 bg-white border-blue-500 hover:bg-blue-600 hover:text-white"
            style={{ height: '46px' }}
            variant="none"
            size={'md'}
          >
            <HiPlusCircle className="mr-2 h-6 w-6" /> Create New
          </Button>
        </Link>
      </div>
      <ListScenario filter={{ scenarioName: search, scenarioDescription: search }} fetchData={useGetAllScenarioQuery} />
    </div>
  );
};
