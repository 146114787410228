import { Link } from '../../../components/Elements';
import { Bom } from '../../../api/bom/bomApi';

type ListBomProps = {
  boms?: Bom[];
};

function ListBom(props: ListBomProps) {
  const { boms } = props;

  return (
    <div className="overflow-hidden bg-white shadow sm:rounded">
      <ul className="divide-y divide-gray-200">
        {!!boms?.length ? (
          boms.map((bom: Bom) => {
            if (!bom) {
              return null;
            }
            return (
              <li key={bom.id}>
                <div className="grid grid-cols-12">
                  <Link to={`./inspect/${bom.id}`}>
                    <p className="text-sm font-medium text-blue-600">{bom.id}</p>
                  </Link>
                </div>
              </li>
            );
          })
        ) : (
          <>No Boms</>
        )}
      </ul>
    </div>
  );
}

export default ListBom;
