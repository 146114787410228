import { Menu, Transition } from '@headlessui/react';
import { Link, useNavigate } from 'react-router-dom';

import { HiGlobeAlt, HiUserCircle } from 'react-icons/hi2';
import React, { Fragment, useState } from 'react';
import classNames from '../../utils/classNames';
import { logout } from '../../api/utility/logoutAction';
import { useDispatch } from 'react-redux';
import { setAuthenticated } from '../../features/auth/authSlice';
import { PasswordPopup } from '../PasswordPopup/PasswordPopup';
import { TooltipItem } from '../Tooltip/Tooltip';
import ConfirmationPopup from '../ConfirmationPopup/ConfirmationPopup';
import { AuthUser } from '../../features/auth/AuthProvider';
import { toast } from 'react-hot-toast/headless';
import storage from '../../utils/storage';
import { SimpleSelect } from '../Input/SimpleSelect';
import Modal from '../Modal/Modal';

type UserNavigationItem = {
  name: string;
  to: string;
  onClick?: () => void;
};

type UserNavigationProps = {
  user: AuthUser;
  changeDefaultRealm: (realm: string) => Promise<boolean>;
  actualRealm: string;
  setActualRealm: (realm: string) => void;
  allowedRealms: string[];
  defaultRealm: string | undefined;
  login: (username: string, password: string, selectedRealm: string, onSuccess: any, onError: any) => Promise<void>;
};

function UserNavigation(props: UserNavigationProps): JSX.Element {
  const { changeDefaultRealm, actualRealm, setActualRealm, allowedRealms, defaultRealm, login } = props;

  const [isSwitchRealmPopupOpen, setSwitchRealmPopupOpen] = useState<boolean>(false);
  const [isMakeDefaultPopupOpen, setMakeDefaultPopupOpen] = useState<boolean>(false);
  const [selectedRealm, setSelectedRealm] = useState<string>(actualRealm);
  const [realmToSwitch, setRealmToSwitch] = useState<string>();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userNavigation = [
    // {name: "Your Profile", to: "./profile"},
    {
      name: 'Sign out',
      onClick: () => {
        logout().then(() => {
          console.log('logout');
          dispatch(setAuthenticated(false));
        });
      },
    },
  ].filter(Boolean) as UserNavigationItem[];

  const handleRealmChange = (realm: string) => {
    if (!allowedRealms.find((x) => x == realm)) {
      return;
    }

    setSwitchRealmPopupOpen(true);
    setRealmToSwitch(realm);
  };

  const handleSetDefaultRealm = async () => {
    const success = await changeDefaultRealm(actualRealm);
    if (success) {
      setMakeDefaultPopupOpen(false);
      toast.success('Default realm changed successfully');
    } else {
      toast.error('Error during operation');
    }
  };

  const handlePasswordSubmit = async (password: string) => {
    if (realmToSwitch) {
      const username = storage.getUsername() || '';
      const onSuccess = () => {
        storage.setTargetRealm(realmToSwitch);
        setSelectedRealm(realmToSwitch);
        setActualRealm(realmToSwitch);
        setSwitchRealmPopupOpen(false);

        navigate('/app/projects');
        window.location.reload();
      };
      const onError = () => {
        toast.error('Wrong credentials');
      };

      console.log(`Login into ${realmToSwitch} for user ${username}`);

      await login(username, password, realmToSwitch, onSuccess, onError);
    }
  };

  return (
    <div className="relative z-10 lg:ml-4 flex items-center">
      {allowedRealms.length > 1 && (
        <div className="flex flex-grow min-w-[210px]">
          {selectedRealm !== defaultRealm ? (
            <TooltipItem title="Make this realm as default" position="left">
              <HiGlobeAlt
                onClick={() => setMakeDefaultPopupOpen(true)}
                className={classNames(
                  selectedRealm == defaultRealm ? 'text-blue-600' : 'text-gray-700',
                  'h-12 w-12 mt-1',
                )}
                aria-hidden="true"
              />
            </TooltipItem>
          ) : (
            <HiGlobeAlt
              className={classNames(
                selectedRealm == defaultRealm ? 'text-blue-600' : 'text-gray-700',
                'h-12 w-12  mt-1',
              )}
              aria-hidden="true"
            />
          )}
          <SimpleSelect
            id="realms"
            className="m-1 text-blue-600 ml-5 w-2/3"
            label={'Realm'}
            options={allowedRealms.map((realm: string) => ({ value: realm, label: realm }) ?? [])}
            value={actualRealm}
            onChange={(e) => handleRealmChange(e)}
          />
        </div>
      )}

      {isSwitchRealmPopupOpen && (
        <Modal open={isSwitchRealmPopupOpen} handleOpen={setSwitchRealmPopupOpen} size={'xs'}>
          <PasswordPopup
            message={'Insert password'}
            onClose={() => setSwitchRealmPopupOpen(false)}
            handleOnSubmit={handlePasswordSubmit}
          />
        </Modal>
      )}

      {isMakeDefaultPopupOpen && (
        <ConfirmationPopup onConfirm={handleSetDefaultRealm} onCancel={() => setMakeDefaultPopupOpen(false)}>
          Are you sure to change the default realm to ${selectedRealm}?
        </ConfirmationPopup>
      )}

      {/* Profile dropdown */}

      <Menu as="div" className="relative flex-shrink-0">
        <div>
          <Menu.Button>
            <span className="sr-only">Open user menu</span>
            <HiUserCircle className={'h-12 w-12 mt-1 text-gray-700'} />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded bg-white py-1 shadow ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className={'mx-2 p-2 rounded bg-white py-1 text-center'}></div>
            <Menu.Item key={storage.getUsername()}>
              <div className={'block pb-2 px-4 text-md text-blue-600 font-bold'}>Hi, {storage.getUsername()}</div>
            </Menu.Item>
            {userNavigation.map((item) => (
              <Menu.Item key={item.name}>
                {({ active }) => (
                  <Link
                    to={item.to}
                    onClick={item.onClick}
                    className={classNames(active ? 'bg-gray-100' : '', 'block py-2 px-4 text-sm text-gray-700')}
                  >
                    {item.name}
                  </Link>
                )}
              </Menu.Item>
            ))}
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}

export default UserNavigation;
