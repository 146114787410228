import { HiClipboardDocument, HiDocumentDuplicate, HiOutlineTrash } from 'react-icons/hi2';
import { yupResolver } from '@hookform/resolvers/yup';
import { useStateMachine } from 'little-state-machine';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { toast } from 'react-hot-toast/headless';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import {
  ImpactMethod,
  ModeledProcessInfoDto,
  TreeInfoDto,
  useGetParametersForTreesQuery,
} from '../../../../api/lightweightOlcaCore/lightweightOlcaCoreApi';
import { PhaseDto } from '../../../../api/project/projectApi';
import { Button } from '../../../../components/Elements';
import initScenarioTemplateAction from '../../utils/initScenarioTemplateAction';
import { InputText } from '../../../../components/Input';
import { getInputError } from '../../../../components/Input/util/getInputError';
import {
  AssessmentConfiguration,
  BasicScenarioTemplate,
  DisplayConfiguration,
  usePostScenarioTemplateMutation,
} from '../../../../api/scenarioTemplate/scenarioTemplateApi';
import { TextArea } from '../../../../components/Input/TextArea';
import { Parameter, useGetCustomizationSpacesQuery } from '../../../../api/customizationSpace/customizationSpaceApi';
import { generateParameters } from '../../utils/generateParameters';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { checkImportMapping, ImportMapping } from '../../utils/checkImportMapping';
import Modal from '../../../../components/Modal/Modal';
import PhaseTreeMapping from './PhaseTreeMapping';
import AssessmentConfigurationForm from './AssessmentConfigurationForm';
import { InputList } from '../../../../components/Input/InputList';

const basicScenarioTemplateSchema = Yup.object().shape({
  name: Yup.string().required('Template name is required').nullable(),
  // description: Yup.string().required('Template description is required').nullable(),
  assessmentConfigurations: Yup.array()
    .of(
      Yup.object().shape({
        assessmentType: Yup.string().required('ModeledProcess is required'),
        impactMethodId: Yup.string().required('ModeledProcess is required'),
        phaseConfigurations: Yup.array()
          .of(
            Yup.object().shape({
              treeId: Yup.string().required('ModeledProcess is required'),
              phaseId: Yup.string().required('Phase is required'),
            }),
          )
          .min(1, 'A phase is required')
          .required(),
      }),
    )
    .min(1, 'An assessment type is required')
    .required(),
});

type ScenarioTemplateFormProps = {
  initialValues: any;
  phases: PhaseDto[];
  trees: TreeInfoDto[];
  impactMethods: ImpactMethod[];
};

function ScenarioTemplateForm(props: ScenarioTemplateFormProps) {
  const { initialValues, phases, trees, impactMethods } = props;

  const navigate = useNavigate();
  const [selectedTreeIds, setSelectedTreeIds] = useState<string[]>([]);
  const [importJson, setImportJson] = useState('');
  const [importMapping, setImportMapping] = useState<ImportMapping[]>([]);
  const [importModal, setImportModal] = useState(false);
  const { actions, state } = useStateMachine({ initScenarioTemplateAction });

  const { data: customizationSpaces } = useGetCustomizationSpacesQuery({
    customizationSpaceRefIds: undefined,
  });
  const [postScenarioTemplate] = usePostScenarioTemplateMutation();
  const { data: variables } = useGetParametersForTreesQuery(
    selectedTreeIds.length > 0 ? { treeIds: selectedTreeIds.filter(Boolean) } : skipToken,
  );

  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(basicScenarioTemplateSchema),
  });

  const {
    register,
    control,
    reset,
    formState: { errors },
    getValues,
    handleSubmit,
  } = methods;

  const handleFormSubmit = (values: BasicScenarioTemplate) => {
    const template = createTemplate(values);
    actions.initScenarioTemplateAction({ template });
    navigate('./../customization');
  };

  const handleImportJson = async () => {
    const mapping: any[] = checkImportMapping(importJson, trees, phases);
    if (mapping.length > 0) {
      setImportModal(true);
      setImportMapping(mapping);
    } else {
      reset(JSON.parse(importJson));
    }
  };

  const mapImportJson = (importJson: any) => {
    reset(JSON.parse(importJson));
    setImportModal(false);
  };

  const createTemplate = (values: any, forCopy: boolean = false) => {
    if (!customizationSpaces) return;
    const template: any = {
      ...values,
      id: !values.id || forCopy ? undefined : values.id,
      type: 'BASIC',
    };
    const parameters: Parameter[] = template.parameters ?? [];
    const displayConfigurations: DisplayConfiguration[] =
      template.displayConfigurations ?? ([] as DisplayConfiguration[]);
    const vars: any[] = template.variableConfigurations ?? [];

    values.assessmentConfigurations?.forEach((ac: AssessmentConfiguration, index: number) => {
      ac.phaseConfigurations?.forEach((pc) => {
        //Still noit fully configured so we skip the displayConfigurations creation
        if (!pc.phaseId || !pc.treeId) return;

        let dc = displayConfigurations.find((dc) => dc.phaseId === pc.phaseId);
        const tree = trees.find((x) => x.id === pc.treeId);

        pc.phaseName = phases.find((p) => p.id === pc.phaseId)?.name;
        pc.treeName = tree?.name;
        const cSpaces = [
          ...(tree?.subProcesses?.map((x: ModeledProcessInfoDto) => x.id as string) ?? []),
          tree?.processRefId as string,
        ];

        if (!dc) {
          const g = generateParameters(customizationSpaces, cSpaces, pc, tree?.processRefId);

          dc = {
            customizationSpaces: [...cSpaces],
            tabs: [...g.tabs],
          };

          displayConfigurations.push({
            ...dc,
            phaseId: pc.phaseId,
            treeIds: [pc.treeId as string],
            phaseName: phases.find((p) => p.id === pc.phaseId)?.name,
          });

          vars.push(...(variables?.[pc.treeId] ?? []));
          parameters.push(...g.parameters);
        } else if (dc && !dc.treeIds?.includes(pc.treeId as string)) {
          const g = generateParameters(customizationSpaces, cSpaces, pc, tree?.processRefId);
          dc.customizationSpaces = [...(dc.customizationSpaces ?? []), ...cSpaces];
          dc.tabs = [...new Map([...(dc.tabs ?? []), ...g.tabs]?.map((item) => [item['name'], item])).values()];

          dc.treeIds = [...(dc.treeIds ?? ([] as string[])), pc.treeId];
          vars.push(...(variables?.[pc.treeId] ?? []));
          parameters.push(...g.parameters);
        }
      });
    });

    template.displayConfigurations = displayConfigurations;
    template.parameters = [...new Map(parameters?.map((item) => [item['parameterName'], item])).values()];
    // DISTINCT
    template.variableConfigurations = [...new Map(vars?.map((item) => [item['name'], item])).values()].map((v) => {
      //Initial assignment of parameter to variable base on name
      const par = template.parameters.filter((p) => p.parameterName === v.name);
      if (par.length === 1 && !v.parameterName) {
        return { ...v, parameterName: par[0].parameterName };
      }
      return v;
    });
    return template;
  };

  const saveScenarioTemplate = async () => {
    // console.log('Saving scenario template: ', getValues());
    const scenarioTemplate: any = await postScenarioTemplate({
      body: createTemplate(getValues()),
    });
    if (scenarioTemplate.error) {
      // console.log(scenarioTemplate.error);
      toast.error('Failed to create scenario template');
    } else {
      reset(scenarioTemplate.data);
      toast.success('Scenario template created successfully');
    }
  };

  const handleAssessmentTreeChange = (assessmentTrees: string[]) => {
    setSelectedTreeIds((prevValue: string[]) => {
      const trees = new Set(prevValue);
      assessmentTrees.forEach((at) => trees.add(at));
      return [...trees];
    });
  };

  return (
    <FormProvider {...methods}>
      <div className="shadow sm:rounded bg-white p-4 mt-2">
        <form
          onSubmit={handleSubmit(handleFormSubmit, (error, value) => {
            console.error(error);
            console.error(value);
          })}
          className="flex flex-col"
        >
          <div className="my-4 flex justify-around">
            <input type="text" {...register('id')} hidden={true} />
            <div className="flex-1">
              <InputText
                className="pr-2"
                label={'Scenario template name'}
                error={getInputError(errors, 'name')}
                {...register('name')}
                // className="flex-1 p-2 focus-visible:outline-none focus:border-2 border-transparent focus:border-blue-500 rounded"
              />
            </div>
            <div className="flex-1">
              <InputText
                className="pl-2"
                label={'Description'}
                error={getInputError(errors, 'description')}
                {...register('description')}
                // className="flex-1 p-2 focus-visible:outline-none focus:border-2 border-transparent focus:border-blue-500 rounded"
              />
            </div>
          </div>
          <InputList
            control={control}
            item={{}}
            addButtonLabel={'Add assessment configuration'}
            name={'assessmentConfigurations'}
            renderItem={(field, index, remove, append) => (
              <div className="flex col-span-12 flex-col">
                <div className=" flex-1 flex border-4 my-4 p-2 rounded">
                  <AssessmentConfigurationForm
                    className="flex-1"
                    phases={phases}
                    trees={trees}
                    onTreeChange={handleAssessmentTreeChange}
                    impactMethods={impactMethods}
                    prefix={`assessmentConfigurations.${index}`}
                  />
                  <HiOutlineTrash className="h-8 w-8 text-red-500 self-center mx-2" onClick={() => remove(index)} />
                </div>
              </div>
            )}
          />

          <div className="mt-6">
            <Button type="submit" className="float-right">
              Add Customization
            </Button>
            <Button className="float-right mr-2" onClick={saveScenarioTemplate}>
              Save
            </Button>

            <div className="flex">
              <CopyToClipboard
                text={JSON.stringify(createTemplate(getValues(), true))}
                onCopy={() => toast.success('Copied to clipboard')}
              >
                <Button variant="none" className="text-sm">
                  <HiDocumentDuplicate className="w-6 h-6" /> Copy as JSON
                </Button>
              </CopyToClipboard>

              <Button variant="none" className="border-l-2 rounded-none text-sm" onClick={() => setImportJson('{}')}>
                <HiClipboardDocument className="w-6 h-8" /> Import from JSON
              </Button>
            </div>
            {importJson ? (
              <div>
                <TextArea rows={10} onChange={(e) => setImportJson(e.target.value)} />
                <Button onClick={handleImportJson}>Import</Button>
              </div>
            ) : null}
          </div>
        </form>
      </div>
      <Modal size="lg" open={importModal} handleOpen={handleImportJson}>
        <div className="my-12">
          <PhaseTreeMapping
            phases={phases}
            trees={trees}
            importMapping={importMapping}
            importJson={importJson}
            onImportJsonChange={mapImportJson}
          />
        </div>
      </Modal>
    </FormProvider>
  );
}

export default ScenarioTemplateForm;
