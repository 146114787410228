import { useEffect } from 'react';
import { useGetAllPhaseQuery } from '../../../api/project/projectApi';
import { Button, Link } from '../../../components/Elements';
import { useBreadcrumbs } from '../../../lib/breadcrumbs';
import ListPhase from '../components/ListPhase';
import { HiPlusCircle } from 'react-icons/hi2';

export const Phases = () => {
  const { setBreadcrumbs } = useBreadcrumbs();

  const { data: phases } = useGetAllPhaseQuery({});

  useEffect(() => {
    setBreadcrumbs([{ label: 'phases' }]);
  }, []);

  return (
    <div className="flex flex-col">
      <div className="my-3">
        <Link to="./new">
          <Button
            className="float-right ml-3 text-blue-500 border-2 bg-white border-blue-500 hover:bg-blue-600 hover:text-white"
            style={{ height: '46px' }}
            variant="none"
            size={'md'}
          >
            <HiPlusCircle className="mr-2 h-6 w-6" /> Create New
          </Button>
        </Link>
      </div>
      <ListPhase phases={phases} />
    </div>
  );
};
