import * as Yup from 'yup';
import { Form } from '../../../components/Form';
import { FormProps } from '../../../components/types';

export const ProjectForm = (props: FormProps) => {
  const { onSubmit, onError, initialValues, submitLabel, formMode = 'EDIT', className } = props;

  const projectValidation = Yup.object().shape({
    name: Yup.string().required('Project name is required').nullable(),
    description: Yup.string().required('Project description is required').nullable(),
  });

  return (
    <Form
      className={className}
      onSubmit={onSubmit}
      onError={onError}
      validationSchema={projectValidation}
      submitLabel={submitLabel || 'Create'}
      initialValues={initialValues}
      editing={true}
    >
      <Form.Item name="id" type="InputText" hidden={true} />

      <Form.Item name="name" type="InputText" label="Project Name" editable={formMode === 'CREATE' || false} />

      <Form.Item name="description" type="InputText" label="Project Description" />
    </Form>
  );
};
