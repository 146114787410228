import { useStateMachine } from 'little-state-machine';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
  CustomizationSpace,
  Parameter,
  useGetCustomizationSpacesQuery,
} from '../../../../api/customizationSpace/customizationSpaceApi';
import { Button } from '../../../../components/Elements';
import { PhaseDto, useGetAllPhaseQuery } from '../../../../api/project/projectApi';

import initScenarioTemplateAction from './../../utils/initScenarioTemplateAction';
import React, { useCallback, useEffect } from 'react';
import { toast } from 'react-hot-toast/headless';
import { FieldValue } from 'react-hook-form/dist/types/fields';
import { InputText, Select } from '../../../../components/Input';
import {
  DisplayConfiguration,
  usePostScenarioTemplateMutation,
  VariableConfiguration,
} from '../../../../api/scenarioTemplate/scenarioTemplateApi';
import PhaseCustomization from './PhaseCustomization';
import ParameterSection from './ParameterSection';
import {
  HiArrowDownOnSquare,
  HiDocumentDuplicate,
  HiLink,
  HiOutlineEye,
  HiQuestionMarkCircle,
  HiXCircle,
} from 'react-icons/hi2';
import { yupResolver } from '@hookform/resolvers/yup';
import { basicScenarioTemplateSchema } from './scenarioTemplateSchema';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import GuidedNavigation from '../../../../components/Navigation/GuidedNavigation';
import { scenarioTemplateSteps } from './ScenarioTemplate';
import Tooltip from '../../../../components/Tooltip/Tooltip';

function ScenarioTemplateCustomization() {
  const navigate = useNavigate();
  const { actions, state } = useStateMachine({ initScenarioTemplateAction });

  const { data: phases } = useGetAllPhaseQuery({});
  const [postScenarioTemplate] = usePostScenarioTemplateMutation();
  const { data: customizationSpaces } = useGetCustomizationSpacesQuery({
    customizationSpaceRefIds: undefined,
  });

  useEffect(() => {
    if (!state.template) {
      navigate(-1);
    } else {
      console.log({ startingTemplate: state.template });
    }
  }, []);

  const methods = useForm({
    defaultValues: { ...state.template },
    resolver: yupResolver(basicScenarioTemplateSchema),
    mode: 'onChange',
  });
  const { register, control, handleSubmit, watch, getValues } = methods;

  const {
    fields: parameterFields,
    append: appendParameter,
    remove,
    move,
  } = useFieldArray({
    control,
    name: 'parameters',
  });

  const { fields: variableConfigurationFields } = useFieldArray({
    control,
    name: `variableConfigurations`,
  });

  const parameters: Parameter[] = watch('parameters');
  const variableConfigurations: VariableConfiguration[] = watch('variableConfigurations');

  const handleParameterCreation = useCallback(
    (toCreate: Parameter[]) => {
      const items = toCreate.filter((v, index) => {
        if (parameters?.some((e: Parameter) => e.parameterName === v.parameterName)) {
          toast.error(`Parameter: ${v.parameterName} already exists. Skipping it.`);
          console.warn(`Parameter: ${v.parameterName} already exists. Skipping it.`);
          return false;
        }
        return true;
      });
      appendParameter(items);
    },
    [appendParameter, parameters],
  );

  const handleBack = () => {
    actions.initScenarioTemplateAction({ template: getValues() });
    navigate(`./../${state.template.id ? state.template.id : 'new'}`);
  };

  const handleFormSubmit = (href: string) => {
    actions.initScenarioTemplateAction({ template: getValues() });
    navigate(href);
  };

  const validateForm = (errors: any) => {
    const filteredErrors = errors.parameters
      .map((ep, index) =>
        ep
          ? {
              index,
              message: ep,
            }
          : undefined,
      )
      .filter(Boolean);
    const es = parameters
      ?.map((p, index) => {
        const error = filteredErrors.find((fe) => fe.index === index);
        if (error) {
          return { ...p, ...error };
        } else {
          return undefined;
        }
      })
      .filter(Boolean);
    es.forEach((e) => {
      toast.error(`Error on parameter: ${e.parameterName}. ${JSON.stringify(e.message)}`);
    });
  };

  const form = watch();
  const saveScenarioTemplate = async () => {
    const scenarioTemplate: any = await postScenarioTemplate({ body: form });
    if (scenarioTemplate.error) {
      toast.error('Failed to create scenario template');
    } else {
      toast.success('Scenario template created successfully');
    }
  };

  return (
    <FormProvider {...methods}>
      <GuidedNavigation
        steps={scenarioTemplateSteps}
        callback={(href: string) => handleFormSubmit(href)}
        className="mt-2"
      />
      <form
        onSubmit={handleSubmit(() => toast.success('Template is valid.'), validateForm)}
        className="flex flex-col mt-2 bg-white"
      >
        <div className="flex-1 mt-2">
          <Tooltip content="Validate" className="float-right mr-2">
            <button className="cursor-pointer" type="submit">
              <HiOutlineEye className="h-12 w-14" />
            </button>
          </Tooltip>

          <Tooltip content="Save" className="float-right mr-2">
            <HiArrowDownOnSquare className="w-14 h-14 p-2 cursor-pointer " onClick={saveScenarioTemplate} />
          </Tooltip>
          <Tooltip content="Copy" className="float-right mr-2 ">
            <CopyToClipboard text={JSON.stringify(getValues())} onCopy={() => toast.success('Copied to clipboard')}>
              <HiDocumentDuplicate className="w-14 h-14 p-2 cursor-pointer" />
            </CopyToClipboard>
          </Tooltip>
        </div>
        <div className="flex-1 my-2 px-2 bg-white rounded">
          <h3 className="ml-4 text-lg font-bold py-4 text-center">Model variables mapping</h3>
          <hr className="bg-gray-300 h-[1px]" />
          <ul className="grid grid-cols-2 py-2">
            {variableConfigurationFields?.map((variableConfigurationField: FieldValue<any>, index: number) => {
              const link = variableConfigurations[index].parameterName;
              return (
                <div
                  key={variableConfigurationField.id}
                  className={'col-span-1 grid grid-cols-12 gap-2 justify-around items-center m-2'}
                >
                  <div className="col-span-1">
                    {!link ? (
                      <HiXCircle className="h-6 w-6 text-red-600" />
                    ) : link === 'UNLINKED' ? (
                      <HiQuestionMarkCircle className="h-6 w-6 text-orange-300" />
                    ) : (
                      <HiLink className="h-6 w-6 text-green-600" />
                    )}
                  </div>
                  <InputText
                    disabled={true}
                    className="col-span-5"
                    label={'Variable name'}
                    {...register(`variableConfigurations.${index}.name`)}
                    addonAfter={<div className="flex-shrink">{variableConfigurationField.value}</div>}
                  />
                  <Select
                    control={control}
                    className="col-span-6"
                    label={'Linked parameter'}
                    name={`variableConfigurations.${index}.parameterName`}
                    options={[
                      {
                        value: 'UNLINKED',
                        label: 'Unlinked',
                      },
                      ...parameters
                        ?.filter((p) => p.type === 'AMOUNT_CHANGE')
                        .map((p) => ({
                          value: p.parameterName,
                          label: p.parameterName,
                        })),
                    ]}
                  />
                </div>
              );
            })}
          </ul>
        </div>

        <div className="bg-white mt-2 rounded">
          <ParameterSection
            type={'AMOUNT_CHANGE'}
            fields={parameterFields.map((p, index) => ({
              ...p,
              index,
              color: variableConfigurations.find((vc: VariableConfiguration) => vc.parameterName === p.parameterName)
                ? ''
                : 'orange',
            }))}
            append={appendParameter}
            remove={remove}
            move={move}
            name={'Parameters'}
          />
        </div>
        <div className="bg-white mt-2 rounded">
          <ParameterSection
            type={'ADDITIONAL_PARAMETER'}
            fields={parameterFields.map((p, index) => ({ ...p, index }))}
            append={appendParameter}
            name={'Additional parameters'}
            remove={remove}
            move={move}
          />
        </div>

        {customizationSpaces &&
          phases &&
          state.template?.displayConfigurations.map((dc: DisplayConfiguration, index) => {
            return (
              <PhaseCustomization
                key={dc.phaseId}
                phase={phases?.find((p) => p.id === dc.phaseId) as PhaseDto}
                displayConfiguration={dc}
                prefix={`displayConfigurations.${index}`}
                onParameterCreation={handleParameterCreation}
                customizationSpaces={customizationSpaces as CustomizationSpace[]}
              />
            );
          })}
      </form>
      <Button onClick={handleBack}>Back</Button>
    </FormProvider>
  );
}

export default ScenarioTemplateCustomization;
