import { useRoutes } from 'react-router-dom';

import { appRoutes, expertRoutes, protectedRoutes } from './protected';
import { publicRoutes } from './public';
import { useAuth } from '../features/auth/AuthProvider';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

export const AppRoutes = () => {
  const { user } = useAuth();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const [initted, setInitted] = useState(false);

  let routes = isAuthenticated ? protectedRoutes : publicRoutes;

  useEffect(() => {
    if (!initted && user && user?.role === 'ADMIN') {
      appRoutes.children.push(...expertRoutes);
      setInitted(true);
    }
  }, [user]);

  const element = useRoutes([...routes]);

  return <>{element}</>;
};
