import { useStateMachine } from 'little-state-machine';
import initScenarioTemplateAction from '../../utils/initScenarioTemplateAction';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { Button } from '../../../../components/Elements';
import TabManagement from './TabManagement';
import { HiArrowDownOnSquare, HiDocumentDuplicate, HiOutlineTrash, HiPlusCircle } from 'react-icons/hi2';
import { InputText } from '../../../../components/Input';
import { toast } from 'react-hot-toast/headless';
import { usePostScenarioTemplateMutation } from '../../../../api/scenarioTemplate/scenarioTemplateApi';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import GuidedNavigation from '../../../../components/Navigation/GuidedNavigation';
import { scenarioTemplateSteps } from './ScenarioTemplate';
import ArrayFieldPositionMover from '../ArrayFieldPositionMover';

function ScenarioTemplateDisplay() {
  const navigate = useNavigate();
  const { actions, state } = useStateMachine({ initScenarioTemplateAction });
  const [postScenarioTemplate] = usePostScenarioTemplateMutation();

  useEffect(() => {
    if (!state.template) {
      navigate('/app/templates/basic');
    }
  }, []);

  const methods = useForm({
    defaultValues: { ...state.template },
    mode: 'onChange',
  });
  const { register, control, handleSubmit, watch, getValues } = methods;
  const {
    fields: groupFields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'groups',
  });

  const { fields: displayConfigurationFields, move: moveDisplayConfigurationField } = useFieldArray({
    control,
    name: `displayConfigurations`,
  });

  const displayConfigurations: any[] = watch('displayConfigurations');

  const handleBack = async () => {
    await actions.initScenarioTemplateAction({ template: getValues() });
    navigate('./../flow-switch');
  };

  const handleFormSubmit = (href: string) => {
    const template = getValues();
    template.displayConfigurations = template.displayConfigurations.map((pc: { tabs: any[] }) => {
      return {
        ...pc,
        tabs: pc.tabs.map((t, index) => ({ ...t, displayOrder: index })),
      };
    });

    // console.log('ScenarioTemplateTabs.payload:', template);
    actions.initScenarioTemplateAction({ template });
    navigate(href);
  };

  const form = watch();
  const saveScenarioTemplate = async () => {
    const scenarioTemplate: any = await postScenarioTemplate({ body: form });
    if (scenarioTemplate.error) {
      console.log(scenarioTemplate.error);
      toast.error('Failed to create scenario template');
    } else {
      toast.success('Scenario template created successfully');
    }
  };

  return (
    <FormProvider {...methods}>
      <GuidedNavigation
        steps={scenarioTemplateSteps}
        callback={(href: string) => handleFormSubmit(href)}
        className="mt-2"
      />
      <div className="">
        <form
          onSubmit={handleSubmit(
            () => console.log('No errors'),
            (error) => {
              console.error({ error });
            },
          )}
          className="flex flex-col bg-white mt-2"
        >
          <div className="flex-1 mt-2">
            <HiArrowDownOnSquare
              className="w-14 h-14 p-2 cursor-pointer float-right mr-2 "
              onClick={saveScenarioTemplate}
            />
            <CopyToClipboard text={JSON.stringify(getValues())} onCopy={() => toast.success('Copied to clipboard')}>
              <HiDocumentDuplicate className="w-14 h-14 p-2 float-right mr-2 cursor-pointer" />
            </CopyToClipboard>
          </div>
          <div className="flex gap-4 flex-col">
            <h3 className="flex-1 text-lg text-blue-600 font-bold my-4 text-center">Phases order management</h3>
            <hr className="bg-gray-300 h-[1px]" />
            <div className="flex-1 flex flex-col">
              {displayConfigurationFields.map((field, index) => {
                return (
                  <div key={field.id} className="flex-1 flex flex-col border-2 border-gray-600 m-2 py-2 rounded">
                    <div className="flex mx-2">
                      <InputText
                        className="flex-1"
                        {...register(`displayConfigurations.${index}.phaseName`)}
                        label={'Phase name'}
                      />
                      <ArrayFieldPositionMover
                        fields={displayConfigurations}
                        move={moveDisplayConfigurationField}
                        index={index}
                      />
                    </div>
                    <div className="bg-white rounded p-2 my-2">
                      <TabManagement prefix={`displayConfigurations.${index}`} />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="flex-1">
            <h3 className={`text-lg text-blue-600 font-bold my-4 text-center`}>Groups</h3>
            <div className="bg-white rounded p-2 m-2 mt-8 border-2 grid grid-cols-8">
              {groupFields.map((groupField: any, index: number) => {
                return (
                  <div key={groupField.id} className="col-span-2 p-1 m-1 flex flex-row  justify-around">
                    <InputText
                      {...register(`groups.${index}.name`)}
                      addonAfter={
                        <HiOutlineTrash className="h-6 w-6 cursor-pointer text-red-500" onClick={() => remove(index)} />
                      }
                      label={'Group name'}
                    />
                  </div>
                );
              })}
              <Button
                type="button"
                variant={'none'}
                className={`col-span-2 flex border-2 my-2`}
                onClick={() => append({ name: '' })}
              >
                <div className="flex items-center">
                  <HiPlusCircle className="h-6 w-6 mr-4" />
                  Create Group
                </div>
              </Button>
            </div>
          </div>
        </form>
      </div>
    </FormProvider>
  );
}

export default ScenarioTemplateDisplay;
