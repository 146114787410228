import React, { FC, PropsWithChildren } from 'react';
import { HiInformationCircle, HiXCircle, HiXMark } from 'react-icons/hi2';

type ConfirmationPopupProps = PropsWithChildren<{
  onConfirm: () => void;
  onCancel: () => void;
}>;

const ConfirmationPopup: FC<ConfirmationPopupProps> = ({ onConfirm, onCancel, children }: ConfirmationPopupProps) => {
  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 flex justify-center items-center bg-black bg-opacity-50 z-10">
      <div className="bg-white rounded shadow text-center border-b-1 border-gray-500">
        <div className="w-full text-gray-500 p-2">
          <HiXMark
            className="h-6 w-6 float-right hover:text-gray-100 hover:bg-gray-400 rounded cursor-pointer"
            onClick={onCancel}
          />
        </div>
        <div>{children}</div>
        <div className="flex justify-center pb-2">
          <button
            className="bg-white text-blue-500 border-2 border-blue-500 p-2 m-2 rounded hover:bg-blue-600 hover:text-white w-1/3"
            onClick={onConfirm}
          >
            Yes
          </button>
          <button
            className="bg-white text-red-500 border-2 border-red-500 p-2 m-2 rounded hover:bg-red-600 hover:text-white w-1/3"
            onClick={onCancel}
          >
            No
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationPopup;
