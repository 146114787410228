import React from 'react';
import { Outlet } from 'react-router-dom';
import { MainLayout } from '../components/Layout';
import NotFound from '../Error';
import { ProjectRoutes } from '../features/project/routes';
import { ScenarioRoutes } from '../features/scenario/routes';
import { AssessmentRoutes } from '../features/assessment/routes';
import { ProcessRoutes } from '../features/modeled-process/routes';
import { TemplateRoutes } from '../features/template/routes';
import { PhaseRoutes } from '../features/phase/routes';
import { CustomizationSpaceRoutes } from '../features/customization-space/routes';
import { ComparisonRoutes } from '../features/comparison/routes';
import { TagRoutes } from '../features/tag/routes';
import { CustomizationRoutes } from '../features/customization-process/routes';
import { ImpactMethodsRoutes } from '../features/impact-method/routes';
import { BomRoutes } from '../features/bom-tree/routes';
import { IntegrationsRoutes } from '../features/integration/routes';
import { RemoteFlowRoutes } from '../features/remote-flow/routes';
import app from '../App';

const App = () => {
  return (
    <MainLayout>
      <Outlet />
    </MainLayout>
  );
};

const Test = () => {
  return <Outlet />;
};

const LorisTest = (props) => {
  return (
    <div className="bg-white h-screen grid grid-cols-6" style={{ width: '80vw' }}>
      <div className={'col-span-2'}></div>
    </div>
  );
};

export const appRoutes = {
  path: '/',
  element: <App />,
  children: [
    { path: 'app/projects/*', element: <ProjectRoutes /> },
    { path: 'app/scenarios/*', element: <ScenarioRoutes /> },
    { path: 'app/assessments/*', element: <AssessmentRoutes /> },
    { path: 'app/comparisons/*', element: <ComparisonRoutes /> },
    { path: 'app/customizations/*', element: <CustomizationRoutes /> },
    { path: '*', element: <NotFound /> },
  ],
};

export const protectedRoutes = [
  appRoutes,
  {
    path: '/test',
    element: <Test />,
    children: [{ path: '1', element: <LorisTest /> }],
  },
];

export const expertRoutes = [
  { path: 'app/modeled-processes/*', element: <ProcessRoutes /> },
  { path: 'app/templates/*', element: <TemplateRoutes /> },
  { path: 'app/phases/*', element: <PhaseRoutes /> },
  { path: 'app/tags/*', element: <TagRoutes /> },
  {
    path: 'app/customization-spaces/*',
    element: <CustomizationSpaceRoutes />,
  },
  { path: 'app/bom/*', element: <BomRoutes /> },
  { path: 'app/impact-methods/*', element: <ImpactMethodsRoutes /> },
  { path: 'app/integrations/*', element: <IntegrationsRoutes /> },
  { path: 'app/remote-flows/*', element: <RemoteFlowRoutes /> },
];
