import { HiArrowPathRoundedSquare } from 'react-icons/hi2';
import { useState } from 'react';
import { Parameter } from '../../../api/customizationSpace/customizationSpaceApi';
import { useInvokeFlowMutation } from '../../../api/remoteDispatcher/remoteDispatcherApi';
import { InputText } from '../../../components/Input';
import { ParameterType } from '../../../api/customizationSpace/types/ParameterType';

type RemoteInputProps = Parameter & {
  flowId: string;
  parameters: Parameter[];
};

export const RemoteInput = (props: RemoteInputProps) => {
  const { flowId, parameters } = props;

  const [remoteValue, setRemoteValue] = useState<number>();
  const [invokeFlow] = useInvokeFlowMutation();

  const getRemoteValue = async () => {
    const formulaScope: any = parameters?.reduce((acc, p) => {
      if (ParameterType[p.parameterType] !== ParameterType.FORMULA) {
        return {
          ...acc,
          [p.parameterName]: p.value ?? 0,
        };
      } else {
        return {
          ...acc,
          //TO-DO - evaluate formula for backend
        };
      }
    }, {});

    // console.log({ formulaScope });

    const result = await invokeFlow({
      flowId,
      body: formulaScope,
    });
    // console.log({ rv })
    if (result && !('error' in result)) {
      setRemoteValue(result.data);
    }
  };

  return (
    <InputText
      disabled={true}
      value={remoteValue}
      placeHolder="This value is received by a remote source"
      addonAfter={
        <HiArrowPathRoundedSquare
          className="text-blue-700 ml-2 h-6 w-6 bg-blue-100 hover:bg-blue-200 rounded cursor-pointer"
          onClick={getRemoteValue}
        />
      }
    />
  );
};
