import { useStateMachine } from 'little-state-machine';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import initScenarioTemplateAction from './../../utils/initScenarioTemplateAction';
import ParameterSection from './ParameterSection';
import { toast } from 'react-hot-toast/headless';
import { usePostScenarioTemplateMutation } from '../../../../api/scenarioTemplate/scenarioTemplateApi';
import { HiArrowDownOnSquare, HiDocumentDuplicate } from 'react-icons/hi2';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import GuidedNavigation from '../../../../components/Navigation/GuidedNavigation';
import { scenarioTemplateSteps } from './ScenarioTemplate';

function ScenarioTemplateFlowSwitch() {
  const navigate = useNavigate();
  const { actions, state } = useStateMachine({ initScenarioTemplateAction });
  const [postScenarioTemplate] = usePostScenarioTemplateMutation();

  useEffect(() => {
    if (!state.template) {
      navigate(-1);
    }
  }, []);

  const methods = useForm({
    defaultValues: { ...state.template },
    mode: 'onChange',
  });
  const { control, handleSubmit, watch, getValues } = methods;

  const {
    fields: parameterFields,
    append: appendParameter,
    remove,
    move,
  } = useFieldArray({
    control,
    name: 'parameters',
  });

  const handleBack = async () => {
    await actions.initScenarioTemplateAction({ template: getValues() });
    navigate('./../customization');
  };

  const handleFormSubmit = (href: string) => {
    // console.log('ScenarioTemplateCustomization.payload:', getValues());
    actions.initScenarioTemplateAction({ template: getValues() });
    navigate(href);
  };

  const form = watch();
  const saveScenarioTemplate = async () => {
    console.log('Saving scenario template: ', form);
    const scenarioTemplate: any = await postScenarioTemplate({ body: form });
    if (scenarioTemplate.error) {
      console.log(scenarioTemplate.error);
      toast.error('Failed to create scenario template');
    } else {
      toast.success('Scenario template created successfully');
    }
  };

  return (
    <FormProvider {...methods}>
      <GuidedNavigation
        steps={scenarioTemplateSteps}
        callback={(href: string) => handleFormSubmit(href)}
        className="mt-2"
      />
      <div className="flex-1 mt-2">
        <HiArrowDownOnSquare
          className="w-12 h-12 p-2 cursor-pointer float-right mr-2 "
          onClick={saveScenarioTemplate}
        />
        <CopyToClipboard text={JSON.stringify(getValues())} onCopy={() => toast.success('Copied to clipboard')}>
          <HiDocumentDuplicate className="w-12 h-12 p-2 float-right mr-2 cursor-pointer" />
        </CopyToClipboard>
      </div>
      <div className="flex-1">
        <ParameterSection
          type={'FLOW_SWITCH'}
          parameterType={'OPTION'}
          fields={parameterFields.map((p, index) => ({ ...p, index }))}
          append={appendParameter}
          remove={remove}
          move={move}
          name={'Flow switches'}
        />
      </div>
    </FormProvider>
  );
}

export default ScenarioTemplateFlowSwitch;
