import { useEffect } from 'react';
import { useGetAllBomsQuery } from '../../../api/bom/bomApi';
import { useBreadcrumbs } from '../../../lib/breadcrumbs';
import ListBom from '../components/ListBom';

export const Boms = () => {
  const { setBreadcrumbs } = useBreadcrumbs();

  const { data: boms } = useGetAllBomsQuery({});

  useEffect(() => {
    setBreadcrumbs([{ label: 'boms' }]);
  }, []);

  return (
    <div className="flex flex-col">
      <ListBom boms={boms} />
    </div>
  );
};
