const storagePrefix: string = 'react_';

const storage = {
  getToken: () => {
    return localStorage.getItem(`${storagePrefix}accessToken`);
  },
  setToken: (token: string) => {
    localStorage.setItem(`${storagePrefix}accessToken`, token);
  },
  clearToken: () => {
    localStorage.removeItem(`${storagePrefix}accessToken`);
  },

  getUsername: () => {
    return localStorage.getItem(`${storagePrefix}username`);
  },
  setUsername: (username: string) => {
    localStorage.setItem(`${storagePrefix}username`, username);
  },
  clearUsername: () => {
    localStorage.removeItem(`${storagePrefix}username`);
  },

  getTargetRealm: () => {
    return localStorage.getItem(`${storagePrefix}targetRealm`);
  },
  setTargetRealm: (targetRealm: string) => {
    localStorage.setItem(`${storagePrefix}targetRealm`, targetRealm);
  },
  clearTargetRealm: () => {
    localStorage.removeItem(`${storagePrefix}targetRealm`);
  },
};

export default storage;
