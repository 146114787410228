import React, { useEffect, useMemo, useState } from 'react';
import { Attribute, Bom, Item } from '../../../api/bom/bomApi';
import BomTreeNode from './BomTreeNode';
import '../style/style.css';
import classNames from '../../../utils/classNames';
import { HiInformationCircle } from 'react-icons/hi2';
import { useResizeDetector } from 'react-resize-detector';
import { Transition } from '@headlessui/react';
import { LuListTree } from 'react-icons/lu';

type BillOfMaterialProps = {
  bom: Bom;
  className?: string;
  itemContent?: (
    item: Item & {
      parentId?: string;
    },
    selectedItemId: string | undefined,
    items: Item[],
  ) => React.ReactElement | string | undefined;
  nodeAppendix?: (
    item: Item & {
      parentId?: string;
    },
    width: number,
    items: Item[],
  ) => React.ReactElement | string | null;
  nodeConfiguration: {
    nodeContent?: (item: Item & { parentId?: string }, items: Item[]) => React.ReactNode;
    backgroundColor?: ((item: Item & { parentId?: string }, items: Item[]) => string) | string;
    borderColor?: ((item: Item & { parentId?: string }, items: Item[]) => string) | string;
  };
};

function BillOfMaterial(props: BillOfMaterialProps) {
  const { bom, nodeConfiguration, className, nodeAppendix, itemContent } = props;

  const [item, setItem] = useState<any>();
  const [compressedView, setCompressedView] = useState(false);
  const [render, setRender] = useState<boolean>(false);
  const [treeExpanded, setTreeExpanded] = useState<boolean>(true);
  const [info, setInfo] = useState<boolean>(false);

  const topLevelItem = useMemo(() => {
    const item = bom?.items?.find((item) => item.root);
    setItem({ ...item, parentId: 'root' });
    return item;
  }, [bom]);
  const itemContentRef = React.useRef<any>(null);
  // const ref = React.useRef<any>(null);

  const { width, ref } = useResizeDetector();
  useEffect(() => {
    if (width) {
      setRender(true);
      if (!nodeAppendix) setCompressedView(width ? width < 1100 : false);
    }
  }, [width]);

  const handleSelectItem = (item: any) => {
    setTreeExpanded(!item);
    setItem(item);
  };

  return (
    <div
      className={classNames(
        className ? className : '',
        'tree-container flex flex-row flex-grow bg-white relative my-3 px-2 z-0',
      )}
      ref={ref}
    >
      {!nodeAppendix && treeExpanded && compressedView ? (
        <div
          className="bg-opacity-50 absolute bg-gray-300 z-[4] w-2/3 h-full right-0 cursor-pointer"
          onClick={() => setTreeExpanded(false)}
        ></div>
      ) : null}
      {render && topLevelItem && (
        <>
          <Transition
            show={!treeExpanded && compressedView}
            enter="transition-opacity duration-250"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-250"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="outside-circle cursor-pointer" onClick={() => setTreeExpanded(true)}>
              <LuListTree className="h-6 w-6 m-3 z-50" />
            </div>
          </Transition>

          <div
            className={classNames(
              !treeExpanded && compressedView ? 'treeview-closed' : '',
              compressedView ? 'treeview-compressed' : '',
              info ? 'h-3/4' : 'h-full',
              'treeview overflow-y-scroll flex flex-col bg-white flex-[7] pb-4 mb-4 relative ',
            )}
          >
            <ul>
              <BomTreeNode
                className="p-2 mt-6"
                parentId={'root'}
                displayAppendix={true}
                node={topLevelItem}
                items={bom?.items}
                quantity={1}
                nodeAppendix={nodeAppendix}
                nodeConfiguration={nodeConfiguration}
                selectedItem={item}
                itemContentRef={itemContentRef}
                onSelectItem={handleSelectItem}
                itemContent={itemContent}
                containerWidth={width as number}
              />
            </ul>
            <HiInformationCircle
              className={classNames(
                nodeAppendix ? 'hidden' : '',
                info ? 'text-blue-400' : 'text-gray-500',
                'h-8 w-8 cursor-pointer flex-shrink absolute right-0 top-2',
              )}
              onClick={() => setInfo(!info)}
            />
          </div>
          {!nodeAppendix && info && (!compressedView || (treeExpanded && compressedView)) ? (
            <div
              className={classNames(
                compressedView && treeExpanded
                  ? 'compressed-info transition-all duration-500 ease-linear'
                  : ' full-info',
                'overflow-y-scroll border-r-8 border-b-8 border-white bg-gray-200 z-10 h-1/4 p-2 absolute bottom-0',
              )}
            >
              {item?.attributes.map((a: Attribute) => (
                <p>
                  {a.name}: {a.value}
                </p>
              ))}
            </div>
          ) : null}
        </>
      )}

      {/* Below item content display using a portal*/}
      <div
        className={classNames(
          compressedView && !treeExpanded ? '' : 'border-l',
          'flex-1 flex flex-col overflow-x-scroll',
        )}
        style={{ flex: `${nodeAppendix ? '0' : '7'} 0`, width: 'inherit' }}
      >
        {itemContent ? <div className={classNames('flex-grow')} ref={itemContentRef}></div> : null}
      </div>
    </div>
  );
}

export default BillOfMaterial;
