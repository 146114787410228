import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { PhaseDto, useGetPhaseQuery, usePostPhaseMutation } from '../../../api/project/projectApi';
import { Spinner } from '../../../components/Elements';
import { useBreadcrumbs } from '../../../lib/breadcrumbs';
import { PhaseForm } from './PhaseForm';
import { toast } from 'react-hot-toast/headless';

function Phase() {
  const { phaseId } = useParams();
  const { data, error, isLoading } = useGetPhaseQuery(phaseId ? { phaseId: phaseId } : skipToken);
  const [postPhase] = usePostPhaseMutation();

  const { setBreadcrumbs } = useBreadcrumbs();

  useEffect(() => {
    if (data) {
      setBreadcrumbs([{ label: 'phases', path: '/app/phases' }, { label: `${data.name}` }]);
    }
  }, [data]);

  const onSubmit = async (phase: PhaseDto) => {
    const updatedPhase: any = await postPhase({ phaseDto: phase }).unwrap();
    if (updatedPhase.error) {
      // console.log(updatedPhase.error);
      toast.error('Failed to update phase');
    } else {
      toast.success('Phase updated successfully');
    }
  };

  if (isLoading) {
    return <Spinner size={'h-14 w-14'} className="w-12 h-12 self-center m-5" />;
  }

  return <PhaseForm onSubmit={onSubmit} initialValues={data} submitLabel={'Update'} />;
}

export default Phase;
