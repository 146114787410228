import { useEffect } from 'react';
import { Button, Link, Spinner } from '../../../components/Elements';
import { useBreadcrumbs } from '../../../lib/breadcrumbs';
import ListTag from '../components/ListTag';
import { useGetAllTagQuery } from '../../../api/project/projectApi';
import { HiPlusCircle } from 'react-icons/hi2';

export const Tags = () => {
  const { setBreadcrumbs } = useBreadcrumbs();
  const { data: tags, error, isLoading } = useGetAllTagQuery({});

  useEffect(() => {
    setBreadcrumbs([{ label: 'tags' }]);
  }, []);

  if (isLoading) {
    return <Spinner size={'h-14 w-14'} className="w-12 h-12 self-center m-5" />;
  }

  return (
    <div className="flex flex-col">
      <div className="my-3">
        <Link to="./new">
          <Button
            className="float-right ml-3 text-blue-500 border-2 bg-white border-blue-500 hover:bg-blue-600 hover:text-white"
            style={{ height: '46px' }}
            variant="none"
            size={'md'}
          >
            <HiPlusCircle className="mr-2 h-6 w-6" /> Create New
          </Button>
        </Link>
      </div>
      <ListTag tags={tags} />
    </div>
  );
};
