import { useLocation, useNavigate } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { useAuth } from '../AuthProvider';
import { AuthDTO } from '../types/authTypes';
import logo from '../../../logo.png';
import { InputText } from '../../../components/Input';
import { Button } from '../../../components/Elements';
import { useMemo } from 'react';
import { toast } from 'react-hot-toast/headless';

type LoginFormProps = {
  onSuccess: () => void;
};

export const LoginForm = ({ onSuccess }: LoginFormProps) => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);

  const { login } = useAuth();

  // console.log(location.)

  const handleOnSubmit = (values: AuthDTO) => {
    login(
      values.username,
      values.password,
      '',
      () =>
        queryParams.has('redirectUrl') ? navigate(queryParams.get('redirectUrl') as string) : navigate('/app/projects'),
      () => {
        toast.error('Username or password is incorrect');
        // console.log("Inside the onError: has redirect? ", queryParams.has("redirectUrl"))
        // console.log("Inside the onError: get redirect? ", queryParams.get("redirectUrl"))
        if (queryParams.has('redirectUrl')) {
          // navigate(('/login?redirectUrl=' + queryParams.get('redirectUrl')) as string);
        }
      },
    );
  };

  const methods = useForm();

  return (
    <FormProvider {...methods}>
      <div className="bg-[#eef2f6] h-screen w-screen items-center flex flex-col">
        <div className="w-7xl px-auto p-12 flex flex-col bg-white rounded mt-36">
          <img className="h-20 mx-auto mt-4 mb-8" src={logo} alt="Your Company" />
          <form onSubmit={methods.handleSubmit(handleOnSubmit)} className="rounded px-4 pt-4 flex gap-8 flex-col">
            <InputText {...methods.register('username')} label="Username" />
            <InputText {...methods.register('password')} label="Password" type="password" />
            <Button
              variant={'none'}
              type="submit"
              className="w-full text-blue-500 border-2 bg-white border-blue-500 hover:bg-blue-600 hover:text-white"
            >
              Login
            </Button>
          </form>
        </div>
      </div>
    </FormProvider>
  );
};
