import { ScenarioDto } from '../../../api/project/projectApi';
import { DefaultProps } from '../../../components/Carousel/Carousel';
import classNames from '../../../utils/classNames';
import ActionMenu, { MenuItem } from './ActionMenu';
import ScenarioTagSelector from './ScenarioTagSelector';

type CustomizationNavBarProps = {
  scenario: ScenarioDto;
  items: MenuItem[];
} & DefaultProps;

export default function CustomizationNavBar(props: CustomizationNavBarProps) {
  const { scenario, items, className } = props;

  return (
    <div className={classNames(className ? className : '', 'bg-white rounded')}>
      <ScenarioTagSelector scenario={scenario} />
      <ActionMenu className="float-right mt-3.5 mr-1" items={items} />
    </div>
  );
}
