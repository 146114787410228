import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Bom, useGetBomQuery } from '../../../../api/bom/bomApi';
import BillOfMaterial from '../../../bom-tree/components/BillOfMaterial';
import {
  ItemScenarioTemplatePair,
  usePostScenarioTemplateMutation,
} from '../../../../api/scenarioTemplate/scenarioTemplateApi';
import React, { useEffect } from 'react';
import { useStateMachine } from 'little-state-machine';
import initScenarioTemplateAction from '../../utils/initScenarioTemplateAction';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import VariableConfigurationsManager from './VariableConfigurationsManager';
import ButModal from '../../../../components/Modal/ButModal';
import ParameterSection from '../BasicScenarioTemplate/ParameterSection';
import { Button } from '../../../../components/Elements';
import { toast } from 'react-hot-toast/headless';

function BomScenarioTemplateForm() {
  const navigate = useNavigate();
  const { actions, state } = useStateMachine({ initScenarioTemplateAction });
  const [postScenarioTemplate] = usePostScenarioTemplateMutation();
  const { data: bom } = useGetBomQuery(state.template ? { bomId: state.template.bomId } : skipToken);

  useEffect(() => {
    if (!state.template) {
      navigate(-1);
    }
  }, []);

  const methods = useForm({
    defaultValues: { ...state.template },
    // resolver: yupResolver(basicScenarioTemplateSchema),
    mode: 'onChange',
  });
  const { register, control, handleSubmit, watch, getValues } = methods;

  const {
    fields: parameterFields,
    append: appendParameter,
    remove,
    move,
  } = useFieldArray({
    control,
    name: 'parameters',
  });
  const handleFormSubmit = async (values) => {
    console.log('Template', { values });
    const scenarioTemplate: any = await postScenarioTemplate({ body: values });
    if (scenarioTemplate.error) {
      console.log(scenarioTemplate.error);
      toast.error('Failed to create scenario template');
    } else {
      toast.success('Scenario template created successfully');
    }
  };

  const template = watch();
  const parameters = watch('parameters');

  return (
    <FormProvider {...methods}>
      <div className="shadow sm:overflow-hidden sm:rounded bg-white p-4 h-full">
        <form
          onSubmit={handleSubmit(handleFormSubmit, (error) => {
            console.error(error);
          })}
          className=""
        >
          <BillOfMaterial
            bom={bom as Bom}
            nodeConfiguration={{
              nodeContent: (item) => {
                let specific = true;
                let config = template.itemScenarioTemplateConfiguration?.find(
                  (i: ItemScenarioTemplatePair) => i.parentId && i.itemId === item.id && i.parentId === item.parentId,
                );

                if (!config) {
                  config = template.itemScenarioTemplateConfiguration?.find(
                    (i: ItemScenarioTemplatePair) => !i.parentId && i.itemId === item.id,
                  );
                  specific = false;
                }
                if (!config) {
                  return `${item.description}:${item.id}`;
                } else {
                  // console.log("Item: ", item, config)
                  if (config.scenarioTemplateId) {
                    return (
                      <span className={specific ? 'bg-blue-200' : 'bg-green-200'}>
                        {item.description}:{item.id}: success
                      </span>
                    );
                  } else {
                    return (
                      <span className="bg-red-200">
                        {item.description}:{item.id}: error
                      </span>
                    );
                  }
                }
              },
            }}
            itemContent={(item, selectedItemId) => {
              //Searching first for an individual config (for the specific item)
              let config = template.itemScenarioTemplateConfiguration?.find(
                (i) => i.itemId === item.id && i.parentId === item.parentId,
              );
              if (!config) {
                //if an individual config does not exist I search for a specific one
                config = template.itemScenarioTemplateConfiguration?.find((i) => i.itemId === item.id && !i.parentId);
              }

              if (config) {
                const itemParameterNames = parameters
                  .filter((p) => p.display?.item === `:${config.parentId ? `${config.parentId}:` : ''}${config.itemId}`)
                  .map((p) => p.parameterName);

                // console.log(
                //   'ParentID: ',
                //   { itemParameterNames },
                //   {
                //     parameterFields: parameterFields.filter((p) => itemParameterNames.includes(p.parameterName)),
                //   },
                // );
                console.log({ config });

                return (
                  <div>
                    <h3 className="text-center text-lg font-bold mb-6">
                      {item.description} ({config.parentId}:{config.itemId})
                    </h3>
                    <ButModal label={'Variable configuration'} className="my-2" size={'xl'}>
                      <VariableConfigurationsManager
                        prefix={`itemScenarioTemplateConfiguration.${config.index}`}
                        parameters={template.parameters}
                      />
                    </ButModal>
                    <ButModal label={'Parameters Management'} className="my-2" size={'xl'}>
                      <div className="flex-1 p-2 gap-2 my-2">
                        {['AMOUNT_CHANGE', 'ADDITIONAL_PARAMETER', 'FLOW_SWITCH'].map((type) => {
                          return (
                            <ParameterSection
                              key={type}
                              type={type as 'AMOUNT_CHANGE' | 'FLOW_SWITCH' | 'ADDITIONAL_PARAMETER'}
                              fields={parameterFields
                                .map((p, index) => ({ ...p, index }))
                                .filter((p, index) => itemParameterNames.includes(p.parameterName))}
                              append={appendParameter}
                              remove={remove}
                              move={move}
                              name={type}
                              includeDisplay={true}
                              displayConfigurations={config.displayConfigurations}
                            />
                          );
                        })}
                      </div>
                    </ButModal>
                  </div>
                );
              }
            }}
          />
          <Button type="submit">Save</Button>
        </form>
      </div>
    </FormProvider>
  );
}

export default BomScenarioTemplateForm;
