import { array, object, string } from 'yup';

export const basicScenarioTemplateSchema = object().shape({
  name: string().required('Template name is required').nullable(),
  // description: string().required('Template description is required').nullable(),
  phaseConfigurations: array()
    .of(
      object().shape({
        treeId: string().required('ModeledProcess is required'),
        phaseId: string().required('Phase is required'),
      }),
    )
    .min(1, 'A phase is required')
    .required(),
  defaultImpactMethod: string().required('A default impact methodology must be specified'),
  assessmentTypes: array()
    .min(1, 'An assessment type must be specified')
    .required('An assessment type must be specified'),
  parameters: array().of(
    object().shape({
      parameterName: string().required('Parameter name is required'),
      alias: string().required('Alas is required'),
      parameterType: string().required('Parameter type is required'),
      options: array()
        .of(object().shape({}))
        .when(['parameterType'], {
          is: (value) => value === 'OPTION', // alternatively: (isBig, isSpecial) => isBig && isSpecial
          then: (schema) => schema.min(1),
          otherwise: (schema) => schema.nullable(),
        }),
      display: object().shape({
        phaseId: string().required('A display phase must be selected'),
        tab: string().required('A display tab must be selected'),
      }),
    }),
  ),
});
