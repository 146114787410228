import { useStateMachine } from 'little-state-machine';
import initScenarioTemplateAction from '../../utils/initScenarioTemplateAction';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import BasicParameterForm from '../Parameter/BasicParameterForm';
import { useGetAllPhaseQuery } from '../../../../api/project/projectApi';
import DisplayManagement from './DisplayManagement';
import { basicScenarioTemplateSchema } from './scenarioTemplateSchema';
import { toast } from 'react-hot-toast/headless';
import { usePostScenarioTemplateMutation } from '../../../../api/scenarioTemplate/scenarioTemplateApi';
import { HiArrowDownOnSquare, HiDocumentDuplicate, HiOutlineEye } from 'react-icons/hi2';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import GuidedNavigation from '../../../../components/Navigation/GuidedNavigation';
import { scenarioTemplateSteps } from './ScenarioTemplate';

function ScenarioTemplateParameters() {
  const navigate = useNavigate();
  const { actions, state } = useStateMachine({ initScenarioTemplateAction });
  const [postScenarioTemplate] = usePostScenarioTemplateMutation();
  const { data: phases } = useGetAllPhaseQuery({});

  useEffect(() => {
    if (!state.template) {
      navigate(-1);
    }
  }, []);

  const methods = useForm({
    defaultValues: { ...state.template },
    resolver: yupResolver(basicScenarioTemplateSchema),
    mode: 'onChange',
  });
  const { control, handleSubmit, watch, getValues } = methods;

  const { fields: parameterFields, append: appendParameter } = useFieldArray({
    control,
    name: 'parameters',
  });

  const displayConfigurations: any[] = watch('displayConfigurations');

  const handleFormSubmit = (href: string) => {
    // console.log('ScenarioTemplateCustomization.payload:', getValues());
    actions.initScenarioTemplateAction({ template: getValues() });
    navigate(href);
  };

  const validateForm = (errors) => {
    toast.error(errors);
  };

  const form = watch();
  const saveScenarioTemplate = async () => {
    console.log('Saving scenario template: ', form);
    const scenarioTemplate: any = await postScenarioTemplate({ body: form });
    if (scenarioTemplate.error) {
      console.log(scenarioTemplate.error);
      toast.error('Failed to create scenario template');
    } else {
      toast.success('Scenario template created successfully');
    }
  };

  return (
    <FormProvider {...methods}>
      <GuidedNavigation
        steps={scenarioTemplateSteps}
        callback={(href: string) => handleFormSubmit(href)}
        className="mt-2"
      />
      <form
        onSubmit={handleSubmit(() => toast.success('Template is valid'), validateForm)}
        className="bg-white mt-2 flex flex-col"
      >
        <div className="flex-1 mt-2">
          <button className="cursor-pointer float-right mr-2 mt-1" type="submit">
            <HiOutlineEye className="h-12 w-12" />
          </button>
          <HiArrowDownOnSquare
            className="w-14 h-14 p-2 cursor-pointer float-right mr-2 "
            onClick={saveScenarioTemplate}
          />
          <CopyToClipboard text={JSON.stringify(getValues())} onCopy={() => toast.success('Copied to clipboard')}>
            <HiDocumentDuplicate className="w-14 h-14 p-2 float-right mr-2 cursor-pointer" />
          </CopyToClipboard>
        </div>
        <div className="flex-1 grid grid-cols-2 p-2 gap-2 my-2">
          {parameterFields.map((parameterField: any, index: number) => {
            return (
              <div key={parameterField.id} className="col-span-1 rounded bg-white p-4 border">
                <BasicParameterForm {...parameterField} defaultValues={{}} prefix={`parameters.${index}`} />
                <DisplayManagement
                  displayConfigurations={displayConfigurations}
                  parameterPrefix={`parameters.${index}.display`}
                />
              </div>
            );
          })}
        </div>
      </form>
    </FormProvider>
  );
}

export default ScenarioTemplateParameters;
