import React, { useEffect } from 'react';
import { Button, Link } from '../../../components/Elements';
import { useBreadcrumbs } from '../../../lib/breadcrumbs';
import ListTemplate from '../components/ListTemplate';
import { HiPlusCircle } from 'react-icons/hi2';

export const Templates = ({ type }: { type?: 'BOM' | 'BASIC' }) => {
  const { setBreadcrumbs } = useBreadcrumbs();

  useEffect(() => {
    setBreadcrumbs([{ label: 'templates' }]);
  }, []);

  return (
    <div className="flex flex-col">
      {type ? (
        <div className="mt-3">
          <Link to={`/app/templates/${type}/new`}>
            <Button
              className="float-right ml-3 text-blue-500 border-2 bg-white border-blue-500 hover:bg-blue-600 hover:text-white"
              style={{ height: '46px' }}
              variant="none"
              size={'md'}
            >
              <HiPlusCircle className="mr-2 h-6 w-6" /> Create New
            </Button>
          </Link>
        </div>
      ) : null}
      <ListTemplate type={type} />
    </div>
  );
};
