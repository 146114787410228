import { HiFolderOpen, HiOutlinePlusCircle, HiOutlineTrash } from 'react-icons/hi2';

import {
  FlowDto,
  RemoteFlow,
  useDeleteRemoteFlowByFlowIdMutation,
  useGetAllRemoteFlowsQuery,
  useGetNodeRedFlowsQuery,
  usePostRemoteFlowMutation,
} from '../../../api/remoteDispatcher/remoteDispatcherApi';
import { Button } from '../../../components/Elements';
import { toast } from 'react-hot-toast/headless';
import ConfirmationPopup from '../../../components/ConfirmationPopup/ConfirmationPopup';
import React, { useState } from 'react';
import classNames from '../../../utils/classNames';

function ListRemoteFlow() {
  const [isConfirmDeleteRemoteFlowPopupOpen, setConfirmDeleteRemoteFlowPopupOpen] = useState<boolean>(false);
  const [selectedRemoteFlowToDelete, setSelectedRemoteFlowToDelete] = useState<FlowDto | undefined>(undefined);

  const { data: noderedFlows, error: noderedError, isLoading: isNoderedLoading } = useGetNodeRedFlowsQuery({});
  const { data: remoteFlows, error: flowError, isLoading: isFlowsLoading } = useGetAllRemoteFlowsQuery({});

  const [deleteRemoteFlow] = useDeleteRemoteFlowByFlowIdMutation();
  const [postRemoteFlow] = usePostRemoteFlowMutation();

  const handleDelete = async (noderedFlow: FlowDto) => {
    setConfirmDeleteRemoteFlowPopupOpen(true);
    setSelectedRemoteFlowToDelete(noderedFlow);
  };

  const handleImport = async (noderedFlow: FlowDto) => {
    let remoteFlow: RemoteFlow = { flowId: noderedFlow.id, flowName: noderedFlow.name, endpoint: noderedFlow.endpoint };
    const response: RemoteFlow = await postRemoteFlow({ remoteFlow: remoteFlow }).unwrap();
    if ('error' in response) {
      // console.log(newScenario.error);
      toast.error('Failed to create remote flow');
    } else {
      toast.success('Remote flow created successfully');
    }
  };

  const deleteRemoteFlowConfirmed = async () => {
    setConfirmDeleteRemoteFlowPopupOpen(false);

    const response: any = await deleteRemoteFlow({ flowId: selectedRemoteFlowToDelete?.id as string });

    if (response.error) {
      // console.log(response.error);
      toast.error('Failed to delete remoteFlow');
    } else {
      toast.success(`RemoteFlow deleted successfully`);
    }
  };

  return (
    <div className="bg-white shadow rounded mt-3">
      <ul className="divide-y divide-gray-200 ">
        {!!noderedFlows?.length ? (
          Object.values(noderedFlows).map((noderedFlow: FlowDto | undefined) => {
            const remoteFlowIsPresent = remoteFlows?.some((r) => r.flowId === noderedFlow?.id);
            if (!noderedFlow) {
              return null;
            }
            return (
              <li key={noderedFlow.id} className={''}>
                <div className={classNames(remoteFlowIsPresent ? '' : '', 'grid grid-cols-12')}>
                  <div className={classNames('block col-span-10 p-4')}>
                    <div className="flex items-center">
                      <p className="flex-shrink truncate text-sm font-medium text-blue-600">{noderedFlow.name}</p>
                    </div>
                    <div className="mt-2 sm:flex sm:justify-between">
                      <div className="sm:flex">
                        <p className="flex items-center text-sm text-gray-500">{noderedFlow.description}</p>
                      </div>
                    </div>
                  </div>
                  {!remoteFlowIsPresent && (
                    <div
                      onClick={() => handleImport(noderedFlow)}
                      className="flex col-span-1 items-center hover:bg-blue-100"
                    >
                      <HiOutlinePlusCircle className="h-6 w-6 m-auto text-blue-600"></HiOutlinePlusCircle>
                    </div>
                  )}
                  {remoteFlowIsPresent && (
                    <Button
                      variant="inverse"
                      className="flex col-span-1 items-center hover:bg-red-100 border-0 text-red-500 rounded-none"
                      onClick={() => handleDelete(noderedFlow)}
                    >
                      <HiOutlineTrash className="h-6 w-6" />
                    </Button>
                  )}
                </div>
              </li>
            );
          })
        ) : (
          <div className="text-center">
            <HiFolderOpen className={'mx-auto mt-4 h-12 w-12 text-gray-400'} aria-hidden="true" />
            <h3 className="my-2 text-sm font-semibold text-gray-900">No Remote Flows</h3>
          </div>
        )}
      </ul>

      {isConfirmDeleteRemoteFlowPopupOpen && (
        <ConfirmationPopup
          onConfirm={deleteRemoteFlowConfirmed}
          onCancel={() => setConfirmDeleteRemoteFlowPopupOpen(false)}
        >
          <h2 className="text-xl text-gray-700 mb-4 mt-1 px-4">
            Are you sure you want to delete the "{selectedRemoteFlowToDelete?.name}" remoteFlow?
          </h2>
        </ConfirmationPopup>
      )}
    </div>
  );
}

export default ListRemoteFlow;
