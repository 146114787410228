import React, { useMemo } from 'react';
import { Select } from '../../../../components/Input';
import { useFormContext } from 'react-hook-form';
import { DisplayConfiguration } from '../../../../api/scenarioTemplate/scenarioTemplateApi';
import classNames from '../../../../utils/classNames';

type DisplayManagementProps = {
  parameterPrefix: string;
  displayConfigurations: DisplayConfiguration[];
};

function DisplayManagement(props: DisplayManagementProps) {
  const { parameterPrefix, displayConfigurations } = props;

  const { control, watch, setValue } = useFormContext();

  const phaseId = watch(`${parameterPrefix}.phaseId`);
  const tab = watch(`${parameterPrefix}.tab`);
  const groups = watch(`groups`);
  const parameters = watch(`parameters`);

  const availableTabs = useMemo(() => {
    return displayConfigurations?.find((pc) => pc.phaseId === phaseId)?.tabs ?? [];
  }, [phaseId, displayConfigurations]);

  return (
    <>
      <div className="grid grid-cols-2 gap-2 rounded-b mt-4">
        <Select
          control={control}
          label={'Display phase'}
          className={classNames(phaseId ? 'border-green-400 ' : 'border-red-400', 'col-span-2 border-2 rounded')}
          name={`${parameterPrefix}.phaseId`}
          options={displayConfigurations?.map((pc) => ({
            label: pc.phaseName as string,
            value: pc.phaseId as string,
          }))}
        />
        {
          <Select
            control={control}
            label={'Display tab'}
            className={classNames(tab ? 'border-green-400 ' : 'border-red-400', 'col-span-2 flex-1 border-2 rounded')}
            name={`${parameterPrefix}.tab`}
            options={
              availableTabs.map((s) => ({
                label: s.name,
                value: s.name,
              })) as []
            }
          />
        }
        <Select
          control={control}
          label={'Display Group'}
          className="flex-1"
          name={`${parameterPrefix}.group`}
          options={[
            ...[...(groups ?? [])].map((g) => ({
              label: g.name,
              value: g.name,
            })),
            { label: 'No group', value: null },
          ]}
        />

        <Select
          control={control}
          label={'Belongs to formula parameter'}
          className="flex-1"
          name={`${parameterPrefix}.formulaParameter`}
          onChange={(e) => {
            const parameter = parameters.find((p) => p.parameterName === e);
            setValue(`${parameterPrefix}.tab`, parameter.display.tab);
            setValue(`${parameterPrefix}.phaseId`, parameter.display.phaseId);
          }}
          options={[
            ...parameters?.map((s) => ({
              label: s.parameterName,
              value: s.parameterName,
            })),
            { label: 'Not a formula parameter', value: null },
          ]}
        />
      </div>
    </>
  );
}

export default DisplayManagement;
