import React from 'react';
import './style.css';

type TooltipProps = {
  content: string | JSX.Element | JSX.Element[] | (() => JSX.Element);
  children: string | JSX.Element | JSX.Element[] | (() => JSX.Element);
  className?: string;
  dx?: number;
  dy?: number;
  textProps?: string;
  onClick?: () => void;
};

export default function Tooltip(props: TooltipProps) {
  const { content, children, className, onClick, dx, dy, textProps } = props;

  return (
    <span onClick={onClick ? onClick : undefined} className={`tooltip ${className ? className : ''}`}>
      <>{children}</>
      <span className={`top ${textProps ? textProps : 'whitespace-wrap'}`}>
        <>{content}</>
      </span>
    </span>
  );
}

export const TooltipItem = ({ children, title, position }) => {
  // const positionClass = useMemo(() => {
  //   switch (position) {
  //     case "top":
  //       return "top-full -translate-x-1/2"
  //     case "left":
  //       return "left-full -translate-y-1/2"
  //     case "bottom":
  //       return "bottom-full -translate-x-1/2"
  //     case "right":
  //       return "right-full -translate-y-1/2"
  //     default:
  //       return "top-full -translate-x-1/2"
  //   }
  // }, [position])className={classNames(positionClass, "border-light text-body-color absolute top-1/2 z-20 ml-3  whitespace-nowrap rounded border bg-black py-[6px] px-4 text-sm font-semibold opacity-0 group-hover:opacity-100")}

  return (
    <div className="group relative">
      <div className="bg-primary rounded text-base text-white group-hover:opacity-100 cursor-pointer">{children}</div>
      <div
        className={` ${
          (position === 'right' &&
            `border-light text-body-color absolute left-full top-1/2 z-20 ml-0 -translate-y-1/2 whitespace-nowrap rounded border bg-blue-100 py-[6px] px-4 text-sm opacity-0 group-hover:opacity-100`) ||
          (position === 'top' &&
            `border-light text-body-color absolute bottom-full left-1/2 z-20 mb-0 -translate-x-1/2 whitespace-nowrap rounded border bg-blue-100 py-[6px] px-4 text-sm opacity-0 group-hover:opacity-100`) ||
          (position === 'left' &&
            `border-light text-body-color absolute right-full top-1/2 z-20 mr-0 -translate-y-1/2 whitespace-nowrap rounded border bg-blue-100 py-[6px] px-4 text-sm opacity-0 group-hover:opacity-100`) ||
          (position === 'bottom' &&
            `border-light text-body-color absolute top-full left-1/2 z-20 mt-0 -translate-x-1/2 whitespace-nowrap rounded border bg-blue-100 py-[6px] px-4 text-sm opacity-0 group-hover:opacity-100`)
        }`}
      >
        <span
          className={` ${
            (position === 'right' &&
              `border-light absolute -left-1 top-1/2 -z-10 h-2 w-2 -translate-y-1/2 rotate-45 rounded-r border-b border-l bg-white`) ||
            (position === 'top' &&
              `border-light absolute -bottom-1 left-1/2 -z-10 h-2 w-2 -translate-x-1/2 rotate-45 rounded-l border-b border-r bg-white`) ||
            (position === 'left' &&
              `border-light absolute -right-1 top-1/2 -z-10 h-2 w-2 -translate-y-1/2 rotate-45 rounded border-t border-r bg-white`) ||
            (position === 'bottom' &&
              `border-light absolute -top-1 left-1/2 -z-10 h-2 w-2 -translate-x-1/2 rotate-45 rounded border-t border-l bg-white`)
          } `}
        ></span>
        {title}
      </div>
    </div>
  );
};

//ml-${dx ? dx : 0} mt-${dy ? dy : 0}
