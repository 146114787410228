import React, { useEffect } from 'react';
import { Button, Link } from '../../../components/Elements';
import { useBreadcrumbs } from '../../../lib/breadcrumbs';
import { HiPlusCircle } from 'react-icons/hi2';
import ListIntegration from '../components/ListIntegration';

export const Integrations = () => {
  const { setBreadcrumbs } = useBreadcrumbs();

  useEffect(() => {
    setBreadcrumbs([{ label: 'integrations' }]);
  }, []);

  return (
    <div className="flex flex-col">
      <div className="mt-3">
        <Link to={`/app/integrations/new`}>
          <Button
            className="float-right ml-3 text-blue-500 border-2 bg-white border-blue-500 hover:bg-blue-600 hover:text-white"
            style={{ height: '46px' }}
            variant="none"
            size={'md'}
          >
            <HiPlusCircle className="mr-2 h-6 w-6" /> Create New
          </Button>
        </Link>
      </div>
      <ListIntegration />
    </div>
  );
};
