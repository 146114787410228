import React from 'react';
import classNames from '../../utils/classNames';
import useRenderCounter from '../../hooks/useRenderCounter';

export type InputProps = {
  addonBefore?: any;
  addonAfter?: any;
  error?: string;
  value?: string | number | undefined;
  placeHolder?: string;
  onChange?: (value: any) => void;
  label?: string;
  disabled?: boolean;
  description?: string;
  id?: string;
  name?: string;
  className?: string;
  hidden?: boolean;
  min?: number | string | undefined;
  step?: number;
};

export const InputNumber = React.forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const {
    addonBefore,
    addonAfter,
    value,
    name,
    placeHolder,
    onChange,
    label,
    error,
    description,
    id,
    disabled,
    hidden,
    className,
    min,
    step,
  } = props;

  const renderCounter = useRenderCounter();

  return (
    <div className={classNames(className ? className : '', hidden ? 'hidden' : '', 'relative')}>
      {renderCounter}
      <label className="whitespace-nowrap bg-gradient-to-t from-[#F8FAFC] from-50%  via-transparent via-55% to-transparent absolute -top-[8px] z-2 left-4 px-1 text-xs text-gray-700 rounded">
        {label}
      </label>
      <div
        className={`flex rounded border-2 focus-visible:outline-none focus:border-blue-500 ${
          error ? 'border-red-500' : ''
        }`}
      >
        {addonBefore && (
          <span className="inline-flex items-center rounded-l border-gray-300 bg-gray-50 px-3 text-sm text-gray-500">
            {addonBefore}
          </span>
        )}
        <input
          type={'number'}
          {...(min !== null ? { min } : {})}
          {...(step !== null ? { step } : {})}
          disabled={disabled}
          name={name}
          ref={ref}
          style={{ backgroundColor: '#F8FAFC' }}
          id={id}
          className={classNames(
            disabled ? 'p-2 border-gray-200 text-gray-500 bg-gray-50' : '',
            'pt-3 p-2 w-0 w-full flex-1 focus-visible:outline-none focus:ring-1 ring-inset focus:ring-blue-500 rounded leading-5',
          )}
          placeholder={placeHolder}
          onChange={onChange}
          value={value}
        />
        {addonAfter && (
          <span className="inline-flex items-center rounded-r border-gray-300 bg-gray-50 px-3 text-sm text-gray-500">
            {addonAfter}
          </span>
        )}
      </div>

      {error && <p className="text-sm text-red-500">{error}</p>}

      {description && <p className="ml-2 mt-1 text-xs text-gray-500">{description}</p>}
    </div>
  );
});
