import { HiArrowDown, HiArrowUp } from 'react-icons/hi2';

type ArrayFieldPositionMoverProps = {
  fields: any[];
  index: number;
  move: (start: number, end: number) => void;
};

function ArrayFieldPositionMover(props: ArrayFieldPositionMoverProps) {
  const { index, fields, move } = props;

  return (
    <div>
      {fields.length > 1 ? (
        <div className="bg-gray-200 rounded px-2 mx-1 text-xs  text-center items-center">
          <HiArrowUp
            className="h-3 w-4 cursor-pointer"
            onClick={() => move(index, index === 0 ? fields.length - 1 : index - 1)}
          />
          {index}
          <HiArrowDown
            className="h-3 w-4 cursor-pointer"
            onClick={() => move(index, index === fields.length - 1 ? 0 : index + 1)}
          />
        </div>
      ) : null}
    </div>
  );
}

export default ArrayFieldPositionMover;
