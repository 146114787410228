import React from 'react';
import classNames from '../../utils/classNames';
import useRenderCounter from '../../hooks/useRenderCounter';

export type InputProps = {
  className?: string;
  addonBefore?: any;
  addonAfter?: any;
  error?: string | undefined | null;
  value?: string | number | undefined;
  placeHolder?: string;
  onChange?: (value: any) => void;
  label?: string;
  disabled?: boolean;
  description?: string;
  id?: string;
  name?: string;
  hidden?: boolean;
  cursor?: string;
  type?: string;
};

export const InputText = React.forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const {
    type,
    className,
    addonBefore,
    addonAfter,
    value,
    name,
    placeHolder,
    onChange,
    label,
    error,
    description,
    id,
    disabled,
    hidden,
    cursor,
  } = props;

  const renderCounter = useRenderCounter();

  return (
    <div className={classNames(className ? className : '', hidden ? 'hidden' : '', 'relative')}>
      {renderCounter}
      <label className="z-10 whitespace-nowrap bg-gradient-to-t from-[#F8FAFC] from-50% via-transparent via-55% to-transparent absolute -top-[8px] z-[2] left-4 px-1 text-xs text-gray-700 rounded">
        {label}
      </label>
      <div
        className={classNames(
          error ? 'border-red-500' : '',
          'flex rounded border border-gray-300 focus:border-blue-500',
        )}
      >
        {addonBefore && (
          <span className="inline-flex items-center rounded-l border-gray-300 bg-gray-50 px-3 text-sm text-gray-500">
            {addonBefore}
          </span>
        )}
        <input
          type={type ? type : 'text'}
          disabled={disabled}
          name={name}
          ref={ref}
          id={id}
          style={{ backgroundColor: '#F8FAFC' }}
          className={classNames(
            disabled ? ' text-gray-400 px-3 bg-gray-50' : '',
            cursor ? cursor : '',
            'block pt-4 p-2 w-full flex-1 rounded leading-5 focus-visible:outline-none focus:ring-1 ring-inset focus:ring-blue-500 ',
            addonAfter ? 'rounded-r-none border-r-none' : '',
            addonBefore ? 'rounded-l-none border-l-none' : '',
          )}
          placeholder={placeHolder}
          onChange={onChange}
          value={value}
        />
        {addonAfter && (
          <span className="inline-flex items-center rounded-r bg-gray-50 px-3 text-sm text-gray-500">{addonAfter}</span>
        )}
      </div>

      {error && <p className="text-sm text-red-500">{error}</p>}

      {description && <p className="ml-2 mt-1 text-xs text-gray-500">{description}</p>}
    </div>
  );
});
