import { emptyLightweightOlcaCoreApi as api } from './emptyApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    postModeledProcess: build.mutation<PostModeledProcessApiResponse, PostModeledProcessApiArg>({
      query: (queryArg) => ({
        url: `/v1/modeled-process/import`,
        method: 'POST',
        body: queryArg.body,
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'] },
      }),
    }),
    postModeledProcessList: build.mutation<PostModeledProcessListApiResponse, PostModeledProcessListApiArg>({
      query: (queryArg) => ({
        url: `/v1/modeled-process/import/list`,
        method: 'POST',
        body: queryArg.body,
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'] },
      }),
    }),
    postAlternativeFlow: build.mutation<PostAlternativeFlowApiResponse, PostAlternativeFlowApiArg>({
      query: (queryArg) => ({
        url: `/v1/modeled-process/alternative-flow`,
        method: 'POST',
        body: queryArg.body,
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'] },
      }),
    }),
    postImpactMethod: build.mutation<PostImpactMethodApiResponse, PostImpactMethodApiArg>({
      query: (queryArg) => ({
        url: `/v1/impact-method/import`,
        method: 'POST',
        body: queryArg.body,
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'] },
      }),
    }),
    postImpactMethodList: build.mutation<PostImpactMethodListApiResponse, PostImpactMethodListApiArg>({
      query: (queryArg) => ({
        url: `/v1/impact-method/import/list`,
        method: 'POST',
        body: queryArg.body,
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'] },
      }),
    }),
    customizeImpactMethod: build.mutation<CustomizeImpactMethodApiResponse, CustomizeImpactMethodApiArg>({
      query: (queryArg) => ({
        url: `/v1/impact-method/customize`,
        method: 'POST',
        body: queryArg.impactMethod,
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'] },
      }),
    }),
    postCategoryGroup: build.mutation<PostCategoryGroupApiResponse, PostCategoryGroupApiArg>({
      query: (queryArg) => ({
        url: `/v1/impact-method/categoryGroup`,
        method: 'POST',
        body: queryArg.categoryGroup,
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'] },
      }),
    }),
    updateImpactMethod: build.mutation<UpdateImpactMethodApiResponse, UpdateImpactMethodApiArg>({
      query: (queryArg) => ({
        url: `/v1/impact-method/customization-update`,
        method: 'PATCH',
        body: queryArg.impactMethod,
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'] },
      }),
    }),
    getTree: build.query<GetTreeApiResponse, GetTreeApiArg>({
      query: (queryArg) => ({
        url: `/v1/tree/${queryArg.treeId}`,
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'] },
      }),
    }),
    deleteTree: build.mutation<DeleteTreeApiResponse, DeleteTreeApiArg>({
      query: (queryArg) => ({
        url: `/v1/tree/${queryArg.treeId}`,
        method: 'DELETE',
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'] },
      }),
    }),
    getParametersByTree: build.query<GetParametersByTreeApiResponse, GetParametersByTreeApiArg>({
      query: (queryArg) => ({
        url: `/v1/tree/${queryArg.treeId}/parameters`,
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'] },
      }),
    }),
    getTreeInfo: build.query<GetTreeInfoApiResponse, GetTreeInfoApiArg>({
      query: (queryArg) => ({
        url: `/v1/tree/${queryArg.treeId}/info`,
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'] },
      }),
    }),
    getParametersForTrees: build.query<GetParametersForTreesApiResponse, GetParametersForTreesApiArg>({
      query: (queryArg) => ({
        url: `/v1/tree/parameters`,
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'] },
        params: { treeIds: queryArg.treeIds },
      }),
    }),
    getAllTreesInfo: build.query<GetAllTreesInfoApiResponse, GetAllTreesInfoApiArg>({
      query: (queryArg) => ({
        url: `/v1/tree/`,
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'] },
        params: { includeSubprocesses: queryArg.includeSubprocesses },
      }),
    }),
    getImpactMethodById: build.query<GetImpactMethodByIdApiResponse, GetImpactMethodByIdApiArg>({
      query: (queryArg) => ({
        url: `/v1/impact-method/${queryArg.impactMethodId}`,
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'] },
      }),
    }),
    deleteImpactMethod: build.mutation<DeleteImpactMethodApiResponse, DeleteImpactMethodApiArg>({
      query: (queryArg) => ({
        url: `/v1/impact-method/${queryArg.impactMethodId}`,
        method: 'DELETE',
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'] },
      }),
    }),
    getAllImpactMethodByRefId: build.query<GetAllImpactMethodByRefIdApiResponse, GetAllImpactMethodByRefIdApiArg>({
      query: (queryArg) => ({
        url: `/v1/impact-method/refId/${queryArg.impactMethodRefId}`,
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'] },
      }),
    }),
    getAllImpactMethodsWhereDefault: build.query<
      GetAllImpactMethodsWhereDefaultApiResponse,
      GetAllImpactMethodsWhereDefaultApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/impact-method/default`,
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'] },
        params: { defaultMethod: queryArg.defaultMethod },
      }),
    }),
    getAllImpactMethods: build.query<GetAllImpactMethodsApiResponse, GetAllImpactMethodsApiArg>({
      query: (queryArg) => ({
        url: `/v1/impact-method/`,
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'] },
        params: { impactMethodName: queryArg.impactMethodName },
      }),
    }),
    deleteAllTrees: build.mutation<DeleteAllTreesApiResponse, DeleteAllTreesApiArg>({
      query: (queryArg) => ({
        url: `/v1/tree/deleteAllTrees`,
        method: 'DELETE',
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'] },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as lightweightOlcaCoreApi };
export type PostModeledProcessApiResponse = /** status 200 OK */ {
  [key: string]: string;
};
export type PostModeledProcessApiArg = {
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
  body: {
    modeledProcessZip?: Blob;
  };
};
export type PostModeledProcessListApiResponse = /** status 200 OK */ {
  [key: string]: string;
};
export type PostModeledProcessListApiArg = {
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
  body: {
    modeledProcessZips?: Blob[];
  };
};
export type PostAlternativeFlowApiResponse = /** status 200 OK */ {
  [key: string]: string;
};
export type PostAlternativeFlowApiArg = {
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
  body: {
    alternativeFlowZip?: Blob;
  };
};
export type PostImpactMethodApiResponse = /** status 200 OK */ ImpactMethod;
export type PostImpactMethodApiArg = {
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
  body: {
    impactMethodFile?: Blob;
  };
};
export type PostImpactMethodListApiResponse = /** status 200 OK */ ImpactMethod[];
export type PostImpactMethodListApiArg = {
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
  body: {
    impactMethodFiles?: Blob[];
  };
};
export type CustomizeImpactMethodApiResponse = /** status 200 OK */ ImpactMethod;
export type CustomizeImpactMethodApiArg = {
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
  impactMethod: ImpactMethod;
};
export type PostCategoryGroupApiResponse = /** status 200 OK */ CategoryGroup;
export type PostCategoryGroupApiArg = {
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
  categoryGroup: CategoryGroup;
};
export type UpdateImpactMethodApiResponse = /** status 200 OK */ ImpactMethod;
export type UpdateImpactMethodApiArg = {
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
  impactMethod: ImpactMethod;
};
export type GetTreeApiResponse = /** status 200 OK */ NodeProcess;
export type GetTreeApiArg = {
  treeId: string;
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
};
export type DeleteTreeApiResponse = /** status 200 OK */ {
  [key: string]: string;
};
export type DeleteTreeApiArg = {
  treeId: string;
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
};
export type GetParametersByTreeApiResponse = /** status 200 OK */ ProcessParameter[];
export type GetParametersByTreeApiArg = {
  treeId: string;
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
};
export type GetTreeInfoApiResponse = /** status 200 OK */ TreeInfoDto;
export type GetTreeInfoApiArg = {
  treeId: string;
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
};
export type GetParametersForTreesApiResponse = /** status 200 OK */ {
  [key: string]: ProcessParameter[];
};
export type GetParametersForTreesApiArg = {
  treeIds?: string[];
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
};
export type GetAllTreesInfoApiResponse = /** status 200 OK */ TreeInfoDto[];
export type GetAllTreesInfoApiArg = {
  includeSubprocesses?: boolean;
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
};
export type GetImpactMethodByIdApiResponse = /** status 200 OK */ ImpactMethod;
export type GetImpactMethodByIdApiArg = {
  impactMethodId: string;
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
};
export type DeleteImpactMethodApiResponse = /** status 200 OK */ GenericResponse;
export type DeleteImpactMethodApiArg = {
  impactMethodId: string;
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
};
export type GetAllImpactMethodByRefIdApiResponse = /** status 200 OK */ ImpactMethod[];
export type GetAllImpactMethodByRefIdApiArg = {
  impactMethodRefId: string;
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
};
export type GetAllImpactMethodsWhereDefaultApiResponse = /** status 200 OK */ ImpactMethod[];
export type GetAllImpactMethodsWhereDefaultApiArg = {
  defaultMethod?: boolean;
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
};
export type GetAllImpactMethodsApiResponse = /** status 200 OK */ ImpactMethod[];
export type GetAllImpactMethodsApiArg = {
  impactMethodName?: string;
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
};
export type DeleteAllTreesApiResponse = /** status 200 OK */ {
  [key: string]: string;
};
export type DeleteAllTreesApiArg = {
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
};
export type CategoryGroup = {
  id?: string;
  name?: string;
  impactMethod?: ImpactMethod;
};
export type ImpactCategory = {
  id?: string;
  refId?: string;
  name?: string;
  visible?: boolean;
  alias?: string;
  categoryGroups?: CategoryGroup[];
  expand?: boolean;
  exponential?: boolean;
  impactMethod?: ImpactMethod;
};
export type ImpactMethod = {
  created?: string;
  lastUpdated?: string;
  createdBy?: string;
  realm?: string;
  id?: string;
  refId?: string;
  name?: string;
  defaultMethod?: boolean;
  impactCategories?: ImpactCategory[];
  categoryGroups?: CategoryGroup[];
};
export type Process = {
  name?: string;
  uuid?: string;
  refId?: string;
  amount?: number;
  unit?: string;
  exchangeAmount?: number;
  exchangeAmountFormula?: string;
  conversionFactor?: number;
  scopedParametersMap?: {
    [key: string]: string;
  };
  unitaryImpacts?: number[];
  inputExchange?: boolean;
  waste?: boolean;
  avoidedProduct?: boolean;
  firstChild?: boolean;
};
export type NodeProcess = {
  data?: Process;
  children?: NodeProcess[];
};
export type ProcessParameter = {
  refId?: string;
  name?: string;
  value?: number;
};
export type ModeledProcessInfoDto = {
  id?: string;
  name?: string;
};
export type TreeInfoDto = {
  id?: string;
  name?: string;
  description?: string;
  processRefId?: string;
  subProcesses?: ModeledProcessInfoDto[];
  impactMethodRefIds?: string[];
};
export type GenericResponse = {
  message?: string;
  details?: string;
  data?: object;
};
export const {
  usePostModeledProcessMutation,
  usePostModeledProcessListMutation,
  usePostAlternativeFlowMutation,
  usePostImpactMethodMutation,
  usePostImpactMethodListMutation,
  useCustomizeImpactMethodMutation,
  usePostCategoryGroupMutation,
  useUpdateImpactMethodMutation,
  useGetTreeQuery,
  useDeleteTreeMutation,
  useGetParametersByTreeQuery,
  useGetTreeInfoQuery,
  useGetParametersForTreesQuery,
  useGetAllTreesInfoQuery,
  useGetImpactMethodByIdQuery,
  useDeleteImpactMethodMutation,
  useGetAllImpactMethodByRefIdQuery,
  useGetAllImpactMethodsWhereDefaultQuery,
  useGetAllImpactMethodsQuery,
  useDeleteAllTreesMutation,
} = injectedRtkApi;
