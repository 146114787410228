import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CustomizationSpace, Parameter } from '../../../api/customizationSpace/customizationSpaceApi';
import {
  CustomizedBasicScenario,
  useGetCustomizedProcessQuery,
  usePostCustomizedBasicScenarioMutation,
  useUpsertCustomizedProcessMutation,
} from '../../../api/customizedScenario/customizedScenarioApi';
import { ScenarioDto, usePostScenarioMutation } from '../../../api/project/projectApi';
import { BasicScenarioTemplate, DisplayConfiguration } from '../../../api/scenarioTemplate/scenarioTemplateApi';
import '../style/style.css';
import { createBasicCustomizationPayload } from '../utils/createBasicCustomizationPayload';
import CustomizationTemplateForm from './CustomizationTemplateForm';
import toast from 'react-hot-toast/headless';
import { useCalculateBasicCustomizedScenarioWithPayloadMutation } from '../../../api/lightweightCalculationEngine/lightweightCalculationEngineApi';
import { HiOutlineArrowDownOnSquare } from 'react-icons/hi2';
import { MenuItem } from './ActionMenu';
import CustomizationNavBar from './CustomizationNavBar';
import useRenderCounter from '../../../hooks/useRenderCounter';
import { useFormContext } from 'react-hook-form';
import { skipToken } from '@reduxjs/toolkit/query';
import { HiOutlineChartPie, HiOutlineSaveAs } from 'react-icons/hi';
import Modal from '../../../components/Modal/Modal';
import { InputText } from '../../../components/Input';
import { debounce } from 'lodash';

export type PhaseStatusDTO = {
  phaseId: string;
  status: any;
  customizationSpace?: CustomizationSpace[];
};

export type BasicCustomizationProps = {
  scenario: ScenarioDto;
  scenarioTemplate: BasicScenarioTemplate;
  triggerAssessment?: boolean;
  onAutoFetch?: (assessment: any, parameters: Parameter[]) => any;
};

export default function BasicCustomization(props: BasicCustomizationProps) {
  const { scenario, scenarioTemplate, triggerAssessment, onAutoFetch } = props;

  const navigate = useNavigate();
  const renderCounter = useRenderCounter();
  const { formState, getValues, unregister, reset, trigger } = useFormContext();

  const [phaseId, setPhaseId] = useState<string>();
  const [openSaveAs, setOpenSaveAs] = useState<boolean>(false);
  const [saveAsName, setSaveAsName] = useState<string>();
  const [initted, setInitted] = useState<boolean>(false);

  const [upsertCustomizedProcess] = useUpsertCustomizedProcessMutation();
  const [postCustomizedBasicScenario] = usePostCustomizedBasicScenarioMutation();
  const [calculateBasicCustomizedScenarioWithPayload] = useCalculateBasicCustomizedScenarioWithPayloadMutation();
  const { data: customizedProcess, isLoading } = useGetCustomizedProcessQuery(
    scenario.id ? { scenarioId: scenario.id as string } : skipToken,
  );

  const [postScenario] = usePostScenarioMutation();

  useEffect(() => {
    if (triggerAssessment) {
      goToAssessment();
    }
  }, [triggerAssessment]);

  useEffect(() => {
    if (!isLoading) {
      let initialState: any;
      if (customizedProcess && customizedProcess?.refId === scenario.id) {
        if (customizedProcess?.customization?.parameters.filter((p) => !p || !p.parameterName).length > 0) {
          toast.error('There was a bug in customization, save to fix it');
          initialState = {
            parameters: scenarioTemplate?.parameters?.map((p, index) => ({
              ...p,
              index,
              value: customizedProcess?.customization?.parameters[index]?.value,
            })),
          };
        } else {
          initialState = customizedProcess?.customization;
        }
      } else {
        initialState = {
          parameters: scenarioTemplate?.parameters?.map((p, index) => ({
            ...p,
            index,
          })),
        };
      }
      reset(initialState);
      setInitted(true);
      // }
    }
  }, [customizedProcess]);

  useEffect(() => {
    if (initted) {
      handleFormChange();
      setInitted(false);
    }
  }, [initted]);

  const handleFormChange = useCallback(
    debounce(async () => {
      const valid = await trigger();
      if (onAutoFetch && valid) {
        onAutoFetch(executeAssessment(true), getValues().parameters);

        // console.log('handleFormChange - executeAssessment');
      }
    }, 150),
    [],
  );

  const goToAssessment = async () => {
    await handleSave();

    if (!Object.keys(formState.errors).length > 0) {
      navigate(`/app/assessments/${scenario?.id}`);
    } else {
      toast.error('Unable to execute!');
    }
  };

  const createPayload = () => {
    if (!Object.keys(formState.errors).length > 0) {
      const parameters: Parameter[] = createBasicCustomizationPayload(
        getValues().parameters,
        scenarioTemplate.variableConfigurations,
      );
      return {
        parameters,
        assessmentConfigurations: scenarioTemplate.assessmentConfigurations,
        variableConfigurations: scenarioTemplate.variableConfigurations,
      };
    }
    return null;
  };

  const executeAssessment = async (shallow: boolean) => {
    const payload = createPayload();
    if (payload) {
      const calculation = await calculateBasicCustomizedScenarioWithPayload({
        customizedBasicScenarioDto: payload,
        shallow,
      });
      if ('error' in calculation) {
        throw new Error('Failed to execute assessment: ' + calculation.error);
      }
      // If there is no error, we can safely assume that the data property exists
      return calculation.data;
    }
  };

  const handleSave = async () => {
    await upsertCustomizedProcess({
      customizedProcess: {
        refId: scenario?.id as string,
        customization: getValues(),
      },
    });
    const payload: CustomizedBasicScenario | null = createPayload();
    toast.success('Scenario successfully saved!');
    if (payload) {
      await postCustomizedBasicScenario({
        customizedBasicScenario: {
          scenarioId: scenario?.id,
          ...payload,
        },
      });
    }
  };

  const handleSaveAs = async () => {
    const newScenario: ScenarioDto = await postScenario({
      scenarioDto: {
        name: saveAsName,
        projectId: scenario.projectId,
        scenarioTemplateId: scenario.scenarioTemplateId,
        description: scenario.description,
      },
    }).unwrap();
    upsertCustomizedProcess({
      customizedProcess: {
        refId: newScenario?.id as string,
        customization: getValues(),
      },
    });

    if ('error' in newScenario) {
      toast.error('Failed to create new scenario');
    } else {
      toast.success('Scenario created successfully');
    }

    setSaveAsName(undefined);
    setOpenSaveAs(false);
  };

  const items: MenuItem[] = [
    {
      name: 'Execute LCA assessment',
      description: 'Compute the LCA assessment',
      onClick: goToAssessment,
      Icon: HiOutlineChartPie,
    },
    {
      name: 'Save customization',
      description: 'Save the current customization',
      onClick: handleSave,
      Icon: HiOutlineArrowDownOnSquare,
    },
    {
      name: 'Save as',
      description: 'Save as a new scenario',
      onClick: () => setOpenSaveAs(true),
      Icon: HiOutlineSaveAs,
    },
  ];

  return (
    <>
      {renderCounter}
      {isLoading && <div className={'h-full w-full absolute block z-50 cursor-wait'}></div>}
      <CustomizationNavBar scenario={scenario} items={items} className="p-1 flex-shrink" />

      <CustomizationTemplateForm
        className="my-3 rounded flex-shrink max-h-full"
        parameters={scenarioTemplate.parameters?.map((p, index) => ({
          ...p,
          index,
        }))}
        displayConfigurations={scenarioTemplate.displayConfigurations as DisplayConfiguration[]}
        scenarioId={scenario.id as string}
        currentPhaseId={phaseId as string}
        changePhase={setPhaseId}
        onFormChange={handleFormChange}
      />

      <Modal open={openSaveAs} handleOpen={setOpenSaveAs} confirm={saveAsName ? handleSaveAs : undefined}>
        <InputText value={saveAsName} onChange={(e) => setSaveAsName(e.target.value)} label={'New scenario name'} />
      </Modal>
    </>
  );
}
