import React from 'react';
import BillOfMaterial from './BillOfMaterial';
import { Bom, useGetBomQuery } from '../../../api/bom/bomApi';
import { useParams } from 'react-router-dom';

function BomComponent() {
  const { bomId } = useParams();
  const { data: bom } = useGetBomQuery({ bomId: bomId as string });

  return (
    <BillOfMaterial
      bom={bom as Bom}
      nodeConfiguration={{
        nodeContent: (item) => (
          <>
            {item.attributes?.map((a) => (
              <p>
                {a.name}: {a.value}
              </p>
            ))}
          </>
        ),
      }}
    />
  );
}

export default BomComponent;
