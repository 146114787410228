import { useEffect, useState } from 'react';
import { Button, Link } from '../../../components/Elements';
import { useBreadcrumbs } from '../../../lib/breadcrumbs';
import ListImpactMethods from '../components/ListImpactMethods';
import { InputText } from '../../../components/Input';
import { HiMagnifyingGlass, HiPlusCircle } from 'react-icons/hi2';

export const ImpactMethods = () => {
  const [searchImpactMethodName, setSearchImpactMethodName] = useState<string>();
  const { setBreadcrumbs } = useBreadcrumbs();

  useEffect(() => {
    setBreadcrumbs([{ label: 'impact methods' }]);
  }, []);

  return (
    <div className="flex flex-col">
      <div className="flex flex-row ml-auto my-3">
        <InputText
          addonBefore={<HiMagnifyingGlass className="w-6 h-6 text-black" />}
          defaultValue={searchImpactMethodName}
          value={searchImpactMethodName}
          onChange={(e) => setSearchImpactMethodName(e.target.value)}
        />
        <Link to="./new">
          <Button
            className="float-right ml-3 text-blue-500 border-2 bg-white border-blue-500 hover:bg-blue-600 hover:text-white"
            style={{ height: '46px' }}
            variant="none"
            size={'md'}
          >
            <HiPlusCircle className="mr-2 h-6 w-6" /> Create New
          </Button>
        </Link>
      </div>
      <ListImpactMethods filter={{ impactMethodName: searchImpactMethodName }} />
    </div>
  );
};
