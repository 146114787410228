import { emptyBomApi as api } from './emptyApi';

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    postPdx: build.mutation<PostPdxApiResponse, PostPdxApiArg>({
      query: (queryArg) => ({
        url: `/v1/bom/import`,
        method: 'POST',
        body: queryArg.body,
        headers: { 'x-realm': queryArg['x-realm'] },
      }),
    }),
    getBom: build.query<GetBomApiResponse, GetBomApiArg>({
      query: (queryArg) => ({
        url: `/v1/bom/${queryArg.bomId}`,
        headers: { 'x-realm': queryArg['x-realm'] },
      }),
    }),
    getAllBoms: build.query<GetAllBomsApiResponse, GetAllBomsApiArg>({
      query: (queryArg) => ({
        url: `/v1/bom/`,
        headers: { 'x-realm': queryArg['x-realm'] },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as bomApi };
export type PostPdxApiResponse = /** status 200 OK */ object;
export type PostPdxApiArg = {
  /** realm */
  'x-realm'?: string;
  body: {
    pdxFile?: Blob;
  };
};
export type GetBomApiResponse = /** status 200 OK */ Bom;
export type GetBomApiArg = {
  bomId: string;
  /** realm */
  'x-realm'?: string;
};
export type GetAllBomsApiResponse = /** status 200 OK */ Bom[];
export type GetAllBomsApiArg = {
  /** realm */
  'x-realm'?: string;
};
export type Attribute = {
  name?: string;
  value?: string;
};
export type Component = {
  attributes?: Attribute[];
  itemId?: string;
  name?: string;
  description?: string;
  quantity?: number;
};
export type Item = {
  id?: string;
  description?: string;
  attributes?: Attribute[];
  components?: Component[];
  root?: boolean;
};
export type Bom = {
  created?: string;
  lastUpdated?: string;
  createdBy?: string;
  realm?: string;
  id?: string;
  externalId?: string;
  creationDate?: string;
  creator?: string;
  description?: string;
  items?: Item[];
};
export const { usePostPdxMutation, useGetBomQuery, useGetAllBomsQuery } = injectedRtkApi;
