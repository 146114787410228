import React, { useEffect, useMemo, useState } from 'react';
import { distinct } from '../../../../utils/distinct';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Select } from '../../../../components/Input';
import { getInputError } from '../../../../components/Input/util/getInputError';
import { Button } from '../../../../components/Elements';
import { HiOutlineChevronDown, HiOutlineChevronUp, HiOutlineTrash, HiPlusCircle } from 'react-icons/hi2';
import { ImpactMethod, TreeInfoDto } from '../../../../api/lightweightOlcaCore/lightweightOlcaCoreApi';
import { PhaseDto } from '../../../../api/project/projectApi';
import classNames from '../../../../utils/classNames';

type AssessmentConfigurationProps = {
  className?: string;
  trees: TreeInfoDto[];
  impactMethods: ImpactMethod[];
  phases: PhaseDto[];
  prefix: string;
  onTreeChange: (selectedTreeIds: string[]) => void;
};

function AssessmentConfigurationForm(props: AssessmentConfigurationProps) {
  const { trees, className, impactMethods, phases, prefix, onTreeChange } = props;

  const [selectedTreeIds, setSelectedTreeIds] = useState<string[]>([]);
  const [expanded, setExpanded] = useState<boolean>(false);

  const {
    watch,
    control,
    register,
    setValue,
    formState: { errors },
  } = useFormContext();

  const {
    fields: phaseConfigurationsFields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: `${prefix}.phaseConfigurations`,
  });

  useEffect(() => {
    updateImpactMethods();
  }, []);

  const assessmentConfiguration: any = watch(prefix);
  const updateImpactMethods = () => {
    const selectedTreeIds: string[] = assessmentConfiguration.phaseConfigurations.map((c) => c.treeId).filter(distinct);
    setSelectedTreeIds(selectedTreeIds);
    onTreeChange(selectedTreeIds);
  };

  const availableMethods: ImpactMethod[] = useMemo(() => {
    //Need to calculate the intersection between all the impactMethodRefIds
    const selectedTrees = trees.filter((t) => selectedTreeIds.includes(t.id as string));
    let startTree = [...selectedTrees].pop();
    if (!startTree) return [];
    let methods = startTree.impactMethodRefIds ?? [];

    for (let tree of selectedTrees) {
      methods?.filter((m: string) => tree.impactMethodRefIds?.indexOf(m) !== -1);
    }
    return impactMethods.filter((im) => methods.includes(im.refId as string));
  }, [selectedTreeIds]);

  const filteredTrees = useMemo(() => {
    return trees.filter((t) =>
      availableMethods?.length > 0
        ? t.impactMethodRefIds?.some((imRefId) => availableMethods.map((im) => im.refId).includes(imRefId))
        : true,
    );
  }, [trees, availableMethods]);

  // console.log({ assessmentConfiguration });

  return (
    <div className={classNames(className ? className : '', 'p-2 flex flex-col')}>
      <Select
        control={control}
        name={`${prefix}.assessmentType`}
        options={['LCA', 'LCC', 'SLCA', 'CE'].map((type) => ({
          value: type,
          label: type,
        }))}
        placeholder="Choose option"
        label={'Assessment type'}
        error={getInputError(errors, 'assessmentType')}
      />
      {expanded ? (
        <>
          {
            <>
              <div className="mb-2 flex flex-col my-4">
                {phaseConfigurationsFields.map((field: any, index: number) => {
                  //Customizing treeID registering

                  return (
                    <div key={field.id} className="py-2 flex-1 flex gap-2 justify-around items-center">
                      <Select
                        control={control}
                        onChange={updateImpactMethods}
                        name={`${prefix}.phaseConfigurations.${index}.treeId`}
                        options={filteredTrees.map((m) => ({
                          value: m.id as string,
                          label: m.name as string,
                        }))}
                        label={'Modeled process'}
                        className="flex-1"
                      />
                      <Select
                        control={control}
                        name={`${prefix}.phaseConfigurations.${index}.phaseId`}
                        options={phases?.map((p) => ({
                          value: p.id as string,
                          label: p.name as string,
                        }))}
                        label={'Phase'}
                        className="flex-1"
                      />
                      <Button type="button" variant="none" onClick={() => remove(index)}>
                        <HiOutlineTrash className="h-6 w-6 text-red-500" />
                      </Button>
                    </div>
                  );
                })}
                <Button
                  variant="none"
                  type="button"
                  className="flex-1 text-blue-600 my-2 border rounded border-blue-600"
                  onClick={() => append({ treeId: '', phaseId: '' })}
                >
                  <HiPlusCircle className="w-6 h-6 mr-4" />
                  Add modeled phase
                </Button>
              </div>
            </>
          }

          {selectedTreeIds.length > 0 && (
            <div className="flex-1 my-4">
              <hr className="p-2" />
              <Select
                control={control}
                label={'Select the default impact method for this BASIC scenario template'}
                name={`${prefix}.impactMethodId`}
                options={availableMethods.map((im) => ({
                  label: im.name as string,
                  value: im.id as string,
                }))}
                onChange={(id: string) => {
                  setValue(`${prefix}.impactMethodRefId`, availableMethods.find((m) => m.id === id)?.refId as string);
                }}
              />
            </div>
          )}

          <HiOutlineChevronUp className="h-8 w-8 self-center" onClick={() => setExpanded(false)} />
        </>
      ) : (
        <HiOutlineChevronDown className="h-8 w-8 mt-2 self-center" onClick={() => setExpanded(true)} />
      )}
    </div>
  );
}

export default AssessmentConfigurationForm;
