import { BasicScenarioTemplate, BomScenarioTemplate } from '../../../api/scenarioTemplate/scenarioTemplateApi';

export const checkBomImportMapping = (importJson: string, scenarioTemplates: BasicScenarioTemplate[]) => {
  const mapping = [];
  const parseBomScenarioTemplate: BomScenarioTemplate = JSON.parse(importJson);

  console.log(parseBomScenarioTemplate);

  parseBomScenarioTemplate.itemScenarioTemplateConfiguration?.forEach((ist: any) => {
    if (!scenarioTemplates.some((st) => st.id === ist.scenarioTemplateId)) {
      mapping.push({
        type: 'SCENARIO_TEMPLATE',
        id: ist.scenarioTemplateId,
        name: ist.name,
      });
    }
  });

  return mapping;
};
