import { ParameterType } from '../../../api/customizationSpace/types/ParameterType';
import { InputNumber } from '../../../components/Input/InputNumber';
import { InputText } from '../../../components/Input';
import { SimpleSelect } from '../../../components/Input/SimpleSelect';

const C: { [key in string]: any } = {
  [ParameterType[ParameterType.NUMERIC]]: InputNumber,
  [ParameterType[ParameterType.STRING]]: InputText,
  [ParameterType[ParameterType.OPTION]]: SimpleSelect,
};

export default C;
