import { HiOutlineTrash, HiPlusCircle } from 'react-icons/hi2';
import { Button } from '../../../../components/Elements';
import React from 'react';
import BasicParameterForm from '../Parameter/BasicParameterForm';
import { DisplayConfiguration } from '../../../../api/scenarioTemplate/scenarioTemplateApi';
import ArrayFieldPositionMover from '../ArrayFieldPositionMover';

type ParameterSectionProps = {
  type: 'AMOUNT_CHANGE' | 'FLOW_SWITCH' | 'ADDITIONAL_PARAMETER';
  parameterType?: 'OPTION' | 'FORMULA' | 'NUMBER' | 'STRING';
  append: (value: any) => void;
  remove: (index: number) => void;
  move: (from: number, to: number) => void;
  fields: any[];
  name: string;
  includeDisplay?: boolean;
  displayConfigurations?: DisplayConfiguration[];
};

function ParameterSection(props: ParameterSectionProps) {
  const { fields, type, parameterType, remove, includeDisplay, displayConfigurations, append, move, name } = props;

  // console.log('Name: ', name, { fields });

  return (
    <div className="my-1 rounded py-2">
      <h3 className={`ml-4 text-lg py-4 text-center`}>{name}</h3>
      <hr className="bg-gray-300 h-[1px]" />
      {
        <div className="grid grid-cols-12 py-2">
          {fields.map((parameterField: any) => {
            const { index, color } = parameterField;
            if (parameterField.type === type) {
              return (
                <div key={parameterField.id} className="col-span-6 p-1 m-1 flex flex-row  justify-around">
                  <BasicParameterForm
                    {...parameterField}
                    defaultValues={{}}
                    includeDisplay={includeDisplay}
                    displayConfigurations={displayConfigurations}
                    prefix={`parameters.${index}`}
                    color={color}
                  />

                  <ArrayFieldPositionMover fields={fields} index={index} move={move} />
                  <Button type="button" variant={'none'} className="text-red-500" onClick={() => remove(index)}>
                    <HiOutlineTrash className="h-6 w-6" />
                  </Button>
                </div>
              );
            }
          })}
          <Button
            type="button"
            variant={'none'}
            className={`col-span-6 my-2 mx-3 flex text-blue-500 border-2 bg-white border-blue-500 hover:bg-blue-600 hover:text-white`}
            onClick={() =>
              append({
                parameterName: '',
                type,
                parameterType: parameterType ? parameterType : 'NUMERIC',
              })
            }
          >
            <div className="flex items-center">
              <HiPlusCircle className="h-8 w-8 mr-4" />
              Add Parameter
            </div>
          </Button>
        </div>
      }
    </div>
  );
}

export default ParameterSection;
