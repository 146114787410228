import { useFormContext } from 'react-hook-form';
import { Select } from '../../../../components/Input';
import React, { useMemo } from 'react';
import {
  ModeledProcessInfoDto,
  TreeInfoDto,
  useGetAllTreesInfoQuery,
} from '../../../../api/lightweightOlcaCore/lightweightOlcaCoreApi';

type OptionFormProps = {
  prefix: String;
};

function FlowSwitchForm(props: OptionFormProps) {
  const { prefix } = props;

  const { data: trees } = useGetAllTreesInfoQuery({
    includeSubprocesses: true,
  });

  const { control, register, watch } = useFormContext();

  const rootModeledProcessRefId = watch(`${prefix}.rootModeledProcessRefId`);

  const processes: ModeledProcessInfoDto[] = useMemo(() => {
    if (rootModeledProcessRefId && trees) {
      const tree = trees.find((t) => t.processRefId === rootModeledProcessRefId);
      return tree?.subProcesses as ModeledProcessInfoDto[];
    } else {
      return [];
    }
  }, [rootModeledProcessRefId, trees]);

  return (
    <div>
      <Select
        className="my-3"
        control={control}
        name={`${prefix}.rootModeledProcessRefId`}
        label={'Root model'}
        options={trees?.map((t: TreeInfoDto) => ({
          label: t.name,
          value: t.processRefId,
        }))}
      />
      <Select
        control={control}
        className="my-3"
        name={`${prefix}.parentRefId`}
        label={'Parent process'}
        options={
          processes.map((p: ModeledProcessInfoDto) => ({
            label: p.id,
            value: p.id,
          })) as []
        }
      />
    </div>
  );
}

export default FlowSwitchForm;
