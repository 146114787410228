import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  Tag,
  useGetAllProjectQuery,
  useGetAllTagQuery,
  useGetScenarioQuery,
  usePostScenarioMutation,
} from '../../../api/project/projectApi';
import { useGetAllScenarioTemplateQuery } from '../../../api/scenarioTemplate/scenarioTemplateApi';
import { Spinner } from '../../../components/Elements';
import { useBreadcrumbs } from '../../../lib/breadcrumbs';
import { ScenarioForm } from './ScenarioForm';
import { toast } from 'react-hot-toast/headless';

function Scenario() {
  const { scenarioId } = useParams();

  const {
    data: scenario,
    error,
    isLoading: isLoadingScenario,
  } = useGetScenarioQuery({ scenarioId: scenarioId as string });

  const [postScenario] = usePostScenarioMutation();

  const { data: projects, isLoading: isLoadingProject } = useGetAllProjectQuery({});
  const { data: templates, isLoading: isLoadingScenarioTemplates } = useGetAllScenarioTemplateQuery({});
  const { data: tags, isLoading: isLoadingTags } = useGetAllTagQuery({});

  const initialValues: any = useMemo(() => {
    return {
      ...scenario,
      tags: scenario?.tags?.map((t: Tag) => t.id) || [],
    };
  }, [scenario]);

  const { setBreadcrumbs } = useBreadcrumbs();

  useEffect(() => {
    if (scenario) {
      setBreadcrumbs([{ label: 'scenarios', path: '/app/scenarios' }, { label: `${scenario.name}` }]);
    }
  }, [scenario]);

  const onSubmit = async (scenario: any) => {
    // console.log({ scenario });
    scenario.tags = scenario.tags?.map((t: any) => ({ id: t }));
    const updatedScenario: any = await postScenario({
      scenarioDto: scenario,
    }).unwrap();
    if (updatedScenario.error) {
      // console.log(updatedScenario.error);
      toast.error('Failed to update scenario');
    } else {
      toast.success('Scenario updated successfully');
    }
  };

  if (isLoadingProject || isLoadingScenario || isLoadingScenarioTemplates || isLoadingTags) {
    return <Spinner size={'h-14 w-14'} className="w-12 h-12 self-center m-5" />;
  }

  // console.log({ initialValues });

  return (
    <ScenarioForm
      onSubmit={onSubmit}
      initialValues={initialValues}
      submitLabel={'Update'}
      projects={projects?.content}
      scenarioTemplates={templates}
      tags={tags}
    />
  );
}

export default Scenario;
