import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ScenarioTemplate from '../components/BasicScenarioTemplate/ScenarioTemplate';
import { Templates } from './Templates';
import { StateMachineProvider } from 'little-state-machine';
import ScenarioTemplateParameters from '../components/BasicScenarioTemplate/ScenarioTemplateParameters';
import ScenarioTemplateCustomization from '../components/BasicScenarioTemplate/ScenarioTemplateCustomization';
import BomScenarioTemplate from '../components/BomScenarioTemplate/BomScenarioTemplate';
import ScenarioTemplateFlowSwitch from '../components/BasicScenarioTemplate/ScenarioTemplateFlowSwitch';
import ScenarioTemplateDisplay from '../components/BasicScenarioTemplate/ScenarioTemplateDisplay';
import ScenarioTemplateLayout from '../components/BasicScenarioTemplate/ScenarioTemplateLayout';
import BomScenarioTemplateCustomization from '../components/BomScenarioTemplate/BomScenarioTemplateCustomization';

export const TemplateRoutes = () => {
  return (
    <StateMachineProvider>
      <Routes>
        <Route path="" element={<Templates />} />
        <Route path="/basic" element={<Templates type="BASIC" />} />
        <Route path="/bom" element={<Templates type="BOM" />} />
        <Route path="/basic/:templateId" element={<ScenarioTemplate />} />
        <Route path="/basic/new" element={<ScenarioTemplate />} />
        <Route path="/basic/customization" element={<ScenarioTemplateCustomization />} />
        <Route path="/basic/flow-switch" element={<ScenarioTemplateFlowSwitch />} />
        <Route path="/basic/tabs" element={<ScenarioTemplateDisplay />} />
        <Route path="/basic/parameters" element={<ScenarioTemplateParameters />} />
        <Route path="/basic/layout" element={<ScenarioTemplateLayout />} />
        <Route path="/bom/:templateId" element={<BomScenarioTemplate />} />
        <Route path="/bom/new" element={<BomScenarioTemplate />} />
        <Route path="/bom/customization" element={<BomScenarioTemplateCustomization />} />
      </Routes>
    </StateMachineProvider>
  );
};
