import { Dialog, Transition } from '@headlessui/react';
import {
  HiBars3BottomLeft,
  HiMiniCubeTransparent,
  HiOutlineArrowPathRoundedSquare,
  HiOutlineBolt,
  HiOutlineScale,
  HiOutlineSquare2Stack,
  HiOutlineSquare3Stack3D,
  HiOutlineSquares2X2,
  HiOutlineTag,
  HiXMark,
} from 'react-icons/hi2';
import { Fragment, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import logo from '../../logo-1.png';
import classNames from '../../utils/classNames';
import Breadcrumbs from './Breadcrumb/Breadcrumbs';
import UserNavigation from './UserNavigation';
import useRenderCounter from '../../hooks/useRenderCounter';
import { useAuth } from '../../features/auth/AuthProvider';
import { TbCubeUnfolded } from 'react-icons/tb';
import { HiOutlineFolder } from 'react-icons/hi';
import { AiOutlineApi } from 'react-icons/ai';
import { PiFlowArrow, PiFlowArrowBold } from 'react-icons/pi';

type NavigationMenu = {
  name: string;
  children: NavigationItem[];
};

type NavigationItem = {
  name: string;
  to: string;
  end?: boolean;
  icon: React.ElementType;
  children?: NavigationItem[];
};

const CustomImage = (props) => {
  return (
    <>
      <img
        src={`/cubes.png`}
        alt={'outlined cubes'}
        className={classNames(props.className ? props.className : '')}
        style={{ minWidth: '22px', marginLeft: '-2px' }}
      />
    </>
  );
};

export const MainLayout = (props: any) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [showSidebarText, setShowSidebarText] = useState(true);
  const renderCounter = useRenderCounter();

  const { user, changeDefaultRealm, setActualRealm, actualRealm, defaultRealm, allowedRealms, login } = useAuth();

  const navigationItems: NavigationMenu[] = [
    {
      name: 'Platform',
      children: [
        {
          name: 'Projects',
          to: 'app/projects',
          icon: HiOutlineFolder,
        },
        {
          name: 'Scenarios',
          to: 'app/scenarios',
          icon: HiOutlineSquare2Stack,
        },
        {
          name: 'Comparisons',
          to: 'app/comparisons',
          icon: HiOutlineScale,
        },
      ],
    },
    {
      name: 'Expert',
      children: [
        {
          name: 'Templates',
          end: true,
          to: 'app/templates',
          icon: TbCubeUnfolded,
          children: [
            {
              name: 'Basic',
              to: 'app/templates/basic',
              icon: HiMiniCubeTransparent,
            },
            {
              name: 'Bom',
              to: 'app/templates/bom',
              icon: CustomImage,
            },
          ],
        },
        {
          name: 'Modeled Processes',
          to: 'app/modeled-processes',
          icon: HiOutlineSquares2X2,
        },
        {
          name: 'Phases',
          to: 'app/phases',
          icon: HiOutlineArrowPathRoundedSquare,
        },
        {
          name: 'Tags',
          to: 'app/tags',
          icon: HiOutlineTag,
        },
        {
          name: 'Customization Spaces',
          to: 'app/customization-spaces',
          icon: HiOutlineSquare3Stack3D,
        },
        {
          name: 'Impact Methodologies',
          to: 'app/impact-methods',
          icon: HiOutlineBolt,
        },
        {
          name: 'External integrations',
          to: 'app/integrations',
          icon: AiOutlineApi,
        },
        {
          name: 'Remote flows',
          to: 'app/remote-flows',
          icon: PiFlowArrow,
        },
      ],
    },
  ].filter((item) => {
    if (item.name === 'Expert') {
      return user?.role === 'ADMIN'; // only include if checkAccess returns true
    }
    return true; // include all other items
  });

  const navigationitemRender = (item: NavigationItem, showSidebarText: boolean) => (
    <div key={item.name} className={'items-center my-2'}>
      {(showSidebarText || (!showSidebarText && !item.children)) && (
        <NavLink
          to={item.to && item.to}
          end={item.end}
          className={({ isActive, isPending, isTransitioning }) =>
            classNames(
              'hover:bg-blue-100 hover:text-blue-400 group flex items-center rounded px-3 py-2 text-sm font-medium',
              showSidebarText ? '' : 'justify-center',
              isActive ? 'bg-blue-200 text-blue-600' : 'text-gray-600',
            )
          }
        >
          <item.icon className="text-gray-600 h-6 w-6 flex-shrink-0" />
          {showSidebarText && <span className="truncate pl-3">{item.name}</span>}
        </NavLink>
      )}
      {item.children ? (
        <ul className={classNames(showSidebarText ? 'ml-6' : '')}>
          {item.children.map((c: NavigationItem) => navigationitemRender(c, showSidebarText))}
        </ul>
      ) : null}
    </div>
  );

  return (
    <div className="flex flex-col justify-around min-h-screen max-w-screen">
      <div className="flex-shrink mx-auto px-2 lg:divide-y lg:divide-gray-200 lg:px-4 w-full">
        {/* Navigation bar */}
        <div className="relative flex h-16 justify-between">
          <div className="relative z-10 flex px-2 lg:px-0">
            <div className="flex flex-shrink-0 items-center">
              <button
                type="button"
                className="pr-4 text-blue-700 focus:outline-none hidden lg:block"
                onClick={() => {
                  setSidebarOpen(true);
                  setShowSidebarText(!showSidebarText);
                }}
              >
                <span className="sr-only">Open sidebar</span>
                <HiBars3BottomLeft className="h-8 w-8 bg-blue-100 hover:bg-blue-200 p-1 rounded" aria-hidden="true" />
              </button>
              <button
                type="button"
                className="pr-4 text-blue-700 focus:outline-none block lg:hidden"
                onClick={() => {
                  setSidebarOpen(true);
                  setShowSidebarText(true);
                }}
              >
                <span className="sr-only">Open sidebar</span>
                <HiBars3BottomLeft className="h-8 w-8 bg-blue-100 hover:bg-blue-200 p-1 rounded" aria-hidden="true" />
              </button>
              <img className="block h-8 w-auto" src={logo} alt="Your Company" />
            </div>
          </div>
          <div className="relative z-0 flex flex-1 items-center justify-center px-2 sm:absolute sm:inset-0"></div>
          {actualRealm && user && (
            <UserNavigation
              user={user}
              changeDefaultRealm={changeDefaultRealm}
              setActualRealm={setActualRealm}
              defaultRealm={defaultRealm}
              actualRealm={actualRealm}
              allowedRealms={allowedRealms}
              login={login}
            />
          )}
        </div>
      </div>

      <main className="flex-1" style={{ minHeight: '95vh' }}>
        <div className="grid grid-cols-48 min-h-[inherit]">
          {/* Sidebar */}
          <Transition.Root show={sidebarOpen} as={Fragment}>
            <Dialog as="div" className="relative z-40 lg:hidden" onClose={setSidebarOpen}>
              <Transition.Child
                as={Fragment}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
              </Transition.Child>

              <div className="fixed inset-0 z-40 flex">
                <Transition.Child
                  as={Fragment}
                  enter="transition ease-in-out duration-300 transform"
                  enterFrom="-translate-x-full"
                  enterTo="translate-x-0"
                  leave="transition ease-in-out duration-300 transform"
                  leaveFrom="translate-x-0"
                  leaveTo="-translate-x-full"
                >
                  <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-white pt-5 pb-4">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-300"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="absolute top-0 right-0 -mr-14 p-1">
                        <button
                          type="button"
                          className="flex h-12 w-12 items-center justify-center rounded-full focus:bg-gray-600 focus:outline-none"
                          onClick={() => setSidebarOpen(false)}
                        >
                          <HiXMark className="h-6 w-6 text-white" aria-hidden="true" />
                          <span className="sr-only">Close sidebar</span>
                        </button>
                      </div>
                    </Transition.Child>
                    <div className="mt-5 h-0 flex-1 overflow-y-auto">
                      <aside className="py-6 px-2 sm:px-6 col-span-2 lg:py-0 lg:px-0">
                        <nav className="space-y-1">
                          {navigationItems.map((menu: NavigationMenu) => (
                            <div key={menu.name} className="border-b border-blue-100 py-6">
                              <p className="font-bold text-md">{menu.name}</p>
                              {menu.children.map((item: NavigationItem) => navigationitemRender(item, true))}
                            </div>
                          ))}
                        </nav>
                      </aside>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
                <div className="w-14 flex-shrink-0" aria-hidden="true">
                  {/* Dummy element to force sidebar to shrink to fit close icon */}
                </div>
              </div>
            </Dialog>
          </Transition.Root>
          <aside className={classNames(showSidebarText ? 'px-4 col-span-6' : 'col-span-2', 'py-2 hidden lg:block')}>
            <nav className="space-y-1 mt-8">
              {navigationItems.map((menu: NavigationMenu) => (
                <div key={menu.name} className="border-b border-blue-100 py-6">
                  {showSidebarText && <p className="font-bold text-md ml-4">{menu.name}</p>}
                  {menu.children.map((item: NavigationItem) => navigationitemRender(item, showSidebarText))}
                </div>
              ))}
            </nav>
          </aside>

          {/* Main Content */}
          <div
            className={classNames(
              showSidebarText ? 'lg:col-span-42' : 'lg:col-span-46',
              'col-span-48 py-3 px-3 bg-[#eef2f6] rounded-t flex flex-col relative',
            )}
          >
            {renderCounter}
            <Breadcrumbs />
            {props.children}
          </div>
        </div>
      </main>
    </div>
  );
};
