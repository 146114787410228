import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { Button } from '../Elements';
import Col from '../Layout/Col';
import Row from '../Layout/Row';
import { Item } from './Item';
import classNames from '../../utils/classNames';
import { HiOutlinePencilSquare } from 'react-icons/hi2';

const Form = (props: any) => {
  const {
    className,
    children,
    formRef,
    initialValues,
    onSubmit = (values: never) => console.log('Submission successful', values),
    onError = (errors: never) => console.error('Submission failed', errors),
    onValuesChange,
    onFormStateChange,
    validationSchema,
    mode,
    reValidateMode,
    submitLabel = 'Save',
    style = {},
    editing,
  } = props;

  const methods = useForm({
    mode: mode || 'onChange',
    reValidateMode: reValidateMode || 'onChange',
    shouldFocusError: true,
    shouldUnregister: true,
    ...(initialValues ? { defaultValues: { ...initialValues } } : {}),
    ...(validationSchema ? { resolver: yupResolver(validationSchema) } : {}),
  });

  useEffect(() => {
    onFormStateChange?.(methods.formState);
  }, [methods.formState, onFormStateChange]);

  const formValues = useWatch(methods);

  useEffect(() => {
    if (formRef)
      formRef.current = {
        ...methods,
        submit: methods.handleSubmit(onSubmit, onError),
      };
  }, [formRef, methods, onError, onSubmit]);

  useEffect(() => {
    onValuesChange?.(formValues);
  }, [formValues, onValuesChange]);

  // console.log(formValues);

  return (
    <FormProvider {...{ ...methods, editing: editing || false }}>
      <div className={classNames(className ? className : '', 'shadow rounded bg-white p-4')}>
        <form onSubmit={methods.handleSubmit(onSubmit, onError)}>
          <Row className="mx-4 gap-y-5 gap-x-5 my-5">
            {children}
            <Col>
              <Button
                variant={'none'}
                type="submit"
                className={
                  style.submit
                    ? style.submit
                    : 'float-right ml-3 text-blue-500 border-2 bg-white border-blue-500 hover:bg-blue-600 hover:text-white'
                }
              >
                <HiOutlinePencilSquare className="h-6 w-6 mr-2" /> {submitLabel}
              </Button>
            </Col>
          </Row>
        </form>
      </div>
    </FormProvider>
  );
};

Form.Item = Item;

export { Form };
