import { CustomizedItemDto } from '../../../api/lightweightCalculationEngine/lightweightCalculationEngineApi';
import { createBasicCustomizationPayload } from './createBasicCustomizationPayload';
import {
  BomScenarioTemplate,
  ItemScenarioTemplatePair,
  Parameter,
} from '../../../api/scenarioTemplate/scenarioTemplateApi';

export const createBomCustomizationPayload = (bomParameters: Parameter[], scenarioTemplate: BomScenarioTemplate) => {
  const customizedScenario: CustomizedItemDto[] = [];

  scenarioTemplate.itemScenarioTemplateConfiguration?.forEach((itemScenarioTemplate: ItemScenarioTemplatePair) => {
    const itemIdentifier = `:${itemScenarioTemplate.parentId ? itemScenarioTemplate.parentId + ':' : ''}${
      itemScenarioTemplate.itemId
    }`;
    const itemParameters = bomParameters.filter(
      (p) => p.type !== 'FLOW_SWITCH' || (p.type === 'FLOW_SWITCH' && p.item === itemIdentifier),
    );

    const parameters = createBasicCustomizationPayload(itemParameters, itemScenarioTemplate.variableConfigurations);

    customizedScenario.push({
      itemId: itemScenarioTemplate.itemId,
      itemPath: itemScenarioTemplate.parentId?.replace('root', '').replace(/^:+/g, ''),
      customizedBasicScenario: {
        parameters,
        assessmentConfigurations: itemScenarioTemplate.assessmentConfigurations,
      },
    });
  });

  return customizedScenario;
};
