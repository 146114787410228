import * as React from 'react';

import classNames from '../../../utils/classNames';

const variants = {
  primary: 'bg-blue-500 text-white hover:bg-blue-600',
  inverse: 'bg-white text-blue-600 border border-blue-600',
  danger: 'bg-red-600 text-white',
  none: '',
};

const sizes = {
  xs: 'py-1 px-1 text-sm',
  sm: 'py-1 px-2 text-sm',
  md: 'py-2 px-4 text-md',
  lg: 'py-3 px-8 text-lg',
};

type IconProps = {
  startIcon?: React.ReactElement | undefined;
  endIcon?: React.ReactElement | undefined;
};

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  variant?: keyof typeof variants;
  size?: keyof typeof sizes;
} & IconProps;

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ type = 'button', className = '', variant = 'primary', size = 'md', startIcon, endIcon, ...props }, ref) => {
    return (
      <button
        ref={ref}
        type={type}
        className={classNames(
          'flex justify-center items-center disabled:opacity-70 rounded disabled:cursor-not-allowed focus:outline-none',
          variants[variant],
          sizes[size],
          className,
        )}
        {...props}
      >
        {startIcon}
        {props.children}
        {endIcon}
      </button>
    );
  },
);

Button.displayName = 'Button';
