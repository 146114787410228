import React, { useMemo, useState } from 'react';
import '../style/style.css';
import { ScenarioDto, Tag, useGetAllTagQuery, usePostScenarioMutation } from '../../../api/project/projectApi';
import { HiPlusCircle, HiXCircle } from 'react-icons/hi2';
import { SimpleSelect } from '../../../components/Input/SimpleSelect';
import { Button } from '../../../components/Elements';

type ScenarioTagSelectorProps = {
  scenario: ScenarioDto;
};

export default function ScenarioTagSelector(props: ScenarioTagSelectorProps) {
  const { scenario } = props;

  const [triggerAddTag, setTriggerAddTag] = useState(false);
  const [postScenario] = usePostScenarioMutation();
  const { data: allTags } = useGetAllTagQuery({});

  const availableTags = useMemo(() => {
    return scenario
      ? allTags?.filter((tag) => {
          return !scenario.tags?.some((t) => t.id === tag.id);
        })
      : [];
  }, [scenario, allTags]);

  const handleTriggerAddTag = () => {
    setTriggerAddTag(!triggerAddTag);
  };

  const handleAddTagToScenario = (tagId: string) => {
    setTriggerAddTag(false);
    let tags = scenario.tags?.map((t: Tag) => ({ id: t.id }));
    tags?.push({ id: tagId });
    postScenario({ scenarioDto: { ...scenario, tags: tags } });
  };

  const handleRemoveTag = (tagId: string) => {
    let tags = scenario.tags?.filter((t: Tag) => t.id !== tagId).map((t: Tag) => ({ id: t.id }));
    postScenario({ scenarioDto: { ...scenario, tags: tags } });
  };

  // console.log("s.id: ", scenario.id, " tags:  ", scenario.tags)

  return (
    <div className={'inline-flex py-1'}>
      {scenario?.tags?.map((tag) => {
        return (
          <div
            style={{ backgroundColor: tag.color, height: '48px' }}
            className="inline-flex mt-0.5 items-center ml-1 mr-2 gap-x-2.5 rounded px-2 text-sm font-semibold text-white shadow"
          >
            {tag.name}
            <HiXCircle
              className="cursor-pointer h-6 w-6"
              aria-hidden="true"
              onClick={() => handleRemoveTag(tag.id as string)}
            />
          </div>
        );
      })}
      <Button
        className="inline-flex mt-0.5 items-center ml-1 text-blue-500 border-2 bg-white border-blue-500 hover:bg-blue-600 hover:text-white rounded"
        style={{ height: '48px' }}
        onClick={handleTriggerAddTag}
        variant="none"
        size={'md'}
      >
        {triggerAddTag ? (
          <div className={'inline-flex'}>
            Close <HiXCircle className="ml-2 h-6 w-6" />
          </div>
        ) : (
          <div className={'inline-flex'}>
            Add tag <HiPlusCircle className="ml-2 h-6 w-6" />
          </div>
        )}
      </Button>

      {triggerAddTag && availableTags && (
        <div className="inline-flex items-center ml-3">
          <SimpleSelect
            id={`${scenario?.id}.tags`}
            style={{}}
            className="-mb-1"
            options={availableTags.map((t) => ({
              value: t?.id as string,
              label: t?.name as string,
            }))}
            value=""
            label={'Tags'}
            onChange={(value) => handleAddTagToScenario(value)}
          />
        </div>
      )}
    </div>
  );
}
