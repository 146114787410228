import classNames from '../../utils/classNames';
import CustomSelect from 'react-select';
import React, { useMemo } from 'react';
//
type SelectProps = {
  options: { label: string | undefined; value: any; disabled?: boolean; color?: string }[] | undefined;
  label?: string;
  className?: string;
  style?: { [key: string]: string };
  id?: string;
  name?: string;
  value?: any;
  onChange: (value: any) => void;
  accessorFn?: (value: any) => string;
  description?: string;
  disabled?: boolean;
  error?: string | null | undefined;
  isMulti?: boolean;
  placeholder?: string;
};

export const SimpleSelect = React.forwardRef<any, SelectProps>((props, ref) => {
  const {
    options,
    accessorFn,
    label,
    id,
    name,
    className,
    error,
    value,
    onChange,
    placeholder,
    disabled,
    description,
    isMulti,
  } = props;

  const selectStyle = useMemo(
    () => ({
      control: (baseStyles, state) => ({
        ...baseStyles,
        border: 'none',
        paddingTop: '10px',
        backgroundColor: '#F8FAFC',
      }),
      placeholder: (baseStyles, state) => ({
        ...baseStyles,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      }),
      option: (baseStyles, state) => ({
        ...baseStyles,
        color: state.data.color ? state.data.color : 'black',
        zIndex: 1000,
      }),
      container: (baseStyles, state) => ({
        ...baseStyles,
        borderRadius: '20px',
      }),
      menu: (baseStyles, state) => ({
        ...baseStyles,
        zIndex: 1000,
      }),

      menuList: (baseStyles, state) => ({
        ...baseStyles,
        zIndex: 1000,
      }),
    }),
    [],
  );

  return (
    <div className={classNames(className ? className : '', 'relative')}>
      <label className="ml-2 whitespace-nowrap bg-gradient-to-t from-[#F8FAFC] from-65%  via-transparent via-65% to-transparent absolute -top-[8px] z-[2] left-4 px-1 text-xs text-gray-700 rounded">
        {label}
      </label>
      <div
        className={`flex border rounded border-gray-300 ${error ? 'border-red-500' : ''}`}
        style={{ backgroundColor: '#F8FAFC' }}
      >
        {isMulti ? (
          <CustomSelect
            isMulti
            key={id}
            id={id}
            noOptionsMessage={(e) => 'No options available'}
            name={name}
            placeholder={
              !options || options.length === 0 ? 'No options available' : placeholder ? placeholder : 'Select an option'
            }
            isDisabled={disabled ? disabled : !options || options.length === 0}
            classNames={{}}
            styles={selectStyle}
            isOptionSelected={(option, selectValues) => {
              return selectValues.some((sv) =>
                accessorFn ? accessorFn(sv?.value) === option?.value : sv?.value === option?.value,
              );
            }}
            options={accessorFn ? options?.map((o) => ({ label: o.label, value: accessorFn(o.value) })) : options}
            value={(() => {
              let a = null;
              if (options && value) {
                if (accessorFn) {
                  a = value.map((v) => ({
                    label: options.find((o) => accessorFn(v) === accessorFn(o.value))?.label,
                    value: v,
                  }));
                } else {
                  a = value.map((v) => ({
                    label: options.find((o) => v === o.value)?.label,
                    value: v,
                  }));
                }
              }
              // console.log("value:::::", a)
              return a;
            })()}
            // value={value}
            ref={ref}
            onChange={(values) => {
              const a = values?.map((selectedValue) => {
                if (accessorFn && typeof selectedValue?.value === 'string') {
                  // console.log("returning", options.find(() => options?.find((o) => accessorFn(o.value) === selectedValue.value))?.value ?? null)
                  return options?.find((o) => accessorFn(o.value) === selectedValue.value)?.value ?? null;
                } else {
                  return selectedValue?.value;
                }
              });
              // console.log("onChange:::::", a)
              onChange(a);
            }}
            className="flex-1 bg-white "
          />
        ) : (
          <CustomSelect
            key={id}
            id={id}
            name={name}
            placeholder={
              !options || options.length === 0 ? 'No options available' : placeholder ? placeholder : 'Select an option'
            }
            isDisabled={disabled ? disabled : !options || options.length === 0}
            styles={selectStyle}
            options={options}
            value={options ? options.find((o) => o.value === value) ?? null : null}
            ref={ref}
            isOptionDisabled={(option) => option.disabled as boolean}
            onChange={(e) => {
              // console.log("############CALLING ON CHANGE: ", e?.value)
              onChange(e?.value);
            }}
            className="flex-1 bg-white relative"
          />
        )}
      </div>
      {error && <p className="text-sm text-red-500">{error}</p>}

      {description && <p className="ml-2 text-xs text-gray-500">{description}</p>}
    </div>
  );
});
