import { HiOutlineDocumentDuplicate, HiOutlinePencilSquare, HiOutlineTrash } from 'react-icons/hi2';
import { useCopyScenarioCustomizedProcessMutation } from '../../../api/customizedScenario/customizedScenarioApi';
import {
  ScenarioDto,
  Tag,
  useDeleteScenarioMutation,
  useGetAllProjectQuery,
  usePostScenarioMutation,
} from '../../../api/project/projectApi';
import {
  AssessmentConfiguration,
  ScenarioTemplate,
  useGetAllScenarioTemplateQuery,
} from '../../../api/scenarioTemplate/scenarioTemplateApi';
import { Button, Link, Spinner } from '../../../components/Elements';
import Tooltip from '../../../components/Tooltip/Tooltip';
import PaginationTable from '../../../components/Pagination/PaginationTable';
import classNames from '../../../utils/classNames';
import { toast } from 'react-hot-toast/headless';
import { TbSquareLetterC, TbSquareLetterE, TbSquareLetterR, TbSquareLetterS } from 'react-icons/tb';
import { LiaCubeSolid, LiaCubesSolid } from 'react-icons/lia';
import React, { useState } from 'react';
import ButModal from '../../../components/Modal/ButModal';

const assessmentTypesMap = {
  LCA: <TbSquareLetterE className="h-6 w-6 text-green-600" />,
  LCC: <TbSquareLetterC className="h-6 w-6 text-blue-600" />,
  SLCA: <TbSquareLetterS className="h-6 w-6 text-pink-600" />,
  CE: <TbSquareLetterR className="h-6 w-6" style={{ color: '#964B00' }} />,
};

function ListScenario(props: any) {
  const { filter, fetchData, className } = props;

  const [deleteScenario] = useDeleteScenarioMutation();
  const [postScenario] = usePostScenarioMutation();
  const [copyScenarioCustomizedProcess] = useCopyScenarioCustomizedProcessMutation();
  const [selectedScenarioToDelete, setSelectedScenarioToDelete] = useState<ScenarioDto | null>(null);

  const { data: projects, isLoading: isLoadingProject } = useGetAllProjectQuery({});

  const { data: scenarioTemplates, isLoading: isLoadingScenarioTemplates } = useGetAllScenarioTemplateQuery({});

  const duplicateScenario = async (scenario: ScenarioDto) => {
    const newScenario: ScenarioDto = await postScenario({
      scenarioDto: {
        name: `${scenario.name} - copy`,
        projectId: scenario.projectId,
        scenarioTemplateId: scenario.scenarioTemplateId,
        description: scenario.description,
      },
    }).unwrap();

    const scenarioCopy: any = await copyScenarioCustomizedProcess({
      oldScenarioId: scenario.id as string,
      newScenarioId: newScenario.id as string,
    });

    if (scenarioCopy.error) {
      toast.error('Failed to duplicate scenario');
    } else {
      toast.success('Scenario duplicated successfully');
    }
  };

  const deleteScenarioConfirmed = async (id: string) => {
    const response: any = await deleteScenario({ scenarioId: id as string });
    if (response.error) {
      toast.error('Failed to delete scenario');
    } else {
      toast.success('Scenario deleted successfully');
    }
  };

  const renderItem = (scenario: ScenarioDto, additionalParameters: any) => {
    const { projects, scenarioTemplates } = additionalParameters;

    return (
      <li key={scenario.id}>
        <div className="grid grid-cols-12">
          <Link
            to={`/app/customizations/${scenario.id}${filter.projectId ? '/project/' + filter.projectId : ''}`}
            className="block col-span-9 hover:bg-blue-100 p-4"
          >
            <div className="grid grid-cols-12">
              <div className="col-span-1">
                {scenarioTemplates?.find((st: ScenarioTemplate) => st.id === scenario.scenarioTemplateId)?.type ===
                'BOM' ? (
                  <LiaCubesSolid className="h-6 w-6" />
                ) : (
                  <LiaCubeSolid className="h-6 w-6" />
                )}
              </div>

              <p className="col-span-7 truncate text-sm font-medium text-blue-600">
                {scenario.name}
                {scenario.tags?.map((tag: Tag) => {
                  return (
                    <div
                      className="inline-flex rounded-full px-2 text-xs font-semibold leading-5 text-white ml-3"
                      style={{ backgroundColor: tag.color }}
                    >
                      {tag.name}
                    </div>
                  );
                })}
              </p>
              <div className="col-span-2 flex py-1">
                {scenarioTemplates
                  ?.find((st: ScenarioTemplate) => st.id === scenario.scenarioTemplateId)
                  ?.assessmentConfigurations?.map((ac: AssessmentConfiguration) => {
                    return assessmentTypesMap[ac.assessmentType as string];
                  })}
              </div>
              <p className="col-span-2 m-1 text-center rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                {projects?.content?.find((p) => p.id === scenario.projectId)?.name}
              </p>
              {/*<p className="col-span-1 m-1 rounded-full bg-yellow-100 px-2 text-xs font-semibold leading-5 text-yellow-800">*/}
              {/*  {scenarioTemplates?.find((st) => st.id === scenario.scenarioTemplateId)?.name}*/}
              {/*</p>*/}
            </div>
            <div className="grid grid-cols-12 sm:justify-between">
              <div className="col-start-2 col-span-9">
                <p className="flex items-center text-sm text-gray-500">{scenario.description}</p>
              </div>
              <div className="mt-2 col-span-2 flex items-center text-sm text-gray-500 sm:mt-0">
                <p>{new Date(scenario.created as string).toDateString()}</p>
              </div>
            </div>
          </Link>
          {/* <Link className="flex col-span-1 items-center hover:bg-green-100" to={`../assessments/${scenario.id}`}>
                    <ChartBarSquareIcon className="h-6 w-6 m-auto text-green-600" />
                  </Link> */}
          <Link
            to={`/app/scenarios/edit/${scenario.id}`}
            className="flex col-span-1 items-center hover:bg-blue-100 text-center justify-center"
          >
            <Tooltip content="Edit">
              <HiOutlinePencilSquare className="h-6 w-6 m-auto text-blue-600"></HiOutlinePencilSquare>
            </Tooltip>
          </Link>
          <Button
            variant="inverse"
            className="flex col-span-1 items-center hover:bg-green-100 border-0 text-green-500 rounded-none"
            onClick={() => duplicateScenario(scenario)}
          >
            <Tooltip content="Duplicate" className="text-color-inherit">
              <HiOutlineDocumentDuplicate className="h-6 w-6 text-blue-600" />
            </Tooltip>
          </Button>
          <ButModal
            label={<HiOutlineTrash className="h-6 w-6" />}
            submitLabel={'Yes'}
            confirm={() => deleteScenarioConfirmed(scenario.id as string)}
            className={'flex col-span-1 items-center hover:bg-red-100 border-0 text-red-500 rounded-none'}
          >
            <h2 className="text-xl text-gray-700 mb-4 mt-1 px-4 text-center">
              Are you sure you want to delete the "{scenario?.name}" scenario?
            </h2>
          </ButModal>
        </div>
      </li>
    );
  };

  if (isLoadingProject || isLoadingScenarioTemplates) {
    return <Spinner size={'h-14 w-14'} className="w-12 h-12 self-center m-5" />;
  }

  return (
    <div className={classNames(className ? className : '', 'bg-white shadow sm:rounded')}>
      <ul className="divide-y divide-gray-200">
        <PaginationTable
          args={filter}
          noDataMessage={'No scenarios'}
          additionalParameters={{
            projects: projects,
            scenarioTemplates: scenarioTemplates,
          }}
          fetchData={fetchData}
          renderItem={renderItem}
        />
      </ul>
    </div>
  );
}

export default ListScenario;
