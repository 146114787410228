import React, { Fragment, ReactNode, useState } from 'react';
import { Button } from '../Elements';
import Modal from './Modal';
import classNames from '../../utils/classNames';

type ButModalProps = {
  children: React.ReactNode;
  label: string | ReactNode;
  submitLabel?: string;
  size?: 'sm' | 'md' | 'lg' | 'xl';
  className?: string;
  confirm?: () => void;
  closeOnSubmit?: boolean;
};

export default function ButModal(props: ButModalProps) {
  const { children, size, label, submitLabel, className, closeOnSubmit, confirm } = props;

  const [open, setOpen] = useState(false);

  const handleConfirm = () => {
    confirm?.();
    if (closeOnSubmit) {
      setOpen(false);
    }
  };

  return (
    <>
      <Button
        variant="none"
        className={classNames(
          className
            ? className
            : 'w-full text-blue-500 border-2 bg-white border-blue-500 hover:bg-blue-600 hover:text-white',
        )}
        onClick={() => setOpen(true)}
      >
        {label}
      </Button>
      <Modal
        open={open}
        handleOpen={setOpen}
        size={size}
        confirm={confirm ? handleConfirm : undefined}
        label={submitLabel}
      >
        {' '}
        {children}{' '}
      </Modal>
    </>
  );
}
