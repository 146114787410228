import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useStateMachine } from 'little-state-machine';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useGetScenarioTemplateQuery } from '../../../../api/scenarioTemplate/scenarioTemplateApi';
import { useBreadcrumbs } from '../../../../lib/breadcrumbs';
import { Spinner } from '../../../../components/Elements';
import BomScenarioTemplateForm from './BomScenarioTemplateForm';

function BomScenarioTemplate() {
  const { templateId } = useParams();
  const { setBreadcrumbs } = useBreadcrumbs();
  const { state } = useStateMachine();
  const { data: template, isLoading } = useGetScenarioTemplateQuery(
    templateId ? { scenarioTemplateId: templateId } : skipToken,
  );

  useEffect(() => {
    if (template) {
      setBreadcrumbs([{ label: 'templates', path: 'app/templates' }, ...(template && [{ label: `${template.name}` }])]);
    }
  }, [template]);

  if (isLoading) {
    return <Spinner size={'h-14 w-14'} className="w-12 h-12 self-center m-5" />;
  }

  return (
    <>
      <BomScenarioTemplateForm initialValues={template ?? {}} />
    </>
  );
}

export default BomScenarioTemplate;
