import { HiChevronLeft, HiChevronRight } from 'react-icons/hi2';
import React, { useEffect, useMemo, useState } from 'react';
import { Spinner } from '../Elements';
import { HiFolderOpen } from 'react-icons/hi2';
import { SimpleSelect } from '../Input/SimpleSelect';

type PaginationProps = {
  args: any;
  additionalParameters?: any;
  noDataMessage?: string;
  fetchData: ({ args, page, size }: { args: any; page: number; size: number }) => { data?: any; isLoading: boolean };
  renderItem: (item: any, additionalParameters: any) => React.ReactElement;
};

export default function PaginationTable(props: PaginationProps) {
  const { args, additionalParameters, noDataMessage, renderItem, fetchData } = props;

  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(parseInt(localStorage.getItem('PAGE_SIZE') ?? '10'));

  useEffect(() => {
    setPage(0);
  }, [args]);

  const pageSizes = useMemo(() => {
    const pageSizes: string[] = ['5', '10', '15', '30'];
    return pageSizes.map((i) => ({ label: i, value: i }));
  }, []);

  const handlePageChange = (page: number, size: number) => {
    setPage(page);
    setSize(size);
    localStorage.setItem('PAGE_SIZE', size.toString());
  };

  const { data, isLoading } = fetchData({
    ...args,
    page: page,
    size: size,
  });

  useEffect(() => {
    if (data) {
      handlePageChange(data.pageable?.pageNumber, data.size);
    }
  }, [data]);

  if (isLoading) {
    return (
      <div className="justify-center grid">
        <Spinner size={'h-14 w-14'} className="w-12 h-12 self-center m-5" />
      </div>
    );
  }

  const { first, last, totalPages, content, totalElements } = data || {};

  const generatePaginationLinks = () => {
    const links = [];
    for (let i = 0; i < totalPages; i++) {
      links.push(
        <button
          key={i}
          disabled={page === i}
          className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${
            page === i
              ? 'z-10 bg-blue-600 text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600'
              : 'text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
          }`}
          onClick={() => handlePageChange(i, size)}
        >
          {i + 1}
        </button>,
      );
    }
    return links;
  };

  return (
    data && (
      <div className={'rounded m-2'}>
        <div className="overflow-hidden bg-white">
          <ul className="divide-y divide-gray-200">
            {content?.length > 0 ? (
              Object.values(content).map((item: any | undefined) => {
                if (!item) {
                  return null;
                }
                return renderItem(item, additionalParameters);
              })
            ) : (
              <div className="text-center">
                <HiFolderOpen className={'mx-auto mt-4 h-12 w-12 text-gray-400'} aria-hidden="true" />
                <h3 className="my-2 text-sm font-semibold text-gray-900">
                  {noDataMessage ? noDataMessage : 'No content'}
                </h3>
              </div>
            )}
          </ul>
        </div>
        <div className="flex items-center justify-between border-t bg-white px-4 py-3 sm:px-6">
          <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
            <div className="flex">
              <p className="text-sm text-gray-700 flex items-center">
                Showing <span className="font-medium px-2">{page * size + 1}</span> to{' '}
                {page === totalPages - 1 ? totalElements : (page + 1) * size} of{' '}
                <span className="font-medium px-2">{totalElements}</span> results
              </p>
              <SimpleSelect
                id={'pageSize'}
                label={'# elements'}
                onChange={(value) => handlePageChange(0, value)}
                options={pageSizes}
                value={size.toString()}
                className={'px-6'}
              />
            </div>
            <div>
              <nav className="isolate inline-flex -space-x-px rounded shadow" aria-label="Pagination">
                <button
                  disabled={first}
                  className={
                    'relative inline-flex items-center rounded-l px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                  }
                  onClick={() => handlePageChange(page - 1, size)}
                >
                  <span className="sr-only">Previous</span>
                  <HiChevronLeft className="h-5 w-5" aria-hidden="true" />
                </button>
                {generatePaginationLinks()}
                <button
                  disabled={last}
                  className={
                    'relative inline-flex items-center rounded-r px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                  }
                  onClick={() => handlePageChange(page + 1, size)}
                >
                  <span className="sr-only">Next</span>
                  <HiChevronRight className="h-5 w-5" aria-hidden="true" />
                </button>
              </nav>
            </div>
          </div>
        </div>
      </div>
    )
  );
}
