import { useState } from 'react';
import { useGetScenarioTemplateQuery } from '../../../api/scenarioTemplate/scenarioTemplateApi';
import '../style/style.css';
import CustomizationTemplateForm, { IndexedParameter } from './CustomizationTemplateForm';
import useRenderCounter from '../../../hooks/useRenderCounter';

type BomItemCustomizationProps = {
  parameters: IndexedParameter[];
  scenarioTemplateId: string;
  handleExecute: () => void;
  onSave: () => void;
  autoFetch?: () => void;
};

const BomItemCustomization = (props: BomItemCustomizationProps) => {
  const { scenarioTemplateId, parameters, onTemplateInitialized, handleExecute, onSave, autoFetch } = props;

  const [phaseId, setPhaseId] = useState<string>();
  const { data: scenarioTemplate } = useGetScenarioTemplateQuery({
    scenarioTemplateId: scenarioTemplateId as string,
  });
  const renderCounter = useRenderCounter();

  return (
    <>
      {' '}
      {renderCounter}
      {scenarioTemplate ? (
        <CustomizationTemplateForm
          scenarioTemplate={scenarioTemplate}
          onExecute={handleExecute}
          onSave={onSave}
          changePhase={setPhaseId}
          currentPhaseId={phaseId}
          onTemplateInitialized={onTemplateInitialized}
          onFormChange={() => autoFetch && autoFetch()}
          parameters={parameters}
          displayConfigurations={scenarioTemplate.displayConfigurations}
        />
      ) : null}
    </>
  );
};

export default BomItemCustomization;
