import type { Middleware, MiddlewareAPI } from '@reduxjs/toolkit';
import { isRejectedWithValue } from '@reduxjs/toolkit';

import { toast } from 'react-hot-toast/headless';

/**
 * Log a warning and show a toast!
 */
export const rtkQueryErrorLogger: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
  //const sliceStatus = httpErrorState(api.getState());&& !sliceStatus

  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
  if (isRejectedWithValue(action) && action.payload.status !== 401) {
    console.warn('We got a rejected action!');
    toast.error(`${action.payload.status}:  ${action.payload.error}`);
  }

  return next(action);
};
