import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../../components/Elements';
import { Bom, useGetAllBomsQuery } from '../../../../api/bom/bomApi';
import BillOfMaterial from '../../../bom-tree/components/BillOfMaterial';
import { HiClipboardDocument, HiDocumentDuplicate, HiOutlineTrash, HiPlusCircle } from 'react-icons/hi2';
import {
  BasicScenarioTemplate,
  FormulaParameter,
  ItemScenarioTemplatePair,
  useGetAllScenarioTemplateQuery,
  usePostScenarioTemplateMutation,
} from '../../../../api/scenarioTemplate/scenarioTemplateApi';
import {
  ImpactMethod,
  useGetAllImpactMethodsQuery,
  useGetAllTreesInfoQuery,
} from '../../../../api/lightweightOlcaCore/lightweightOlcaCoreApi';
import React, { useMemo, useState } from 'react';
import { InputText, Select } from '../../../../components/Input';
import { getInputError } from '../../../../components/Input/util/getInputError';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-hot-toast/headless';
import { TextArea } from '../../../../components/Input/TextArea';
import Modal from '../../../../components/Modal/Modal';
import { useStateMachine } from 'little-state-machine';
import initScenarioTemplateAction from '../../utils/initScenarioTemplateAction';
import { Parameter } from '../../../../api/customizationSpace/customizationSpaceApi';
import { ParameterType } from '../../../../api/customizationSpace/types/ParameterType';
import { checkBomImportMapping } from '../../utils/checkBomImportMapping';
import ScenarioTemplateMapping from './ScenarioTemplateMapping';

type ScenarioTemplateFormProps = {
  initialValues: any;
};

function BomScenarioTemplateForm(props: ScenarioTemplateFormProps) {
  const { initialValues } = props;

  const navigate = useNavigate();
  const [availableImpactMethods, setAvailableImpactMethods] = useState<ImpactMethod[]>([]);
  const [doUpdate, setDoUpdate] = useState(false);
  const [importJson, setImportJson] = useState('');
  const [importMapping, setImportMapping] = useState([]);
  const [importModal, setImportModal] = useState(false);
  const { actions, state } = useStateMachine({ initScenarioTemplateAction });

  const { data: boms } = useGetAllBomsQuery({});
  const { data: scenarioTemplates } = useGetAllScenarioTemplateQuery({
    type: 'BASIC',
  });
  const { data: trees } = useGetAllTreesInfoQuery({
    includeSubprocesses: true,
  });
  const { data: impactMethods } = useGetAllImpactMethodsQuery({});

  const [postScenarioTemplate] = usePostScenarioTemplateMutation();

  const methods = useForm({
    defaultValues: initialValues,
  });
  const {
    register,
    control,
    reset,
    getValues,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = methods;

  const template = watch();

  const filteredScenarioTemplates = useMemo(() => {
    if (!scenarioTemplates || !impactMethods || !trees) return;

    let assessmentTemplates: any[] = [];
    template.assessmentConfigurations?.forEach((ac) => {
      const impactMethod = impactMethods.find((im) => im.id === ac.impactMethodId);
      if (impactMethod) {
        assessmentTemplates.push(
          ...scenarioTemplates
            .filter((s) => s.assessmentConfigurations?.some((c) => c.assessmentType === ac.assessmentType))
            .filter((s) => {
              const configurations = s.assessmentConfigurations?.find((c) => c.assessmentType === ac.assessmentType);
              const templateTrees = trees?.filter(
                (t) => configurations?.phaseConfigurations?.map((pc) => pc.treeId).includes(t.id),
              );
              // Calculating an intersect between al the available methods of each  tree configured in the Basic Scenario template
              return templateTrees
                ?.reduce((acc, current) => {
                  if (acc.length === 0) return current.impactMethodRefIds;
                  return [...acc?.filter((m: string) => current.impactMethodRefIds?.indexOf(m) !== -1)];
                }, [])
                ?.includes(impactMethod.refId as string);
            }),
        );
      }
    });
    return [...new Map(assessmentTemplates?.map((item) => [item['id'], item])).values()];
  }, [doUpdate, template.assessmentConfigurations, scenarioTemplates, trees, impactMethods]);

  const {
    fields: itemScenarioTemplateConfigurationFields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'itemScenarioTemplateConfiguration',
  });

  const { remove: removeParameter } = useFieldArray({
    control,
    name: 'parameters',
  });

  const {
    fields: assessmentConfigurationsFields,
    append: appendAssessmentConfiguration,
    remove: removeAssessmentConfiguration,
  } = useFieldArray({
    control,
    name: 'assessmentConfigurations',
  });

  const handleImportJson = async () => {
    const mapping = checkBomImportMapping(importJson, scenarioTemplates as BasicScenarioTemplate[]);
    if (mapping.length === 0) {
      await reset(JSON.parse(importJson));
      setImportModal(false);
    } else {
      console.log(mapping);
      setImportModal(true);
      setImportMapping(mapping);
    }
  };

  const createTemplate = (values) => {
    if (Object.keys(values).length === 0) return;

    const template: any = {
      ...values,
      id: !values.id ? undefined : values.id,
      type: 'BOM',
    };

    const parameters = template.parameters ?? [];
    const groups = template.groups ?? [];
    template.itemScenarioTemplateConfiguration = template.itemScenarioTemplateConfiguration.map((ist, index) => {
      // if a configuration already exists than we don't want to override it.
      //therefore we return it unchanged.

      const basicScenarioTemplate: BasicScenarioTemplate = {
        ...scenarioTemplates?.find((st) => st.id === ist.scenarioTemplateId),
      };

      if (ist.variableConfigurations) {
        return {
          ...basicScenarioTemplate,
          ...ist,
        };
      }

      if (Object.keys(basicScenarioTemplate).length === 0) return;

      let variableConfigurations = basicScenarioTemplate.variableConfigurations;
      let suffix = `:${ist.itemId}`;
      // If it's a specific configuration we use the parent id to identify it
      if (ist.parentId) {
        suffix = `:${ist.parentId}:${ist.itemId}`;
      }
      variableConfigurations = variableConfigurations?.map((vc) => {
        return {
          ...vc,
          parameterName: vc.parameterName ? `${vc.parameterName}${suffix}` : undefined,
        };
      });

      groups.push(...(basicScenarioTemplate.groups ?? []));

      parameters.push(
        ...(basicScenarioTemplate.parameters?.map((p: Parameter) => {
          const parameter = { ...p };
          if (p.parameterType === ParameterType[ParameterType.OPTION]) {
            parameter.options = p.options?.map((o) => {
              const option = { ...o };
              option.linkedParameters = o.linkedParameters?.map((lp) => {
                return {
                  ...lp,
                  parameterName: `${lp.parameterName}${suffix}`,
                };
              });
              return option;
            });
          }
          if (p.parameterType === ParameterType[ParameterType.FORMULA]) {
            (parameter as FormulaParameter).dependsOn = (p as FormulaParameter).dependsOn?.map((d) => {
              const dependency = { ...d };
              (parameter as FormulaParameter).formula = (parameter as FormulaParameter).formula?.replaceAll(
                dependency.parameterName as string,
                `${d.parameterName}${suffix}`,
              );
              dependency.parameterName = `${d.parameterName}${suffix}`;
              return dependency;
            });
          }
          return {
            ...parameter,
            item: suffix,
            parameterName: `${p.parameterName}${suffix}`,
            display: {
              ...p.display,
              item: suffix,
              formulaParameter: p.display?.formulaParameter ? `${p.display.formulaParameter}${suffix}` : undefined,
            },
          };
        }) as Parameter[]),
      );

      delete basicScenarioTemplate.parameters;

      return {
        ...ist,
        ...basicScenarioTemplate,
        variableConfigurations,
        assessmentConfigurations: basicScenarioTemplate.assessmentConfigurations,
        index,
      };
    });

    template.parameters = [...new Map(parameters?.map((item) => [item['parameterName'], item])).values()]?.map(
      (p: Parameter, index) => ({
        ...p,
        index,
      }),
    );

    template.groups = [...new Map(groups?.map((item) => [item['name'], item])).values()];
    console.log({});
    return template;
  };

  const handleFormSubmit = (values: BasicScenarioTemplate) => {
    const template = createTemplate(values);
    console.log({ template });
    actions.initScenarioTemplateAction({ template });
    navigate('./../customization');
  };

  const handleRemove = (index: number) => {
    const ist = template.itemScenarioTemplateConfiguration[index];
    let suffix = `:${ist.itemId}`;
    if (ist.parentId) {
      suffix = `:${ist.parentId}:${ist.itemId}`;
    }
    const parameters = template.parameters?.filter((p) => p.item === suffix);
    if (parameters?.length > 0) {
      //Need to remove them in a DESC order otherwise indexes are wrong
      parameters.sort((a, b) => b.index - a.index);
      parameters?.forEach((p) => {
        console.log('Removing parameter: ', p);
        removeParameter(p.index);
      });
    }

    remove(index);
  };

  const saveScenarioTemplate = async () => {
    console.log('Saving scenario template: ', getValues());
    const scenarioTemplate: any = await postScenarioTemplate({
      body: getValues(),
    });
    if (scenarioTemplate.error) {
      console.log(scenarioTemplate.error);
      toast.error('Failed to create scenario template');
    } else {
      toast.success('BOM Scenario template created successfully');
    }
  };

  return (
    <>
      <FormProvider {...methods}>
        <div className="shadow sm:overflow-hidden sm:rounded bg-white p-4 h-full">
          <form
            onSubmit={handleSubmit(handleFormSubmit, (error) => {
              console.error(error);
            })}
            className=""
          >
            <div className="my-4 flex justify-around gap-x-4">
              <input type="text" {...register('id')} hidden={true} />
              <InputText label={'Name'} type="text" {...register('name')} className="flex-1" />
              <InputText label={'Description'} type="text" {...register('description')} className="flex-1" />
            </div>

            <>
              <div className="mb-2 flex flex-col my-4 border rounded p-2">
                {assessmentConfigurationsFields.map((field: any, index: number) => {
                  //Customizing treeID registering

                  return (
                    <div key={field.id} className="py-2 flex-1 flex gap-2 justify-around items-center">
                      <Select
                        control={control}
                        name={`assessmentConfigurations.${index}.assessmentType`}
                        options={['LCA', 'LCC', 'SLCA', 'CE'].map((type) => ({
                          value: type,
                          label: type,
                        }))}
                        placeholder="Choose option"
                        label={'Assessment type'}
                        onChange={() => setDoUpdate(!doUpdate)}
                        error={getInputError(errors, 'assessmentTypes')}
                        className={'flex-1'}
                      />
                      <Select
                        control={control}
                        name={`assessmentConfigurations.${index}.impactMethodId`}
                        options={impactMethods?.map((p) => ({
                          value: p.id as string,
                          label: p.name as string,
                        }))}
                        onChange={(id) => {
                          setValue(
                            `assessmentConfigurations.${index}.impactMethodRefId`,
                            impactMethods?.find((m) => m.id === id)?.refId,
                          );
                          setDoUpdate(!doUpdate);
                        }}
                        label={'Impact Method'}
                        className="flex-1"
                      />
                      <Button type="button" variant="none" onClick={() => removeAssessmentConfiguration(index)}>
                        <HiOutlineTrash className="h-6 w-6 text-red-500" />
                      </Button>
                    </div>
                  );
                })}
                <Button
                  variant="none"
                  type="button"
                  className="flex-1 text-blue-600 my-2 border rounded border-blue-600"
                  onClick={() =>
                    appendAssessmentConfiguration({
                      assessmentType: '',
                      impactMethodId: '',
                    })
                  }
                >
                  <HiPlusCircle className="w-6 h-6 mr-4" />
                  Add assessment type
                </Button>
              </div>
            </>

            {boms && (
              <Select
                control={control}
                name={'bomId'}
                className="bg-white max-w-md"
                placeholder="Select the BOM"
                options={boms.map((m) => ({
                  label: m.description,
                  value: m.id,
                }))}
              />
            )}

            <div className="mb-6 h-full">
              {template.bomId ? (
                <BillOfMaterial
                  bom={boms?.find((b: Bom) => b.id === template.bomId) as Bom}
                  nodeConfiguration={{
                    nodeContent: (item) => {
                      let specific = true;
                      let config = template.itemScenarioTemplateConfiguration?.find(
                        (i: ItemScenarioTemplatePair) =>
                          i.parentId && i.itemId === item.id && i.parentId === item.parentId,
                      );

                      if (!config) {
                        config = template.itemScenarioTemplateConfiguration?.find(
                          (i: ItemScenarioTemplatePair) => !i.parentId && i.itemId === item.id,
                        );
                        specific = false;
                      }
                      if (!config) {
                        return `${item.description}:${item.id}`;
                      } else {
                        // console.log("Item: ", item, config)
                        if (config.scenarioTemplateId) {
                          return (
                            <span className={specific ? 'bg-blue-200' : 'bg-green-200'}>
                              {item.description}:{item.id}: success
                            </span>
                          );
                        } else {
                          return (
                            <span className="bg-red-200">
                              {item.description}:{item.id}: error
                            </span>
                          );
                        }
                      }
                    },
                  }}
                  itemContent={(item, selectedItemId) => {
                    //Searching first for an individual config (for the specific item)
                    let config = template.itemScenarioTemplateConfiguration?.find(
                      (i) => i.itemId === item.id && i.parentId === item.parentId,
                    );
                    if (!config) {
                      //if an individual config does not exist I search for a specific one
                      config = template.itemScenarioTemplateConfiguration?.find(
                        (i) => i.itemId === item.id && !i.parentId,
                      );
                    }
                    if (config) {
                      // console.log({config}, itemScenarioTemplateConfigurationFields, item)
                      const { index, id } = itemScenarioTemplateConfigurationFields
                        .map((f, index) => ({
                          ...f,
                          index,
                        }))
                        .find(
                          (f: ItemScenarioTemplatePair) => f.itemId === config.itemId && f.parentId === config.parentId,
                        ) as any;
                      // console.log({field})
                      return (
                        <div>
                          <div
                            key={id}
                            className="p-6 border rounded border-blue-100 shadow flex flex-row gap-4 justify-around"
                          >
                            <Select
                              name={`itemScenarioTemplateConfiguration.${index}.scenarioTemplateId`}
                              control={control}
                              onChange={() => setDoUpdate(!doUpdate)}
                              className="bg-white max-w-md"
                              placeholder={'Select a scenario template'}
                              options={filteredScenarioTemplates?.map((m) => ({
                                label: m.name,
                                value: m.id,
                              }))}
                            />
                            <Button type="button" className="bg-blue-300" onClick={() => handleRemove(index)}>
                              <HiOutlineTrash className="h-4 w-4" />
                            </Button>
                          </div>
                          {!config.parentId && (
                            <Button
                              type="button"
                              className="w-full bg-blue-300 my-2"
                              onClick={() =>
                                append({
                                  parentId: item.parentId,
                                  itemId: item.id,
                                  scenarioTemplateId: '',
                                })
                              }
                            >
                              {item.id}:Link a scenario template only to this item
                            </Button>
                          )}
                        </div>
                      );
                    } else {
                      return (
                        <>
                          <Button
                            type="button"
                            className="w-full bg-blue-300 my-2"
                            onClick={() =>
                              append({
                                parentId: item.parentId,
                                itemId: item.id,
                                scenarioTemplateId: '',
                              })
                            }
                          >
                            {item.id}:Link a scenario template only to this item
                          </Button>
                          <Button
                            type="button"
                            className="w-full bg-blue-300 my-2"
                            onClick={() =>
                              append({
                                itemId: item.id,
                                scenarioTemplateId: '',
                              })
                            }
                          >
                            {item.id}:Link a scenario template to all items of this type
                          </Button>
                        </>
                      );
                    }
                  }}
                />
              ) : null}
            </div>

            <div className="mt-6">
              <Button type="submit" className="float-right">
                Add Customization
              </Button>
              {/*<Button className="float-right mr-2" onClick={saveScenarioTemplate}>*/}
              {/*    Save*/}
              {/*</Button>*/}

              <div className="flex">
                <CopyToClipboard
                  text={JSON.stringify(createTemplate(getValues()))}
                  onCopy={() => toast.success('Copied to clipboard')}
                >
                  <Button variant="none" className="text-sm">
                    <HiDocumentDuplicate className="w-6 h-6" /> Copy as JSON
                  </Button>
                </CopyToClipboard>

                <Button
                  variant="none"
                  className="border-l-2 rounded-none text-sm"
                  onClick={() => setImportJson(!importJson)}
                >
                  <HiClipboardDocument className="w-6 h-8" /> Import from JSON
                </Button>
              </div>
              {importJson ? (
                <div>
                  <TextArea rows={10} onChange={setImportJson} />
                  <Button onClick={handleImportJson}>Import</Button>
                </div>
              ) : null}
            </div>
          </form>
        </div>
      </FormProvider>
      <Modal size="lg" open={importModal} handleOpen={handleImportJson}>
        <div className="my-12">
          <ScenarioTemplateMapping
            importMapping={importMapping}
            scenarioTemplates={scenarioTemplates as BasicScenarioTemplate[]}
            onImportMappingChange={setImportMapping}
            importJson={importJson}
            onImportJsonChange={setImportJson}
          />
        </div>
      </Modal>
    </>
  );
}

export default BomScenarioTemplateForm;
