import React, { useEffect, useMemo } from 'react';
import classNames from '../../../utils/classNames';
import { CustomizationParameterForm } from './CustomizationParameterForm';
import useRenderCounter from '../../../hooks/useRenderCounter';
import { groupBy } from '../../../utils/groupBy';
import { IndexedParameter } from './CustomizationTemplateForm';
import { useFieldArray, useFormContext } from 'react-hook-form';

type CustomizationTabFormProps = {
  description: string;
  currentTab: string;
  parameters: IndexedParameter[];
  onFormChange: () => void;
};

function CustomizationTabForm(props: CustomizationTabFormProps) {
  const { description, currentTab, parameters, onFormChange } = props;

  const renderCounter = useRenderCounter();

  const groupedParameters = useMemo(() => {
    const params = parameters.filter((p) => !p.display?.formulaParameter);
    return groupBy(params, (x) => x?.display?.group) ?? [];
  }, [parameters]);
  const {
    formState: { errors },
    control,
    setFocus,
    watch,
  } = useFormContext();

  //IMPORTANT: Do no delete otherwise it does not register the parameters in the form //
  const {
    fields: parameterFields,
    append: appendParameter,
    remove,
  } = useFieldArray({
    control,
    name: 'parameters',
  });

  const formParameters = watch('parameters');

  useEffect(() => {
    const parameterIndexes = errors.parameters?.map((ep, index) => (ep ? index : undefined)).filter(Boolean);
    const errorParameters = formParameters?.filter((p) => parameterIndexes?.includes(p.index));
    if (errorParameters) {
      setFocus(`parameters.${errorParameters[0]?.index}.value`);
    }
  }, [currentTab]);

  return (
    <div className="bg-white rounded-b mt-4">
      {renderCounter}
      {description && (
        <div className="p-2 border-2 text-lg m-2 rounded">
          <div dangerouslySetInnerHTML={{ __html: description as string }} />
        </div>
      )}
      {Object.entries(groupedParameters).map(([group, params]) => {
        return (
          <div
            key={group}
            className={classNames(group !== 'undefined' ? 'border-2 pb-2 rounded m-2' : 'm-3', 'relative')}
          >
            {group !== 'undefined' && (
              <h1 className="w-full leading-border-text absolute -top-3 ">
                <span className="text-base font-semibold font-medium ml-12 bg-white px-2">{group}</span>
              </h1>
            )}
            <div>
              {params.map((p: IndexedParameter) => {
                return <CustomizationParameterForm key={p.parameterName} onFormChange={onFormChange} {...p} />;
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default CustomizationTabForm;
