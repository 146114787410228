import { useFormContext } from 'react-hook-form';
import { InputText, Select } from '../../../../components/Input';
import React, { useMemo, useState } from 'react';
import { VariableCustomization } from './index';
import Modal from '../../../../components/Modal/Modal';
import { HiArrowsPointingOut } from 'react-icons/hi2';
import FlowSwitchForm from './FlowSwitchForm';
import classNames from '../../../../utils/classNames';
import DisplayManagement from '../BasicScenarioTemplate/DisplayManagement';
import { DisplayConfiguration } from '../../../../api/scenarioTemplate/scenarioTemplateApi';

type VariableFormProps = {
  prefix: string;
  includeDisplay?: boolean;
  displayConfigurations?: DisplayConfiguration[];
  expanded?: boolean;
  className?: string;
  color?: string;
};

function BasicParameterForm(props: VariableFormProps) {
  const { prefix, expanded, includeDisplay, color, displayConfigurations, className } = props;

  const [open, setOpen] = useState(false);

  const { register, control, watch, setValue } = useFormContext();

  const parameterType = watch(`${prefix}.parameterType`);
  const type = watch(`${prefix}.type`);

  const Customization = useMemo(() => VariableCustomization[parameterType], [parameterType, type]);

  const render = useMemo(() => {
    return (
      <>
        <InputText {...register(`${prefix}.alias`)} label={'Alias'} className="my-3" />
        <InputText {...register(`${prefix}.value`)} label={'Default Value'} className="my-3" />
        <InputText {...register(`${prefix}.description`)} label={'Description'} className="my-3" />
        <InputText {...register(`${prefix}.editable`)} label={'Editable'} className="my-3" />
        <InputText {...register(`${prefix}.hidden`)} label={'Hidden'} className="my-3" />
        <InputText {...register(`${prefix}.unitOfMeasure`)} label={'Unit'} className="my-3" />
        {/*<InputText {...register(`${prefix}.type`)} label={"Type"} className="my-3"/>*/}
        <Select
          control={control}
          name={`${prefix}.type`}
          className="my-3"
          label={'Type'}
          options={['AMOUNT_CHANGE', 'FLOW_SWITCH', 'ADDITIONAL_PARAMETER'].map((o) => ({ label: o, value: o }))}
        />

        <Select
          control={control}
          name={`${prefix}.parameterType`}
          className="my-3"
          disabled={type === 'FLOW_SWITCH'}
          label={'Parameter type'}
          options={['FORMULA', 'OPTION', 'NUMERIC', 'STRING', 'REMOTE', 'LIST'].map((o) => ({ label: o, value: o }))}
        />
        {type === 'FLOW_SWITCH' && <FlowSwitchForm prefix={prefix} />}
        {Customization ? <Customization {...props} requiresDefault={type === 'FLOW_SWITCH'} /> : null}
        {includeDisplay ? (
          <div className="p-4 border border-blue-500">
            <DisplayManagement
              parameterPrefix={`${prefix}.display`}
              displayConfigurations={displayConfigurations ? displayConfigurations : []}
            />
          </div>
        ) : null}
      </>
    );
  }, [prefix, parameterType]);

  return (
    <div
      className={classNames(className ? className : '', 'w-full')}
      style={{ border: color ? `2px solid ${color}` : '', borderRadius: '5px' }}
    >
      <div className={'flex bg-transparent'}>
        <InputText
          label={'Parameter name'}
          {...register(`${prefix}.parameterName`)}
          className="flex-1 hover:cursor-pointer"
          addonBefore={<HiArrowsPointingOut className="w-6 h-6 mt-2 " onClick={() => setOpen(!open)} />}
          addonAfter={(() => {
            switch (parameterType) {
              case 'OPTION':
                return <i>otp</i>;
              case 'NUMERIC':
                return <i>123</i>;
              case 'FORMULA':
                return <i>a * b</i>;
              case 'STRING':
                return <i>aBc</i>;
              case 'REMOTE':
                return <i>remote</i>;
            }
          })()}
        />
      </div>
      {expanded ? (
        <>{render}</>
      ) : (
        <Modal open={open} handleOpen={setOpen}>
          {render}
        </Modal>
      )}
    </div>
  );
}

export default BasicParameterForm;
