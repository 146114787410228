import { get } from '../../../utils/objectUtils';

export const getInputError = (errors, name) => {
  let errorMessage = undefined;
  console.log({ errors }, name);
  const values = get(errors, name).filter(Boolean);

  if (values.length) {
    let error = values[0];
    errorMessage = error.message;
    return errorMessage;
  }
  return null;
};
