import { emptyProjectApi as api } from './emptyApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAllTag: build.query<GetAllTagApiResponse, GetAllTagApiArg>({
      query: (queryArg) => ({
        url: `/v1/tag/`,
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'] },
      }),
    }),
    postTag: build.mutation<PostTagApiResponse, PostTagApiArg>({
      query: (queryArg) => ({
        url: `/v1/tag/`,
        method: 'POST',
        body: queryArg.tag,
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'] },
      }),
    }),
    patchTag: build.mutation<PatchTagApiResponse, PatchTagApiArg>({
      query: (queryArg) => ({
        url: `/v1/tag/`,
        method: 'PATCH',
        body: queryArg.tag,
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'] },
      }),
    }),
    getAllScenario: build.query<GetAllScenarioApiResponse, GetAllScenarioApiArg>({
      query: (queryArg) => ({
        url: `/v1/scenario/`,
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'] },
        params: {
          projectId: queryArg.projectId,
          scenarioName: queryArg.scenarioName,
          scenarioDescription: queryArg.scenarioDescription,
          page: queryArg.page,
          size: queryArg.size,
        },
      }),
    }),
    postScenario: build.mutation<PostScenarioApiResponse, PostScenarioApiArg>({
      query: (queryArg) => ({
        url: `/v1/scenario/`,
        method: 'POST',
        body: queryArg.scenarioDto,
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'] },
      }),
    }),
    patchScenario: build.mutation<PatchScenarioApiResponse, PatchScenarioApiArg>({
      query: (queryArg) => ({
        url: `/v1/scenario/`,
        method: 'PATCH',
        body: queryArg.scenarioDto,
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'] },
      }),
    }),
    getAllProject: build.query<GetAllProjectApiResponse, GetAllProjectApiArg>({
      query: (queryArg) => ({
        url: `/v1/project/`,
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'] },
        params: {
          projectName: queryArg.projectName,
          projectDescription: queryArg.projectDescription,
          page: queryArg.page,
          size: queryArg.size,
        },
      }),
    }),
    postProject: build.mutation<PostProjectApiResponse, PostProjectApiArg>({
      query: (queryArg) => ({
        url: `/v1/project/`,
        method: 'POST',
        body: queryArg.projectDto,
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'] },
      }),
    }),
    patchProject: build.mutation<PatchProjectApiResponse, PatchProjectApiArg>({
      query: (queryArg) => ({
        url: `/v1/project/`,
        method: 'PATCH',
        body: queryArg.projectDto,
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'] },
      }),
    }),
    getAllPhase: build.query<GetAllPhaseApiResponse, GetAllPhaseApiArg>({
      query: (queryArg) => ({
        url: `/v1/phase/`,
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'] },
      }),
    }),
    postPhase: build.mutation<PostPhaseApiResponse, PostPhaseApiArg>({
      query: (queryArg) => ({
        url: `/v1/phase/`,
        method: 'POST',
        body: queryArg.phaseDto,
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'] },
      }),
    }),
    postPhase1: build.mutation<PostPhase1ApiResponse, PostPhase1ApiArg>({
      query: (queryArg) => ({
        url: `/v1/auth/`,
        method: 'POST',
        body: queryArg.authDto,
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'] },
      }),
    }),
    getTag: build.query<GetTagApiResponse, GetTagApiArg>({
      query: (queryArg) => ({
        url: `/v1/tag/${queryArg.tagId}`,
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'] },
      }),
    }),
    deleteTag: build.mutation<DeleteTagApiResponse, DeleteTagApiArg>({
      query: (queryArg) => ({
        url: `/v1/tag/${queryArg.tagId}`,
        method: 'DELETE',
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'] },
      }),
    }),
    getScenario: build.query<GetScenarioApiResponse, GetScenarioApiArg>({
      query: (queryArg) => ({
        url: `/v1/scenario/${queryArg.scenarioId}`,
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'] },
      }),
    }),
    deleteScenario: build.mutation<DeleteScenarioApiResponse, DeleteScenarioApiArg>({
      query: (queryArg) => ({
        url: `/v1/scenario/${queryArg.scenarioId}`,
        method: 'DELETE',
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'] },
      }),
    }),
    getProject: build.query<GetProjectApiResponse, GetProjectApiArg>({
      query: (queryArg) => ({
        url: `/v1/project/${queryArg.projectId}`,
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'] },
      }),
    }),
    deleteProject: build.mutation<DeleteProjectApiResponse, DeleteProjectApiArg>({
      query: (queryArg) => ({
        url: `/v1/project/${queryArg.projectId}`,
        method: 'DELETE',
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'] },
      }),
    }),
    getPhase: build.query<GetPhaseApiResponse, GetPhaseApiArg>({
      query: (queryArg) => ({
        url: `/v1/phase/${queryArg.phaseId}`,
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'] },
      }),
    }),
    deletePhase: build.mutation<DeletePhaseApiResponse, DeletePhaseApiArg>({
      query: (queryArg) => ({
        url: `/v1/phase/${queryArg.phaseId}`,
        method: 'DELETE',
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'] },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as projectApi };
export type GetAllTagApiResponse = /** status 200 OK */ Tag[];
export type GetAllTagApiArg = {
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
};
export type PostTagApiResponse = /** status 200 OK */ Tag;
export type PostTagApiArg = {
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
  tag: Tag;
};
export type PatchTagApiResponse = /** status 200 OK */ Tag;
export type PatchTagApiArg = {
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
  tag: Tag;
};
export type GetAllScenarioApiResponse = /** status 200 OK */ PageScenarioDto;
export type GetAllScenarioApiArg = {
  projectId?: string;
  scenarioName?: string;
  scenarioDescription?: string;
  page?: number;
  size?: number;
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
};
export type PostScenarioApiResponse = /** status 200 OK */ ScenarioDto;
export type PostScenarioApiArg = {
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
  scenarioDto: ScenarioDto;
};
export type PatchScenarioApiResponse = /** status 200 OK */ ScenarioDto;
export type PatchScenarioApiArg = {
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
  scenarioDto: ScenarioDto;
};
export type GetAllProjectApiResponse = /** status 200 OK */ PageProjectDto;
export type GetAllProjectApiArg = {
  projectName?: string;
  projectDescription?: string;
  page?: number;
  size?: number;
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
};
export type PostProjectApiResponse = /** status 200 OK */ ProjectDto;
export type PostProjectApiArg = {
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
  projectDto: ProjectDto;
};
export type PatchProjectApiResponse = /** status 200 OK */ ProjectDto;
export type PatchProjectApiArg = {
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
  projectDto: ProjectDto;
};
export type GetAllPhaseApiResponse = /** status 200 OK */ PhaseDto[];
export type GetAllPhaseApiArg = {
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
};
export type PostPhaseApiResponse = /** status 200 OK */ PhaseDto;
export type PostPhaseApiArg = {
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
  phaseDto: PhaseDto;
};
export type PostPhase1ApiResponse = /** status 200 OK */ AuthResponseDto;
export type PostPhase1ApiArg = {
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
  authDto: AuthDto;
};
export type GetTagApiResponse = /** status 200 OK */ Tag;
export type GetTagApiArg = {
  tagId: string;
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
};
export type DeleteTagApiResponse = /** status 200 OK */ GenericResponse;
export type DeleteTagApiArg = {
  tagId: string;
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
};
export type GetScenarioApiResponse = /** status 200 OK */ ScenarioDto;
export type GetScenarioApiArg = {
  scenarioId: string;
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
};
export type DeleteScenarioApiResponse = /** status 200 OK */ GenericResponse;
export type DeleteScenarioApiArg = {
  scenarioId: string;
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
};
export type GetProjectApiResponse = /** status 200 OK */ ProjectDto;
export type GetProjectApiArg = {
  projectId: string;
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
};
export type DeleteProjectApiResponse = /** status 200 OK */ GenericResponse;
export type DeleteProjectApiArg = {
  projectId: string;
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
};
export type GetPhaseApiResponse = /** status 200 OK */ PhaseDto;
export type GetPhaseApiArg = {
  phaseId: string;
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
};
export type DeletePhaseApiResponse = /** status 200 OK */ GenericResponse;
export type DeletePhaseApiArg = {
  phaseId: string;
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
};
export type Tag = {
  created?: string;
  lastUpdated?: string;
  createdBy?: string;
  realm?: string;
  id?: string;
  name?: string;
  description?: string;
  color?: string;
  tagRestriction?: 'PROJECT_UNIQUE' | 'REALM_UNIQUE' | 'UNRESTRICTED';
};
export type ScenarioDto = {
  id?: string;
  name?: string;
  description?: string;
  scenarioTemplateId?: string;
  projectId?: string;
  tags?: Tag[];
  created?: string;
  lastUpdated?: string;
  createdBy?: string;
  aiBased?: string;
  sustainabilityPoints?: string;
  sustainabilityPercentage?: number;
  profitPoints?: string;
  profitPercentage?: number;
};
export type SortObject = {
  empty?: boolean;
  sorted?: boolean;
  unsorted?: boolean;
};
export type PageableObject = {
  offset?: number;
  sort?: SortObject;
  pageNumber?: number;
  pageSize?: number;
  paged?: boolean;
  unpaged?: boolean;
};
export type PageScenarioDto = {
  totalPages?: number;
  totalElements?: number;
  size?: number;
  content?: ScenarioDto[];
  number?: number;
  sort?: SortObject;
  pageable?: PageableObject;
  first?: boolean;
  last?: boolean;
  numberOfElements?: number;
  empty?: boolean;
};
export type ProjectDto = {
  id?: string;
  name?: string;
  description?: string;
  scenarioCount?: number;
  created?: string;
  lastUpdated?: string;
  createdBy?: string;
};
export type PageProjectDto = {
  totalPages?: number;
  totalElements?: number;
  size?: number;
  content?: ProjectDto[];
  number?: number;
  sort?: SortObject;
  pageable?: PageableObject;
  first?: boolean;
  last?: boolean;
  numberOfElements?: number;
  empty?: boolean;
};
export type PhaseDto = {
  id?: string;
  name?: string;
  description?: string;
  color?: string;
  created?: string;
  lastUpdated?: string;
  createdBy?: string;
  displayOrder?: number;
};
export type UserDto = {
  id?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  imageUrl?: string;
  role?: string;
};
export type AuthResponseDto = {
  token?: string;
  userDto?: UserDto;
};
export type AuthDto = {
  username?: string;
  password?: string;
};
export type GenericResponse = {
  message?: string;
  details?: string;
  data?: object;
};
export const {
  useGetAllTagQuery,
  usePostTagMutation,
  usePatchTagMutation,
  useGetAllScenarioQuery,
  usePostScenarioMutation,
  usePatchScenarioMutation,
  useGetAllProjectQuery,
  usePostProjectMutation,
  usePatchProjectMutation,
  useGetAllPhaseQuery,
  usePostPhaseMutation,
  usePostPhase1Mutation,
  useGetTagQuery,
  useDeleteTagMutation,
  useGetScenarioQuery,
  useDeleteScenarioMutation,
  useGetProjectQuery,
  useDeleteProjectMutation,
  useGetPhaseQuery,
  useDeletePhaseMutation,
} = injectedRtkApi;
