import React from 'react';
import { RefCallBack } from 'react-hook-form';
import classNames from '../../utils/classNames';
import useRenderCounter from '../../hooks/useRenderCounter';

export type InputProps = {
  className?: string;
  error?: string;
  value?: string | number | undefined;
  onChange?: (value: any) => void;
  label?: string;
  disabled?: boolean;
  description?: string;
  id?: string;
  name?: string;
  key?: string;
  inputRef?: RefCallBack;
  hidden?: boolean;
};

export const Checkbox = React.forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const { className, value, name, key, onChange, label, defaultValue, error, description, id, disabled, hidden } =
    props;

  const renderCounter = useRenderCounter();

  return (
    <div
      className={classNames(
        className ? className : '',
        hidden ? 'hidden' : '',
        'relative border rounded border-gray-300',
      )}
      style={{ backgroundColor: '#F8FAFC' }}
    >
      {renderCounter}
      {label && (
        <label className="whitespace-nowrap left-1 bg-gradient-to-t from-[#F8FAFC] from-65%  via-transparent via-65% to-transparent absolute -top-[8px] z-10 px-1 text-xs text-gray-700 rounded">
          {label}
        </label>
      )}
      <div className={`p-2.5 px- focus-visible:outline-none focus:border-blue-500 ${error ? 'border-red-500' : ''}`}>
        <input
          key={key}
          type={'checkbox'}
          disabled={disabled}
          name={name}
          ref={ref}
          id={id}
          className={classNames(disabled ? 'border-gray-200 text-gray-500 bg-gray-50' : '', 'text-center')}
          onChange={onChange}
          value={value}
        />
      </div>
      {error && <p className="text-sm text-red-500">{error}</p>}
      {description && <p className="mt-2 text-sm text-gray-500">{description}</p>}
    </div>
  );
});
