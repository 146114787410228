import { emptyScenarioTemplateApi as api } from './emptyApi';

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAllScenarioTemplate: build.query<GetAllScenarioTemplateApiResponse, GetAllScenarioTemplateApiArg>({
      query: (queryArg) => ({
        url: `/v1/scenario-template/`,
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'] },
        params: { type: queryArg['type'] },
      }),
    }),
    postScenarioTemplate: build.mutation<PostScenarioTemplateApiResponse, PostScenarioTemplateApiArg>({
      query: (queryArg) => ({
        url: `/v1/scenario-template/`,
        method: 'POST',
        body: queryArg.body,
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'] },
      }),
    }),
    getScenarioTemplate: build.query<GetScenarioTemplateApiResponse, GetScenarioTemplateApiArg>({
      query: (queryArg) => ({
        url: `/v1/scenario-template/${queryArg.scenarioTemplateId}`,
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'] },
      }),
    }),
    deleteScenarioTemplate: build.mutation<DeleteScenarioTemplateApiResponse, DeleteScenarioTemplateApiArg>({
      query: (queryArg) => ({
        url: `/v1/scenario-template/${queryArg.scenarioTemplateId}`,
        method: 'DELETE',
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'] },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as scenarioTemplateApi };
export type GetAllScenarioTemplateApiResponse = /** status 200 OK */ (BasicScenarioTemplate | BomScenarioTemplate)[];
export type GetAllScenarioTemplateApiArg = {
  type?: string;
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
};
export type PostScenarioTemplateApiResponse = /** status 200 OK */ BasicScenarioTemplate | BomScenarioTemplate;
export type PostScenarioTemplateApiArg = {
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
  body: BasicScenarioTemplate | BomScenarioTemplate;
};
export type GetScenarioTemplateApiResponse = /** status 200 OK */ BasicScenarioTemplate | BomScenarioTemplate;
export type GetScenarioTemplateApiArg = {
  scenarioTemplateId: string;
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
};
export type DeleteScenarioTemplateApiResponse = /** status 200 OK */ string;
export type DeleteScenarioTemplateApiArg = {
  scenarioTemplateId: string;
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
};
export type Tab = {
  name?: string;
  description?: string;
};
export type DisplayConfiguration = {
  phaseName?: string;
  customizationSpaces?: string[];
  tabs?: Tab[];
  treeIds?: string[];
  phaseId?: string;
};
export type PhaseConfiguration = {
  treeId?: string;
  phaseId?: string;
  phaseName?: string;
  treeName?: string;
  customizationSpaces?: string[];
  displayOrder?: number;
  tabs?: Tab[];
};
export type AssessmentConfiguration = {
  impactMethodId?: string;
  impactMethodRefId?: string;
  assessmentType?: 'LCA' | 'LCC' | 'SLCA' | 'CE';
  phaseConfigurations?: PhaseConfiguration[];
};
export type LinkedParameter = {
  parameterName: string;
  value: string;
};
export type Option = {
  label: string;
  value: string;
  isDefault?: boolean;
  linkedParameters?: LinkedParameter[];
};
export type Validation = {
  type?: string;
  value?: number;
  message?: string;
};
export type DisplayOption = {
  phaseId?: string;
  tab?: string;
  group?: string;
  item?: string;
  formulaParameter?: string;
};
export type Parameter = {
  alias?: string;
  value?: string;
  item?: string;
  description?: string;
  tooltip?: string;
  parameterName: string;
  options?: Option[];
  validations?: Validation[];
  unitOfMeasure?: string;
  type: 'AMOUNT_CHANGE' | 'FLOW_SWITCH' | 'ADDITIONAL_PARAMETER';
  parameterType: 'NUMERIC' | 'STRING' | 'OPTION' | 'FORMULA' | 'REMOTE' | 'LIST';
  hidden?: string;
  editable?: string;
  display?: DisplayOption;
};
export type FlowSwitchParameter = Parameter & {
  parentRefId?: string;
  rootModeledProcessRefId?: string;
};
export type Dependency = {
  parameterName?: string;
};
export type FormulaParameter = Parameter & {
  formula?: string;
  dependsOn?: Dependency[];
  accuracy?: number;
};
export type ItemProperty = {
  name?: string;
};
export type ListParameter = Parameter & {
  listItem?: ItemProperty[];
};
export type RemoteParameter = Parameter & {
  flowId?: string;
};
export type Group = {
  name?: string;
};
export type ScenarioTemplate = {
  created?: string;
  lastUpdated?: string;
  createdBy?: string;
  realm?: string;
  id?: string;
  name?: string;
  description?: string;
  type?: string;
  displayConfigurations?: DisplayConfiguration[];
  assessmentConfigurations?: AssessmentConfiguration[];
  parameters?: (FlowSwitchParameter | FormulaParameter | ListParameter | RemoteParameter)[];
  groups?: Group[];
  defaultImpactMethod?: string;
  assessmentTypes?: ('LCA' | 'LCC' | 'SLCA' | 'CE')[];
};
export type VariableConfiguration = {
  name?: string;
  value?: number;
  parameterName?: string;
};
export type BasicScenarioTemplate = ScenarioTemplate & {
  variableConfigurations?: VariableConfiguration[];
  phaseConfigurations?: PhaseConfiguration[];
};
export type ItemScenarioTemplatePair = {
  itemId?: string;
  parentId?: string;
  index?: number;
  scenarioTemplateId?: string;
  variableConfigurations?: VariableConfiguration[];
  assessmentConfigurations?: AssessmentConfiguration[];
};
export type BomScenarioTemplate = ScenarioTemplate & {
  bomId?: string;
  itemScenarioTemplateConfiguration?: ItemScenarioTemplatePair[];
};
export const {
  useGetAllScenarioTemplateQuery,
  usePostScenarioTemplateMutation,
  useGetScenarioTemplateQuery,
  useDeleteScenarioTemplateMutation,
} = injectedRtkApi;
