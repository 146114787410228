import { useFormContext } from 'react-hook-form';
import { Parameter } from '../../../api/customizationSpace/customizationSpaceApi';
import { ParameterType } from '../../../api/customizationSpace/types/ParameterType';
import Tooltip from '../../../components/Tooltip/Tooltip';
import { HiQuestionMarkCircle } from 'react-icons/hi2';
import classNames from '../../../utils/classNames';
import React, { Suspense, useMemo } from 'react';
import useRenderCounter from './../../../hooks/useRenderCounter';
import { useResizeDetector } from 'react-resize-detector';
import { evaluateWithScope, ParameterInput } from './ParameterInput';
import { IndexedParameter } from './CustomizationTemplateForm';
import { RemoteInput } from './RemoteInput';
import { InputList } from '../../../components/Input/InputList';
import { ItemProperty, ListParameter } from '../../../api/scenarioTemplate/scenarioTemplateApi';
import { InputText } from '../../../components/Input';
import { Spinner } from '../../../components/Elements';

const FormulaInput = React.lazy(() => import('./FormulaInput'));

export function evaluateScriptWithScope(script: string | undefined, parameters: Parameter[]) {
  if (!script) {
    return true;
  }

  const localScope = parameters?.reduce((accumulator, current) => {
    return {
      ...accumulator,
      [current.parameterName]: current.value,
    };
  }, {});
  let result = false;
  try {
    result = evaluateWithScope(localScope, script);
  } catch (e) {
    console.error(e, 'with local scope: ', localScope, ' and script: ', script);
  }
  return result;
}

export const CustomizationParameterForm = (props: IndexedParameter) => {
  const { alias, tooltip, parameterType, unitOfMeasure, hidden } = props;

  const { register, control, watch, formState, getValues } = useFormContext();

  const renderCounter = useRenderCounter();
  const { width, ref } = useResizeDetector();
  const parameters = watch('parameters');
  const value = watch(`parameters.${props.index}.value`);

  const hide = useMemo(
    () => (hidden ? evaluateScriptWithScope(hidden, getValues().parameters) : false),
    [hidden, formState],
  );

  return (
    <>
      <div ref={ref} className={classNames(hide ? 'hidden' : 'block', 'bg-white px-4 p-1 xs:p-4')}>
        <div className="grid grid-cols-3 gap-6">
          {width && width > 768 ? (
            <div className="col-span-1 flex items-center">
              <h3 className=" text-base font-semibold leading-6 text-gray-900">
                {alias} {unitOfMeasure ? `[${unitOfMeasure}]` : ''}
                {tooltip && (
                  <Tooltip className="ml-2" content={tooltip as string}>
                    <HiQuestionMarkCircle className="h-6 w-6" />
                  </Tooltip>
                )}
              </h3>
              {/*<p className="mt-1 text-sm text-gray-500">{props.index} {renderCounter}</p>*/}
            </div>
          ) : undefined}
          <div className={classNames(width && width > 768 ? 'col-span-2' : 'col-span-3', 'mt-2')}>
            {parameterType == ParameterType[ParameterType.FORMULA] && (
              <Suspense fallback={<Spinner size={'h-8 w-8  '} />}>
                <FormulaInput
                  register={register}
                  {...props}
                  label={width && width < 768 ? alias : ''}
                  parameters={parameters}
                />
              </Suspense>
            )}
            {parameterType == ParameterType[ParameterType.REMOTE] && <RemoteInput {...props} parameters={parameters} />}
            {[
              ParameterType[ParameterType.OPTION],
              ParameterType[ParameterType.NUMERIC],
              ParameterType[ParameterType.STRING],
            ].includes(parameterType) && (
              <ParameterInput
                {...props}
                hidden={hide}
                label={width && width < 768 ? `${alias} ${unitOfMeasure ? `[${unitOfMeasure}]` : ''}` : ''}
              />
            )}
            {[ParameterType[ParameterType.LIST]].includes(parameterType) && (
              <InputList
                control={control}
                name={`parameters.${props.index}.value`}
                {...props}
                item={(props as ListParameter).listItem?.reduce((acc, current: ItemProperty) => ({
                  ...acc,
                  [current.name as string]: '',
                }))}
                hidden={hide}
                label={width && width < 768 ? `${alias} ${unitOfMeasure ? `[${unitOfMeasure}]` : ''}` : ''}
                renderItem={(field, index, remove, append) => {
                  return (
                    <div className={'col-span-12 m-1 border border-blue-300 p-2 rounded text-center'}>
                      <p className="self-center font-bold">Item {index + 1}</p>
                      {(props as ListParameter).listItem?.map((property: ItemProperty) => {
                        return (
                          <InputText
                            className="my-1"
                            {...register(`parameters.${props.index}.value.${index}.${property.name}`)}
                            label={property.name}
                          />
                        );
                      })}
                    </div>
                  );
                }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
