import { emptyLightweightCalculationEngineApi as api } from './emptyApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    calculateBomCustomizedScenario: build.mutation<
      CalculateBomCustomizedScenarioApiResponse,
      CalculateBomCustomizedScenarioApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/bom/calculate-scenario`,
        method: 'POST',
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'] },
        params: { scenarioId: queryArg.scenarioId, shallow: queryArg.shallow },
      }),
    }),
    calculateBomCustomizedScenarioWithPayload: build.mutation<
      CalculateBomCustomizedScenarioWithPayloadApiResponse,
      CalculateBomCustomizedScenarioWithPayloadApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/bom/calculate-scenario-with-payload`,
        method: 'POST',
        body: queryArg.customizedBomScenarioDto,
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'] },
        params: { shallow: queryArg.shallow },
      }),
    }),
    calculateBasicCustomizedScenario: build.mutation<
      CalculateBasicCustomizedScenarioApiResponse,
      CalculateBasicCustomizedScenarioApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/basic/calculate-scenario`,
        method: 'POST',
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'] },
        params: { scenarioId: queryArg.scenarioId, shallow: queryArg.shallow },
      }),
    }),
    calculateBasicCustomizedScenarioWithPayload: build.mutation<
      CalculateBasicCustomizedScenarioWithPayloadApiResponse,
      CalculateBasicCustomizedScenarioWithPayloadApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/basic/calculate-scenario-with-payload`,
        method: 'POST',
        body: queryArg.customizedBasicScenarioDto,
        headers: { 'x-realm': queryArg['x-realm'], 'x-group': queryArg['x-group'] },
        params: { shallow: queryArg.shallow },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as lightweightCalculationEngineApi };
export type CalculateBomCustomizedScenarioApiResponse = /** status 200 OK */ (SimpleResult | TreeBasedResult)[];
export type CalculateBomCustomizedScenarioApiArg = {
  scenarioId: string;
  shallow?: boolean;
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
};
export type CalculateBomCustomizedScenarioWithPayloadApiResponse = /** status 200 OK */ (
  | SimpleResult
  | TreeBasedResult
)[];
export type CalculateBomCustomizedScenarioWithPayloadApiArg = {
  shallow?: boolean;
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
  customizedBomScenarioDto: CustomizedBomScenarioDto;
};
export type CalculateBasicCustomizedScenarioApiResponse = /** status 200 OK */ (SimpleResult | TreeBasedResult)[];
export type CalculateBasicCustomizedScenarioApiArg = {
  scenarioId: string;
  shallow?: boolean;
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
};
export type CalculateBasicCustomizedScenarioWithPayloadApiResponse = /** status 200 OK */ (
  | SimpleResult
  | TreeBasedResult
)[];
export type CalculateBasicCustomizedScenarioWithPayloadApiArg = {
  shallow?: boolean;
  /** realm */
  'x-realm'?: string;
  /** group */
  'x-group'?: string;
  customizedBasicScenarioDto: CustomizedBasicScenarioDto;
};
export type AssessmentResult = {
  assessmentType?: 'LCA' | 'LCC' | 'SLCA' | 'CE';
  type: string;
};
export type ImpactDto = {
  refId?: string;
  name?: string;
  percentage?: number;
  value?: number;
  unit?: string;
  selfImpact?: number;
};
export type SimplePhaseResultDto = {
  impactList?: ImpactDto[];
  phaseId?: string;
};
export type SimpleResult = AssessmentResult & {
  simplePhaseResultDtos?: SimplePhaseResultDto[];
  item?: boolean;
  type?: string;
};
export type NodeDto = {
  name?: string;
  refId?: string;
};
export type NodeImpactResultDto = {
  name?: string;
  refId?: string;
  children?: NodeDto[];
  impactList?: ImpactDto[];
  item?: boolean;
};
export type PhaseResultDto = {
  phaseId?: string;
  nodeImpactResult?: NodeImpactResultDto;
};
export type TreeBasedResult = AssessmentResult & {
  phaseResults?: PhaseResultDto[];
  type?: string;
};
export type PhaseTreeDto = {
  phaseId?: string;
  treeId?: string;
};
export type AssessmentConfigurationDto = {
  impactMethodRefId?: string;
  assessmentType?: 'LCA' | 'LCC' | 'SLCA' | 'CE';
  phaseConfigurations?: PhaseTreeDto[];
};
export type LinkedParameter = {
  parameterName: string;
  value: string;
};
export type Option = {
  label: string;
  value: string;
  isDefault?: boolean;
  linkedParameters?: LinkedParameter[];
};
export type Validation = {
  type?: string;
  value?: number;
  message?: string;
};
export type DisplayOption = {
  phaseId?: string;
  tab?: string;
  group?: string;
  item?: string;
  formulaParameter?: string;
};
export type Parameter = {
  alias?: string;
  value?: string;
  item?: string;
  description?: string;
  tooltip?: string;
  parameterName: string;
  options?: Option[];
  validations?: Validation[];
  unitOfMeasure?: string;
  type: 'AMOUNT_CHANGE' | 'FLOW_SWITCH' | 'ADDITIONAL_PARAMETER';
  parameterType: 'NUMERIC' | 'STRING' | 'OPTION' | 'FORMULA' | 'REMOTE' | 'LIST';
  hidden?: string;
  editable?: string;
  display?: DisplayOption;
};
export type FlowSwitchParameter = Parameter & {
  parentRefId?: string;
  rootModeledProcessRefId?: string;
};
export type Dependency = {
  parameterName?: string;
};
export type FormulaParameter = Parameter & {
  formula?: string;
  dependsOn?: Dependency[];
  accuracy?: number;
};
export type ItemProperty = {
  name?: string;
};
export type ListParameter = Parameter & {
  listItem?: ItemProperty[];
};
export type RemoteParameter = Parameter & {
  flowId?: string;
};
export type CustomizedBasicScenarioDto = {
  scenarioId?: string;
  assessmentConfigurations?: AssessmentConfigurationDto[];
  parameters?: (FlowSwitchParameter | FormulaParameter | ListParameter | RemoteParameter)[];
};
export type CustomizedItemDto = {
  itemId?: string;
  itemPath?: string;
  customizedBasicScenario?: CustomizedBasicScenarioDto;
};
export type CustomizedBomScenarioDto = {
  bomId?: string;
  scenarioId?: string;
  assessmentConfigurations?: AssessmentConfigurationDto[];
  customizedItems?: CustomizedItemDto[];
};
export const {
  useCalculateBomCustomizedScenarioMutation,
  useCalculateBomCustomizedScenarioWithPayloadMutation,
  useCalculateBasicCustomizedScenarioMutation,
  useCalculateBasicCustomizedScenarioWithPayloadMutation,
} = injectedRtkApi;
